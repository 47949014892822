import { css } from '@emotion/css';

import { borders } from '../styles/commonClasses';

const svgRoot = [
  css`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  borders.DEBUG_goldDashed,
].join(' ');
export const MediaPreviewStyles = {
  svgRoot,
  svgObject: [
    css`
      width: 100%;
      height: 100%;
    `,
    borders.DEBUG_purpleBorderDashed,
  ].join(' '),
  svgClickHandler: [
    css`
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
      touch-action: manipulation;
      /* background-color: rgba(0, 200, 0, 0.5) */
    `,
    borders.DEBUG_pinkDashed,
  ].join(' '),
} as const;
