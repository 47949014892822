import { css, cx } from '@emotion/css';
import { useRenderCount } from '@uidotdev/usehooks';
import { formatISO9075 } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid } from 'react-window';

import { FullScreenSwiper } from '^/userWeb/Components/FullScreenSwiper/FullScreenSwiper';
import { SmallVideoThumbnailCell } from '^/userWeb/Components/SmallVideoThumbnailCell';
import { ViewByState } from '^/userWeb/Components/ViewByState';
import { useInstance } from '^/userWeb/hooks/useInstance';
import { portalDOM } from '^/userWeb/Portal';
import { CowMountHistoryResultType } from '^/userWeb/repositories/CowMountHistoryRepo';
import { router } from '^/userWeb/routes';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';
import { borders } from '^/userWeb/styles/commonClasses';

const itemRootCssDefault = css`
  /* background-color: rgba(80, 20, 200, 0.2); */
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const itemRootCssLeft = cx(
  itemRootCssDefault,
  css`
    /* background-color: rgba(200, 160, 20, 0.2); */
    padding-left: 10px;
  `,
);
const itemRootCssRight = cx(
  itemRootCssDefault,
  css`
    /* background-color: rgba(100, 160, 80, 0.2); */
    padding-right: 10px;
  `,
);
export const CowMountHistoryPageItemsRenderer: React.FC<{
  items: CowMountHistoryResultType[],
  fullScreenSliderIndex: MobxInputValue<number | null>,
}> = observer(function CowMountHistoryPageItemsRenderer({
  items,
  fullScreenSliderIndex,
}) {
  const renderCount = useRenderCount();
  const isFullScreenInteractable = useInstance(() => new MobxInputValue(false));
  if (fullScreenSliderIndex.value !== null) {
    return createPortal(
      <FullScreenSwiper
        isSwiperDisabled={isFullScreenInteractable.current}
        items={items}
        slideIndex={fullScreenSliderIndex.value}
        setSlideIndex={(newIdx) => {
          console.log('DEBUG__setSlideIndex!', newIdx);
          fullScreenSliderIndex.set(newIdx);
        }}
        closeFullScreen={() => {
          if (fullScreenSliderIndex.value !== null) {
            router.session.back();
          }
        }}
        preloadPrevCount={2}
        preloadNextCount={2}
        fixedLayerRenderFunc={(
          item, nth,
        ) => (
          <ViewByState
            data={item}
            errorView={<div />}
            loadingView={<div />}
          >
            {(value) => {
              return (
                <>
                  <div
                    className={[
                      css`
                        position: absolute;
                        top: 0;
                        right: 20px;
                        /* background-color: grey; */
                        opacity: 1;
                        color: white;
                        z-index: 200;
                        font-size: 20px;
                        height: 20px;
                        text-align: right;
                      `,
                    ].join(' ')}
                  >
                    {formatISO9075(value.createdAt)} <br />
                    카메라 {value.cameraName} <br />
                    승가 {nth + 1} / {items.length}
                  </div>
                </>
              );
            }}
          </ViewByState>
        )}
        renderFunc={(
          mountHistResult,
          nth,
        ) => (
          <ViewByState
            data={mountHistResult}
            errorView={<div> error </div>}
            loadingView={<div> loading </div>}
          >
            {(value) => {
              if (value.fileName.endsWith('.svg')) {
                return (
                  <div
                    className={[
                      // borders.DEBUG_redBorderDashed,
                      css`
                        width: 100%;
                        height: 100%;
                        position: relative;
                        pointer-events: none;
                      `,
                    ].join(' ')}
                  >
                    <object
                      aria-label={value.fileName}
                      id="SVGViewer1"
                      data={value.videoUrl}
                      type="image/svg+xml"
                      className={[
                        css`
                          width: 100%;
                          height: 100%;
                        `,
                        borders.DEBUG_purpleBorderDashed,
                      ].join(' ')}
                    >

                    </object>
                  </div>
                );
              }
              return (
                <div
                  className={[
                    // borders.DEBUG_redBorderDashed,
                    css`
                      width: 100%;
                      height: 100%;
                      position: relative;
                    `,
                  ].join(' ')}
                >
                  <img
                    alt="testimage"
                    className={[
                      css`
                        /* position: absolute; */
                        /* top: 0; left:0; right:0; bottom: 0; */
                        width: 100%; height: 100%;
                        /* transform: translateY(calc({dimension.height * 0.5}px - 50%)) translateX(calc({dimension.width * 0.5}px - 50%)) rotate(90deg); */
                        background-color: green;
                      `,
                      // borders.DEBUG_greenBorderDashed,
                    ].join(' ')}
                    // style={{
                    //   width: dimension.height,
                    //   height: dimension.width,
                    // }}
                    src={value.videoUrl}
                  />
                </div>
              );
            }}
          </ViewByState>

        )}
      />,
      portalDOM,
    );
  }
  return (
    <div
      className={css`
        width: 100%;
        height: 100%;
      `}
    >
      <div
        className={css`
          visibility: hidden;
          position: fixed;
          top: 10; left: 10;
        `}
      >
        <h1> RenderCount: {renderCount}</h1>
      </div>
      <AutoSizer>
        {({ width, height }) => {
          const columns = Math.floor(width / 230);
          const columnWidth = width / columns;
          return (
            <FixedSizeGrid
              itemData={items}
              columnCount={columns}
              columnWidth={columnWidth}
              width={width}
              height={height}
              rowCount={Math.ceil(items.length / columns)}
              rowHeight={((columnWidth * 10) / 16) + 50}
              overscanRowCount={5}
            >
              {(a) => {
                const nth = (a.rowIndex * columns) + a.columnIndex;
                const item = a.data[nth];
                console.log('a', a);
                console.log('a.data', a.data.length);
                console.log('nth', nth);
                console.log('item', item);

                if (item === undefined) {
                  return (
                    <div
                      key={`${a.columnIndex}:${a.rowIndex}:empty`}
                      style={a.style}
                    />
                  );
                }
                // eslint-disable-next-line no-nested-ternary
                const rootCss = a.columnIndex === 0
                  ? itemRootCssLeft
                  : a.columnIndex === columns - 1
                    ? itemRootCssRight
                    : itemRootCssDefault;

                console.log('item.status', item.status);

                if (item.status === undefined || item.status === 'failure') {
                  return (
                    <SmallVideoThumbnailCell
                      key={`${a.columnIndex}:${a.rowIndex}:error`}
                      videoSrc=""
                      date="에러"
                      rootStyle={a.style}
                      rootCSS={rootCss}
                    />
                  );
                }
                if (item.status === 'loading') {
                  return (
                    <SmallVideoThumbnailCell
                      key={`${a.columnIndex}:${a.rowIndex}:loading`}
                      videoSrc=""
                      date="로딩중"
                      rootStyle={a.style}
                      rootCSS={rootCss}
                    />
                  );
                }
                return (
                  // <SmallVideoThumbnailCell
                  //   key={`${item.value.id}_ok`}
                  //   videoSrc={item.value.videoUrl}
                  //   date={formatISO9075(new Date(item.value.createdAt))}
                  //   text={`카메라 ${item.value.cameraName}`}
                  //   rootStyle={a.style}
                  //   rootCSS={rootCss}
                  // />
                  <SmallVideoThumbnailCell
                    // FIXME: cowmount.value.isCheck
                    onDoubleClick={() => {
                      fullScreenSliderIndex.set(nth);
                    }}
                    text={`카메라 ${item.value.cameraName}`}
                    key={item.value.id}
                    rootStyle={a.style}
                    date={formatISO9075(item.value.createdAt)}
                    // title={`카메라 ${item.value.cameraName} 에서 승가가 발생하였습니다`}
                    videoSrc={item.value.videoUrl}
                  />
                );
              }}
            </FixedSizeGrid>
          );
        }}
      </AutoSizer>
    </div>
  );
});
