import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import SwipeableViews from 'react-swipeable-views';

import { ReactComponent as ArrowLeftIcon } from '^/userWeb/Assets/Icons/arrow-left.svg';
import { NavigationBar } from '^/userWeb/Components/NavigationBar/NavigationBar';
import { SegmentedControl } from '^/userWeb/Components/SegmentedControl/SegmentedControl';
import { router } from '^/userWeb/routes';
import { hideScrollBar } from '^/userWeb/styles/commonClasses';
import { BackgroundColors } from '^/userWeb/styles/styleConstants';

import { NotificationsPageViewModel } from './NotificationsPage.viewmodel';
import { CowAlarmsTab } from './tabs/CowAlarmsTab';
import { NoticeTab } from './tabs/NoticeTab';
import { VisitorDetectedTab } from './tabs/VisitorDetectedTabs';

export const NotificationPage: React.FC<{
  viewmodel: NotificationsPageViewModel,
}> = observer(function NotificationPage({
  viewmodel,
}) {
  return (
    <div
      className={
        css`
          position: fixed;
          width: 100%;
          height: 100%;
          background-color: ${BackgroundColors.white};
        `
      }
    >
      <NavigationBar
        LeftIcon={ArrowLeftIcon}
        LeftIconProps={{
          onClick: () => {
            router.session.back();
          },
        }}
        title="알림"
        rootCSS={
          css`
            width: 100%;
            position: absolute;
            top: 0px;
            height: 60px;
            left: 0px;
            right: 0px;
          `
        }
      />
      <div
        className={css`
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 60px; 
          left: 0; right: 0;
          bottom: 0;
          margin-left: 20px;
          margin-right: 20px;
        `}
      >
        <SegmentedControl
          items={['승가', '출입', '공지']}
          selectedIndex={viewmodel.tabSelection.value}
          setSelectIndex={(value) => viewmodel
            .tabSelection
            .setValue(value)}
          rootCSS={css`
            flex-grow: 0;
            width: 100%;
            height: 48px;
            margin-top: 20px;
          `}
        />
        <SwipeableViews
          enableMouseEvents
          index={viewmodel.tabSelection.value}
          onChangeIndex={(nth) => viewmodel
            .tabSelection
            .setValue(nth)}
          className={css`
            position: relative;
            flex: 1;
            flex-grow: 1;
          `}
          containerStyle={{
            width: '100%',
            height: '100%',
          }}
          slideClassName={cx(
            css`
              position: relative;
              top: 0; right: 0; bottom: 0; left: 0;
              overflow-y: scroll;
            `,
            hideScrollBar,
          )}

        >
          <CowAlarmsTab
            viewmodel={viewmodel.cowAlarmsTabViewModel}
          />
          <VisitorDetectedTab
            viewmodel={viewmodel.visitorDetectedTabViewModel}
          />
          <NoticeTab
            viewmodel={viewmodel.noticeTabViewModel}
          />
        </SwipeableViews>
      </div>
    </div>
  );
});
