import React from 'react';

import loadable from '@loadable/component';
import { observer } from 'mobx-react-lite';

import { getCurrentFreshness } from '^/types/__ResultType';
import { extractTokenData } from '^/utils/token/forClient';

import { WithTabBar } from './Components/Tabbar/WithTabBar';
import { AdminCamerasPage } from './Pages/AdminCamerasPage/AdminCamerasPage';
import { AdminCamerasPageViewmodel } from './Pages/AdminCamerasPage/AdminCameraspage.viewmodel';
import { AdminIotDevicePage } from './Pages/AdminIotDevicePage/AdminIotDevicePage';
import { AdminIotDevicePageViewModel } from './Pages/AdminIotDevicePage/AdminIotDevicePage.viewmodel';
import { AdminUsersPage } from './Pages/AdminUsersPage/AdminUsersPage';
import { AdminUsersPageViewModel } from './Pages/AdminUsersPage/AdminUsersPage.viewmodel';
import { CowMountHistoryPage } from './Pages/CowMountHistoryPage/CowMountHistoryPage';
import {
  CowMountHistoryPageViewModel,
} from './Pages/CowMountHistoryPage/CowMountHistoryPage.viewmodel';
import { DetectedVisitorsPage } from './Pages/DetectedVisitorsPage/DetectedVisitorsPage';
import {
  DetectedVisitorsPageViewModel,
} from './Pages/DetectedVisitorsPage/DetectedVisitorsPage.viewmodel';
import { EmptyPage } from './Pages/EmptyPage';
import { LoginPageViewModel } from './Pages/LoginPage/LoginPage.viewmodel';
import { MainPage } from './Pages/MainPage/MainPage';
import { MainPageViewModel } from './Pages/MainPage/MainPage.viewmodel';
import { MyPage } from './Pages/MyPage/MyPage';
import { MyPageViewModel } from './Pages/MyPage/MyPage.viewmodel';
import { MySettingsPage } from './Pages/MySettingsPage/MySettingsPage';
import { MySettingsPageViewModel } from './Pages/MySettingsPage/MySettingsPage.viewmodel';
import { NotificationPage } from './Pages/NotificationPage/NotificationsPage';
import { NotificationsPageViewModel } from './Pages/NotificationPage/NotificationsPage.viewmodel';
import { SensorsPage } from './Pages/SensorsPage/SensorsPage';
import { SensorsPageViewModel } from './Pages/SensorsPage/SensorsPage.viewmodel';
import { StatsPage } from './Pages/StatsPage/StatsPage';
import { StatsPageViewModel } from './Pages/StatsPage/StatsPage.viewmodel';
import {
  router, routes,
} from './routes';
import type { AppContext } from './types/AppContext';

const LoginPage = loadable(() => import(
  './Pages/LoginPage/LoginPage'
));

export const RoutesPageRenderer: React.FC<{
  appContext: AppContext,
}> = observer(function RoutePageRenderer({
  appContext,
}) {
  const route = router.useRoute();
  if (route.name === 'login') {
    const viewmodel = new LoginPageViewModel(appContext);
    return <LoginPage viewmodel={viewmodel} />;
  }
  if (appContext.tokenRepo.userToken.status !== 'success') {
    setTimeout(() => routes.login()
      .replace());
    return null;
  }

  const tokenData = extractTokenData(
    appContext.tokenRepo.userToken.value,
  );
  const isAdmin = tokenData.userKind === 'admin';

  if (route.name === 'main') {
    const viewModel = new MainPageViewModel(appContext);
    return (
      <WithTabBar
        routeName="main"
        withAdminTabs={isAdmin}
      >
        <MainPage viewmodel={viewModel} />
      </WithTabBar>
    );
  }
  if (route.name === 'myPage') {
    const viewmodel = new MyPageViewModel(appContext);
    return (
      <WithTabBar
        routeName="myPage"
        withAdminTabs={isAdmin}
      >
        <MyPage viewmodel={viewmodel} />
      </WithTabBar>
    );
  }

  if (route.name === 'notification') {
    const viewmodel = new NotificationsPageViewModel(appContext);
    return (
      <NotificationPage
        viewmodel={viewmodel}
      />
    );
  }

  if (route.name === 'notificationSetting') {
    const viewmodel = new MySettingsPageViewModel(
      appContext,
    );
    return (
      <MySettingsPage
        viewmodel={viewmodel}
      />
    );
  }

  if (route.name === 'visitorHistory') {
    const viewmodel = new DetectedVisitorsPageViewModel(
      appContext,
      undefined,
      undefined,
      route.params.fullscreenNth,
    );
    return (
      <WithTabBar
        routeName={route.name}
        withAdminTabs={isAdmin}
      >
        <DetectedVisitorsPage
          title="최근 방문한 기록"
          viewmodel={viewmodel}
        />
      </WithTabBar>
    );
  }

  if (route.name === 'cowMountingHistory') {
    const detectionType = route.params.detectionType || 'ride';
    const viewmodel = new CowMountHistoryPageViewModel(
      appContext,
      undefined,
      undefined,
      detectionType,
      route.params.fullscreenNth,
      undefined,
      undefined,
      route.params.freshness,
    );

    const title = detectionType === 'ride' ? '최근 발생한 승가' : '최근 발생한 스탄존';
    return (
      <WithTabBar
        routeName={route.name}
        withAdminTabs={isAdmin}
      >
        <CowMountHistoryPage
          title={title}
          viewmodel={viewmodel}
        />
      </WithTabBar>

    );
  }
  if (route.name === 'sensors') {
    if (!route.params.freshness) {
      setTimeout(() => {
        routes.sensors({
          ...route.params,
          freshness: getCurrentFreshness(),
        }).replace();
      }, 20);
      return null;
    }
    const viewmodel = new SensorsPageViewModel(
      appContext,
      route.params.freshness,
      route.params.selectedDevice ?? null,
    );
    return (
      <WithTabBar
        routeName={route.name}
        withAdminTabs={isAdmin}
      >
        <SensorsPage
          viewmodel={viewmodel}
        />
      </WithTabBar>
    );
  }
  if (route.name === 'stats') {
    if (!route.params.freshness) {
      setTimeout(() => {
        routes.stats({
          ...route.params,
          freshness: getCurrentFreshness(),
        }).replace();
      }, 20);
      return null;
    }
    const viewmodel = new StatsPageViewModel(
      appContext,
      route.params.freshness,
    );
    return (
      <WithTabBar
        routeName={route.name}
        withAdminTabs={isAdmin}
      >
        <StatsPage
          viewmodel={viewmodel}
        />
      </WithTabBar>
    );
  }
  if (route.name === 'adminMain') {
    setInterval(() => {
      routes.adminIotPage()
        .push();
    }, 1000);
    return (
      <div>
        adminMain 준비 중 입니다
        (iotDevice 관리 페이지로 이동 중...)
      </div>
    );
  }

  if (route.name === 'adminIotPage') {
    if (!route.params.freshness) {
      setTimeout(() => {
        routes.adminIotPage({
          ...route.params,
          freshness: getCurrentFreshness(),
        }).replace();
      }, 50);
      return null;
    }
    const viewmodel = new AdminIotDevicePageViewModel(
      appContext,
    );
    return (
      <WithTabBar
        routeName={route.name}
        withAdminTabs={isAdmin}
      >
        <AdminIotDevicePage
          viewmodel={viewmodel}
        />
      </WithTabBar>
    );
  }
  if (route.name === 'adminCamerasPage') {
    if (!route.params.freshness) {
      setTimeout(() => {
        routes.adminCamerasPage({
          ...route.params,
          freshness: getCurrentFreshness(),
        }).replace();
      }, 50);
      return null;
    }
    const viewmodel = new AdminCamerasPageViewmodel(
      appContext,
      route.params.freshness,
    );
    return (
      <WithTabBar
        routeName={route.name}
        withAdminTabs={isAdmin}
      >
        <AdminCamerasPage
          viewmodel={viewmodel}
        />
      </WithTabBar>
    );
  }
  if (route.name === 'adminUsersPage') {
    const viewmodel = new AdminUsersPageViewModel(
      appContext,
    );
    return (
      <WithTabBar
        routeName={route.name}
        withAdminTabs={isAdmin}
      >
        <AdminUsersPage
          viewmodel={viewmodel}
        />
      </WithTabBar>

    );
  }
  return (
    <WithTabBar
      routeName={route.name}
      withAdminTabs={isAdmin}
    >
      <EmptyPage
        pageName={route.name}
      />
    </WithTabBar>
  );
});
