import { makeAutoObservable, runInAction } from 'mobx';

import {
  asSuccessPromise, ExtractFailures, FreshnessType, NotInitiatedPromiseStale, PromiseResultType,
} from '^/types/__ResultType';
import { CowMountHistoryIdType } from '^/types/CowMountHistoryType';
import { UserIDType, UserTokenType } from '^/types/userTypes';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';

import { APIClient } from '../apiClient';
import type { FailedPromiseDueToTRPCClientError } from '../apiClient/types';
import { GetCowMountHistoryListFailResult } from '../apiTypes/getCowMountHistoryInfo';
import { CowMountHistoryRepo } from '../repositories/CowMountHistoryRepo';

type CowMountMistoryListCacheID = string & {
  CowMountMistoryListCacheID: null;
};

export type CowMountHistoryListFilterType = {
  __CowMountHistoryListFilterType: null;
  userId: UserIDType,
  userToken: UserTokenType,
};

function serializeCowMountHistoryListFilter(
  filter: CowMountHistoryListFilterType,
) {
  return JSON.stringify(filter) as CowMountMistoryListCacheID;
}
export class CowMountHistoryListCore {
  constructor(
    public apiClient: APIClient,
    public cowMountHistoryRepo: CowMountHistoryRepo,
    public cowMountIDsByFilter: Record<
      /* eslint-disable @typescript-eslint/indent */
      CowMountMistoryListCacheID,
      PromiseResultType<
        CowMountHistoryIdType[],
        ExtractFailures<
          GetCowMountHistoryListFailResult
          | FailedPromiseDueToTRPCClientError<any>
        >
      >
    > = {},
    /* eslint-enable @typescript-eslint/indent */
  ) {
    makeAutoObservable(this, {
      apiClient: false,
    });
  }

  getList = computedFn2((
    filter: CowMountHistoryListFilterType,
    freshness: FreshnessType,
  ) => {
    const cacheKey = serializeCowMountHistoryListFilter(filter);
    console.log('pre this.fetchList(filter, freshness)');
    this.fetchList(filter, freshness);
    console.log('post this.fetchList(filter, freshness)');
    const mountHistoryIDsByFilter = this
      .cowMountIDsByFilter[cacheKey];
    if (!mountHistoryIDsByFilter) {
      return NotInitiatedPromiseStale;
    }
    if (mountHistoryIDsByFilter.status !== 'success') {
      return mountHistoryIDsByFilter;
    }
    console.log('pre return getList = computedFn2(( promise');
    return asSuccessPromise(
      mountHistoryIDsByFilter.value.map(
        (id) => this
          .cowMountHistoryRepo
          .getCowMountHistoryById(id),
      ),
    );
  });

  fetchList = computedFn2(async (
    filter: CowMountHistoryListFilterType,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    freshness: FreshnessType,
  ) => {
    const cacheKey = serializeCowMountHistoryListFilter(filter);
    const fetchedData = await this
      .apiClient
      .mountHistory
      .getCowMountHistory
      .query({
        userId: filter.userId,
        userToken: filter.userToken,
      });
    console.log('fetchedData status error?', fetchedData);

    if (fetchedData.status !== 'success') {
      this.cowMountIDsByFilter[cacheKey] = fetchedData;
      return;
    }
    console.log("after if (fetchedData.status !== 'success')");
    const ids = fetchedData
      .value
      .map((mount) => mount.id);
    console.log('const ids = fetchedData');
    runInAction(() => {
      this.cowMountIDsByFilter[cacheKey] = asSuccessPromise(ids);
      fetchedData.value
        .forEach((mount) => {
          this.cowMountHistoryRepo
            .setCowMountHistoryById(
              mount.id,
              asSuccessPromise(mount),
            );
        });
    });
    console.log('runInAction');
  });
}
