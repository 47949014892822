/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/** @jsx jsx */
import React, { MouseEventHandler, useCallback, useRef } from 'react';

import { css } from '@emotion/css';
import { m } from 'framer-motion';

// import { useLongPress } from '../hooks/useLongPress';
import { browserInfo } from '../utils/browserInfo';
import { isImageExt } from '../utils/isImageExt';

import { MediaPreviewStyles } from './MediaPreview.styles';

export const MediaPreview: React.FC<{
  mediaSrc?: string;
  mediaCSS?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  onDoubleClick?: MouseEventHandler<HTMLElement>;
  alt?: string;
  styles?: typeof MediaPreviewStyles,
}> = (props) => {
  const styles = props.styles || MediaPreviewStyles;
  const videoRef = useRef<HTMLVideoElement>(null);
  const onClickHandler = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick(e);
    }
    if (videoRef.current) {
      const isPlaying = videoRef.current.currentTime > 0
        && !videoRef.current.paused
        && !videoRef.current.ended
        && videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;
      // https://stackoverflow.com/a/36898221/19531789
      if (videoRef.current.paused && !isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
    if (e.detail === 2 && props.onDoubleClick) {
      // alert('!');
      props.onDoubleClick(e);
    }
  }, [videoRef, props]);

  const showSvg = props.mediaSrc && props.mediaSrc.endsWith('.svg');
  if (showSvg) {
    return (
      <div
        className={styles.svgRoot}
      >
        <object
          aria-label={props.alt}
          id="SVGViewer1"
          data={props.mediaSrc}
          type="image/svg+xml"
          className={styles.svgObject}
        >
        </object>
        <div
          onClick={onClickHandler}
          className={styles.svgClickHandler}
        />
      </div>
    );
  }

  const showImgTag = (props.mediaSrc && isImageExt(props.mediaSrc))
    || browserInfo?.name === 'safari'
    || browserInfo?.os === 'iOS';

  if (showImgTag) {
    /* {...onDoubleTap} */
    return (
      <m.img
        alt={props.alt}
        src={props.mediaSrc}
        className={[
          props.mediaCSS,
          css`
          `,
          // borders.DEBUG_purpleBorderDashed,
        ].join(' ')}
        onClick={onClickHandler}
      />
    );
  }
  return (
    <m.video
      layoutId={props.mediaSrc}
      autoPlay
      muted
      playsInline
      ref={videoRef}
      className={[
        props.mediaCSS,
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
      src={props.mediaSrc}
      onClick={onClickHandler}
    />
  );
};
