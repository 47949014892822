import { css, cx } from '@emotion/css';

import { borders } from '../styles/commonClasses';
import {
  BackgroundColors,
  FontSizes, FontWeights,
  TextColors,
} from '../styles/styleConstants';

import { VerticalDivider } from './Dividers/VerticalDivider';

export const NotificationVideoCellStyles = {
  root: [
    css`
      display: flex;
      flex-direction: column;

      &:active {
        opacity: 0.5;
      }
    `,
  ].join(' '),
  container: [
    css`
      display: flex;
      flex-direction: row;
      padding-top: 16px;
      padding-bottom: 16px;
      gap: 12px;
    `,
    // borders.DEBUG_greenBorderDashed,
  ].join(' '),
  textArea: [
    css`
      display: flex;
      flex-grow: 0.7;
      flex-direction: column;
      gap: 8px;
    `,
  ].join(' '),
  textArea$Date: [
    css`
      color: ${TextColors.black_light};
      font-size: ${FontSizes.base};
      font-weight: ${FontWeights.regular};
      text-align: left;
      margin: 0px;
    `,
  ].join(' '),
  textArea$Title: [
    css`
      color: ${TextColors.black};
      font-size: ${FontSizes.lg};
      font-weight: ${FontWeights.bold};
      text-align: left;
      margin: 0px;
    `,
  ].join(' '),
  mediaPreview: [
    css`
      flex-grow: 1;
      align-self: flex-start;
      background-color: ${BackgroundColors.gray_light};
      border-radius: 4px;
      min-width: 128px;
      height: auto;
      aspect-ratio: 16 / 10;
    `,
  ].join(' '),
  mediaPreviewNORENDER: [
    css`
      flex-grow: 1;
      align-self: flex-start;
      // background-color: purple;
      background-color: ${BackgroundColors.gray_light};
      border-radius: 4px;
      min-width: 128px;
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 10;
    `,
  ].join(' '),
  bottomDivider: [
    cx(
      VerticalDivider,
      css`
        width: 100%;
      `,
    ),
  ].join(' '),
} as const;
