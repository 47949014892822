import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';
import Sheet from 'react-modal-sheet';

import { ViewByMobxResultOfRxjsResult } from '^/userWeb/Components/ViewByResultObservable';
import { ViewByState } from '^/userWeb/Components/ViewByState';
import { portalDOM } from '^/userWeb/Portal';
import { borders } from '^/userWeb/styles/commonClasses';

import { SensorItem } from './SensorItem';
// import { SensorsDetailPopup } from './SensorsDetail.popup';
import { SensorsDetailPopup } from './SensorsDetail.popup';
import type { SensorsPageViewModel } from './SensorsPage.viewmodel';

export const SensorsPage = observer(
  function SensorsPage(p: {
    viewmodel: SensorsPageViewModel,
  }) {
    return (
      <div
        className={[
          css`
            /* background-color: green; */
            height: 100%;
            padding-left: 12px;
            padding-right: 12px;
          `,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      >
        {/* <h1> inside sensors page </h1> */}
        {/* <ViewByMobxResultOfRxjsResult
          value={p.viewmodel.iotDevices$Rx}
          errorView={<p> err</p>}
          loadingView={<h1> loading mobxReOfRx</h1>}
        >
          {(value) => {
            return (
              <pre>
                {JSON.stringify(value, null, 2)}
              </pre>
            );
          }}
        </ViewByMobxResultOfRxjsResult> */}
        <ViewByState
          data={p.viewmodel.iotDevices}
          loadingView={<p> loading </p>}
          errorView={<p> error </p>}
        >
          {(devices) => {
            if (devices.length === 0) {
              return (
                <div>
                  등록된 환경제어기가 없습니다
                </div>
              );
            }
            return (
              <>
                <h1> 환경제어기 </h1>
                {devices.map((d) => {
                  return (
                    <SensorItem
                      device={d}
                      onClick={() => p.viewmodel.selectedDeviceId.set(d.id)}
                      key={d.id}
                    />
                  );
                })}
                {/* {selectedDevice && (
                  createPortal(
                    <SensorsDetailPopup
                      device={selectedDevice}
                      viewmodel={p.viewmodel}
                    />,
                    portalDOM,
                  )
                )} */}
                <Sheet
                  isOpen={p.viewmodel.detailPopupVM.status === 'success'}
                  onClose={() => p
                    .viewmodel
                    .selectedDeviceId
                    .set(null)}
                >
                  <Sheet.Container
                    style={{
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      // backgroundColor: 'purple',
                    }}
                  >
                    <Sheet.Header />
                    <Sheet.Content
                      disableDrag
                    >
                      <Sheet.Scroller>
                        {p.viewmodel.detailPopupVM.status === 'success' && (
                          <SensorsDetailPopup
                            viewmodel={p.viewmodel.detailPopupVM.value}
                          />
                        )}
                      </Sheet.Scroller>
                    </Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>
              </>
            );
          }}
        </ViewByState>
      </div>
    );
  },
);
