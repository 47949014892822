import { autorun, makeAutoObservable, reaction } from 'mobx';
import { fromResource } from 'mobx-utils';
import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  NotInitiatedPromiseStale,
  type ExtractFailures,
  type ExtractLoading,
  type ExtractPromiseSuccess,
  type LoadingPromise,
  type PromiseResultType,
} from '^/types/__ResultType';
import type { ExtractObservable } from '^/types/__utils';

import { registerForDispose, type IWithDispose } from './withDisposeClass';

class FlattenMobxRxResult<
  T extends PromiseResultType<
    Observable<
      PromiseResultType<any, any, any>
    >, any, any
  >,
  TError extends ExtractFailures<T> = ExtractFailures<T>,
  TLoading extends ExtractLoading<T> = ExtractLoading<T>,
  TObservableR extends ExtractObservable<ExtractPromiseSuccess<T>>
  = ExtractObservable<ExtractPromiseSuccess<T>>,
  TValue extends ExtractPromiseSuccess<TObservableR> = ExtractPromiseSuccess<TObservableR>,
  TErrorR extends ExtractFailures<TObservableR> = ExtractFailures<TObservableR>,
  TLoadingR extends ExtractLoading<TObservableR> = ExtractLoading<TObservableR>,
> implements IWithDispose {
  aa: ReturnType<typeof autorun>;

  constructor(
    public original: T,
    public currentRxSubscription: Observable<TObservableR> | null = null,
    public currentValue: PromiseResultType<
      TValue, TError | TErrorR, TLoading | TLoadingR
    > | LoadingPromise = NotInitiatedPromiseStale,
    public subscription: Subscription | null = null,
  ) {
    makeAutoObservable(this, {
      // aa: false,
    });
    this.aa = autorun(() => {
      debugger;
      if (this.original.status !== 'success') {
        this.subscription?.unsubscribe();
        this.currentRxSubscription = null;
        // debugger;
        this.currentValue = this.original;
        return;
      }
      this.subscription = this.original
        .value
        .subscribe((v) => {
          debugger;
          this.currentValue = v;
        });
    });
  }

  // __disposer = reaction(
  //   () => this.original,
  //   (current, prev) => {
  //     alert(`current: ${current}`);
  //     if (prev.status === 'success') {
  //       this.subscription?.unsubscribe();
  //     }
  //     if (current.status === 'success') {
  //       alert('reaction current.status success!');
  //       this.subscription =
  //       return;
  //     }
  //     this.currentValue = current;
  //   },
  // );

  dispose() {
    this.subscription?.unsubscribe();
    // eslint-disable-next-line no-underscore-dangle
    this.aa();
  }
}

export function flattenMobxRxResult<
  T extends PromiseResultType<
    Observable<
      PromiseResultType<any, any, any>
    >, any, any
  >,
// TError extends ExtractFailures<T> = ExtractFailures<T>,
// TLoading extends ExtractLoading<T> = ExtractLoading<T>,
// TObservableR extends ExtractObservable<ExtractPromiseSuccess<T>>
// = ExtractObservable<ExtractPromiseSuccess<T>>,
// TValue extends ExtractPromiseSuccess<TObservableR> = ExtractPromiseSuccess<TObservableR>,
// TErrorR extends ExtractFailures<TObservableR> = ExtractFailures<TObservableR>,
// TLoadingR extends ExtractLoading<TObservableR> = ExtractLoading<TObservableR>,
>(original: T) {
  return registerForDispose(
    new FlattenMobxRxResult(original),
  );
}
