import 'keen-slider/keen-slider.min.css';

import { css } from '@emotion/css';
import { useMeasure, useOrientation } from '@uidotdev/usehooks';
import { useKeenSlider } from 'keen-slider/react';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

import { useInstance } from '^/userWeb/hooks/useInstance';
import { portalDOM } from '^/userWeb/Portal';
import { borders } from '^/userWeb/styles/commonClasses';
import type { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';
import { stripMargin } from '^/utils/stripMargin';

import { FullScreenSwiperPinchableItem } from './FullScreenSwiperPinchableItem';
import { SwiperArrow } from './SwiperArrows';

export const FullScreenSwiper = observer(function FullScreenSwiper<
  TItem,
>(props: {
  items: TItem[],
  renderFunc: (
    item: TItem,
    nth: number,
  ) => React.ReactNode,
  fixedLayerRenderFunc?: (
    item: TItem,
    nth: number,
  ) => React.ReactNode,
  slideIndex: number,
  setSlideIndex(newSlideIndex: number): void,
  closeFullScreen(): void;
  preloadPrevCount?: number,
  preloadNextCount?: number,
  isSwiperDisabled: MobxInputValue<boolean>,
}) {
  const [measureRef, { width, height }] = useMeasure();
  const orientation = useOrientation();
  // const orientationAngle = orientation.angle;
  // const isLandscape = (70 < orientationAngle && orientationAngle < 120)
  //   || (250 < orientationAngle && orientationAngle < 290);
  const isLandscape = window.innerWidth > window.innerHeight;

  const [slideRef, instanceRef] = useKeenSlider({
    created(k) {
      // toast(stripMargin`
      //   |FullScreenSwiper: created
      //   | moving to: ${props.slideIndex}`);
      k.track.to(props.slideIndex);
    },
    slideChanged(k) {
      // toast(stripMargin`
      //   |FullScreenSwiper: slideChanged
      //   | moving to: ${k.track.details.rel}`);
      props.setSlideIndex(k.track.details.rel);
    },
    animationEnded(k) {
      // toast(stripMargin`
      //   |FullScreenSwiper: animationEnded
      //   | moving to: ${k.track.details.rel}`);
      // props.setSlideIndex(k.track.details.rel);
    },
    // dragEnded(k) {
    //   props.setSlideIndex(k.track.details.rel);
    // },
    // initial: 1,
    // initial: props.slideIndex,
    vertical: !isLandscape,
    mode: 'snap',
    renderMode: 'performance',
    // disabled: props.isSwiperDisabled.value,
    drag: !props.isSwiperDisabled.value,
    // drag: false,
  });

  const slideNthMin = props.slideIndex - (props.preloadPrevCount ?? 100000000);
  const slideNthMax = props.slideIndex + (props.preloadNextCount ?? 1000000000);

  return createPortal(
    <div
      ref={measureRef}
      className={[
        css`
          width: 100%; height: 100%;
          pointer-events: all;
        `,
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
      id="FullScreenSwiper"
    >
      <SwiperArrow
        dim={!props.isSwiperDisabled.value}
        isLandscape={isLandscape}
        nthSlide={props.slideIndex}
        numSlides={props.items.length}
        prevFn={() => {
          instanceRef.current?.prev();
        }}
        nextFn={() => {
          instanceRef.current?.next();
        }}
      />
      <div
        className={css`
          width: 100%; height: 100%;
        `}
        id="FullScreenSwiper"
      >
        {width && height && (
          <div
            ref={slideRef}
            className="keen-slider"
            style={{
              height,
            }}
          >
            {props.items.map((item, nth) => {
              // if (nth < slideNthMin || nth > slideNthMax) {
              //   return null; // don't render
              // }
              const dontRender = nth < slideNthMin || nth > slideNthMax;
              const renderedItem = dontRender === false
                ? props.renderFunc(item, nth) : null;

              const fixedRenderedItem = (
                dontRender === false && props.fixedLayerRenderFunc
              ) ? props.fixedLayerRenderFunc(item, nth) : null;

              return (
                <div
                  className="keen-slider__slide"
                  style={{
                    height,
                    width,
                  }}
                  key={nth}
                >
                  <FullScreenSwiperPinchableItem
                    disabled={nth !== props.slideIndex}
                    // orientationAngle={orientation.angle}
                    isLandscape={isLandscape}
                    item={renderedItem}
                    fixedItem={fixedRenderedItem}
                    onSwipeUp={() => {
                      props.closeFullScreen();
                    }}
                    width={width}
                    height={height}
                    isSwiperDisabled={props.isSwiperDisabled}
                    __DEBUG_MESSAGE={stripMargin`
                    |current index: ${props.slideIndex}
                    `}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>,
    portalDOM,
  );
});
