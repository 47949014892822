import { fromResource } from 'mobx-utils';

export interface IWithDispose {
  dispose(): void;
}

export function registerForDispose<T extends IWithDispose>(
  value: T,
  $DEBUG_NAME: string = '',
) {
  return fromResource<T>((sink) => {
    console.log(
      'registerForDispose::creating...:',
      $DEBUG_NAME,
    );
    sink(value);
  }, () => {
    console.log(
      'registerForDispose::disposing...:',
      $DEBUG_NAME,
    );
    value.dispose();
  }, value);
}
