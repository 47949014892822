import { makeAutoObservable, runInAction } from 'mobx';

import {
  asSuccessPromise, ExtractFailures, FreshnessType, NotInitiatedPromiseStale, PromiseResultType,
} from '^/types/__ResultType';
import { NoticeIdType } from '^/types/notice';
import { UserIDType, UserTokenType } from '^/types/userTypes';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';

import { APIClient } from '../apiClient';
import { GetNoticesListFailResult } from '../apiTypes/getNotices';
import { NoticesRepo } from '../repositories/NoticesRepo';

type SerializedCacheKey = string & {
  __NoticesSerializedCacheKey: null;
};

export type NoticesListFilterType = {
  __NoticesListFilterType: null;
  userId: UserIDType;
  userToken: UserTokenType;
};

function serializeNoticesListFilter(
  filter: NoticesListFilterType,
) {
  return JSON.stringify(filter) as SerializedCacheKey;
}
export class NoticesListCore {
  constructor(
    public apiClient: APIClient,
    public noticeRepo: NoticesRepo,
    public idsByFilter: Record<
      SerializedCacheKey,
      PromiseResultType<
        NoticeIdType[],
        ExtractFailures<GetNoticesListFailResult>
      >
    > = {},
  ) {
    makeAutoObservable(this, {
      apiClient: false,
    });
  }

  getList = computedFn2((
    filter: NoticesListFilterType,
    freshness: FreshnessType,
  ) => {
    const cacheKey = serializeNoticesListFilter(filter);
    this.fetchList(filter, freshness);
    const noticeIDsByFilter = this
      .idsByFilter[cacheKey];
    if (!noticeIDsByFilter) {
      return NotInitiatedPromiseStale;
    }
    if (noticeIDsByFilter.status !== 'success') {
      return noticeIDsByFilter;
    }
    return asSuccessPromise(
      noticeIDsByFilter.value.map(
        (id) => this
          .noticeRepo
          .get(id),
      ),
    );
  });

  fetchList = computedFn2(async (
    filter: NoticesListFilterType,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    freshness: FreshnessType,
  ) => {
    const cacheKey = serializeNoticesListFilter(filter);
    const fetchedData = await this
      .apiClient
      .notices
      .query({
        userId: filter.userId,
        userToken: filter.userToken,
      });
    if (fetchedData.status !== 'success') {
      this.idsByFilter[cacheKey] = fetchedData;
      return;
    }
    const ids = fetchedData
      .value
      .map((notice) => notice.id);
    runInAction(() => {
      this.idsByFilter[cacheKey] = asSuccessPromise(ids);
      fetchedData.value
        .forEach((notice) => {
          this.noticeRepo
            .set(
              notice.id,
              asSuccessPromise(notice));
        });
    });
  });
}
