import { makeAutoObservable, makeObservable } from 'mobx';
import { computedFn } from 'mobx-utils';

import {
  asFailedPromise,
  asSuccessPromise,
  getCurrentFreshness,
  NotInitiatedPromiseStale,
  FreshnessType,
} from '^/types/__ResultType';
import { UserIDType, UserTokenType, UserType } from '^/types/userTypes';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';

import { APIClient } from '../apiClient';
import { ExpoPushTokenRepo } from '../repositories/ExpoPushTokenRepo';
import { FCMTokenRepo } from '../repositories/FCMTokenRepo';
import { MyInfoRepo } from '../repositories/MyInfoRepo';
import { TokenRepo } from '../repositories/TokenRepo';
import { routes } from '../routes';

export class MyInfoCore {
  constructor(
    public tokenRepo: TokenRepo,
    public myInfoRepo: MyInfoRepo,
    public fcmTokenRepo: FCMTokenRepo,
    public expoPushTokenRepo: ExpoPushTokenRepo,
    public apiClient: APIClient,
  ) {
    makeObservable(this, {
      login: true,
      myInfo: true,
      fetchMyInfo: true,
      updateMyInfo: true,
    });
  }

  async login({
    phone,
    password,
  }: {
    phone: string,
    password: string,
  }) {
    const result = await this
      .apiClient
      .user
      .login
      .mutate({
        phone,
        password,
      });
    if (result.status === 'success') {
      this.tokenRepo.setUserToken(result);
      if (this.fcmTokenRepo.fcmToken) {
        await this.apiClient
          .addFCMPushSubscription
          .mutate({
            userToken: result.value,
            fcmToken: this.fcmTokenRepo.fcmToken,
          });
      }
      if (this.expoPushTokenRepo.expoPushToken) {
        await this.apiClient
          .addExpoPushSubscription
          .mutate({
            userToken: result.value,
            expoToken: this.expoPushTokenRepo.expoPushToken,
          });
      }
    }
    return result;
  }

  myInfo = computedFn2((
    userToken: UserTokenType,
    freshness: FreshnessType,
    disableRedirectToLogin?: boolean,
  ) => {
    if (!userToken) {
      return asFailedPromise('USER_NOT_LOGGEDIN' as const);
    }
    this.fetchMyInfo(userToken, freshness, disableRedirectToLogin);
    return this.myInfoRepo.myInfo;
  });

  fetchMyInfo = computedFn(async (
    userToken: UserTokenType,
    freshness: FreshnessType,
    disableRedirectToLogin?: boolean,
  ) => {
    const result = await this.apiClient
      .user
      .getUserInfo
      .query({
        userToken,
      });
    if (result.status === 'failure'
      && !disableRedirectToLogin
    ) {
      this.tokenRepo
        .setUserToken(NotInitiatedPromiseStale);
      routes.login({
        redirectUrl: window.location.pathname,
      });
    }
    this.myInfoRepo.setMyInfo(result);
  });

  async updateMyInfo(
    id: UserIDType,
    update: Partial<UserType>,
  ) {
    const updateResult = await this
      .apiClient
      .user
      .updateUser
      .mutate({
        ...update,
        id,
      });
    if (updateResult.status === 'success') {
      this.myInfoRepo.setMyInfo(updateResult);
    }
    return updateResult;
  }
}
