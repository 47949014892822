import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { Virtuoso } from 'react-virtuoso';

import { ViewByState } from '^/userWeb/Components/ViewByState';
import { borders } from '^/userWeb/styles/commonClasses';

import { CameraItem } from '../../Components/Lists/CameraList/CameraItem';

import type { AdminCamerasPageViewmodel } from './AdminCameraspage.viewmodel';

export const AdminCamerasPage = observer(function AdminCamerasPage(p: {
  viewmodel: AdminCamerasPageViewmodel,
}) {
  return (
    <div
      className={[
        css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `,
        borders.DEBUG_blueBorderDashed,
      ].join(' ')}
    >
      <h1> Admin Cameras Page! </h1>
      <input
        type="text"
        value={p.viewmodel.filter.value}
        onChange={(e) => p.viewmodel.filter.set(e.currentTarget.value)}
      />
      <ViewByState
        data={p.viewmodel.filteredAndGroupedCameras2}
        errorView={(e) => (
          <p>error</p>
        )}
        loadingView={<p>loading</p>}
      >
        {(cams) => {
          const groupedCams = cams
            .toArray();
          return (
            <Virtuoso
              className={[
                css`
                flex-grow: 1;

                tr:hover{
                  background-color: green;
                }
              `,
                borders.DEBUG_greenBorderDashed,
              ].join(' ')}
              data={groupedCams}
              itemContent={(nth, [phone, items]) => {
                return (
                  <>
                    <div
                      className={[
                        css`
                        position: sticky;
                        top: 0px;
                        background-color: black;
                        color: white;
                      `,
                      ].join(' ')}
                    >
                      <h1> {phone}</h1>

                    </div>
                    <div>
                      {items.map((cam) => {
                        return (
                          <CameraItem
                            key={cam.obj.cam.id}
                            camera={cam.obj.cam}
                          />
                        );
                      })}

                      {/* <pre>
                        {JSON.stringify(
                          item[1]
                            .map((i) => i.obj.cam)
                            .toArray(),
                          null,
                          2,
                        )}
                      </pre> */}
                    </div>
                  </>
                );
              }}
            />
          );
        }}
      </ViewByState>

      {/*
      <h2>{p.viewmodel.freshness}</h2>
      <h2>
        {p.viewmodel.filteredCameras.status === 'success'
          && p.viewmodel.filteredCameras.value.length}
      </h2>
      <pre
        className={[
          css`
            height: 300px;
            overflow: scroll;
          `,
          borders.DEBUG_blueBorderDashed,
        ].join(' ')}
      >
        {JSON.stringify(
          p.viewmodel.filteredCameras,
          null,
          2)}
      </pre> */}
    </div>
  );
});
