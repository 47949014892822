/** @jsx jsx */
import React from 'react';

import { css } from '@emotion/css';

import { FontSizes, FontWeights, TextColors } from '../styles/styleConstants';

export const EmptyPage: React.FC<{
  pageName: string | false;
}> = ({
  pageName,
}) => {
    // eslint-disable-next-line no-console
    console.log(`${pageName} not found`);
    return (
      <div
        className={css`
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
      `}
      >
        <p
          className={css`
          color: ${TextColors.black_light};
          font-size: ${FontSizes.base};
          font-weight: ${FontWeights.regular};
          text-align: center;
          align-items: center;
          flex-grow: 0;
          display: flex;
        `}
        >
          페이지 준비중 입니다.
        </p>
      </div>
    );
  };
