/** @jsx jsx */
import React from 'react';

import { css, cx } from '@emotion/css';

import { router } from '^/userWeb/routes';
import {
  BackgroundColors, FontSizes, FontWeights, IconColors, TextColors,
} from '^/userWeb/styles/styleConstants';
import { SVGReactComponent, SVGReactProps } from '^/userWeb/types/SVGReactComponent';

const NavIcon: React.FC<SVGReactProps & {
  Icon: SVGReactComponent,
}> = ({
  Icon,
  ...svgProps
}) => {
    return (
      <Icon
        className={css`
        flex-grow: 0;
        width: 20px;
        height: 20px;
        fill: ${IconColors.black};
        `}
        {...svgProps}
      />
    );
  };

export const NavigationBar: React.FC<{
  // eslint-disable-next-line react/no-unused-prop-types
  LeftIcon?: SVGReactComponent;
  LeftIconProps?: SVGReactProps;
  title?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  RightIcon?: SVGReactComponent;
  RightIconProps?: SVGReactProps;
  trailingIconTapAction?: () => void;
  rootCSS?: string;
}> = (props) => {
  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 60px;
          padding-left: 20px;
          padding-right: 20px;
          align-items: center;
          background-color: ${BackgroundColors.white};
        `,
        props.rootCSS,
      )}
    >
      <div
        className={css`
          width: 44px;
          height: 44px;
          padding: 12px;
          margin-left: -12px;
          &:active {
            opacity: 0.5;
          }
        `}
      >
        {props.LeftIcon ? (
          <NavIcon
            Icon={props.LeftIcon}
            onClick={() => router.session.back()}
            {...props.LeftIconProps}
          />
        ) : null}
      </div>
      <p
        className={css`
          flex-grow: 1;
          color: ${TextColors.black};
          font-size: ${FontSizes.lg};
          font-weight: ${FontWeights.medium};
          text-align: center;
          margin-left: 12px;
          margin-right: 12px;
          user-select: none;
        `}
      >{props.title}
      </p>
      <div
        className={css`
          width: 44px;
          height: 44px;
          padding: 12px;
          margin-right: -12px;
          &:active {
            opacity: 0.5;
          }
        `}
      >
        {props.RightIcon ? (
          <NavIcon
            Icon={props.RightIcon}
            {...props.RightIconProps}
          />
        ) : (
          <div
            className={css`
              flex-grow: 0;
              width: 20px;
              height: 20px;
            `}
          />
        )}
      </div>
    </div>
  );
};
