import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { ExtractPromiseSuccess } from '^/types/__ResultType';
import { LiveCamView } from '^/userWeb/Components/LiveCam/LiveCamView';
import { VirtualizedSwipeableViews } from '^/userWeb/Components/Slides/Swiper';
import { usePrevious } from '^/userWeb/hooks/usePrevious';
import { lockScreenOrientation } from '^/userWeb/lockScreenOrientation';
import { portalDOM } from '^/userWeb/Portal';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

import { LiveCamsSliderFullsCreen } from './LiveCamsSlider.FullScreen';
import type { LiveCamsSliderAreaViewModel } from './LiveCamsSliderArea.viewmodel';

export const LiveCamsSlider: React.FC<{
  slideIndex: MobxValue<number>;
  liveCamsResult: ExtractPromiseSuccess<LiveCamsSliderAreaViewModel['liveCams']>;
  isFullScreen: MobxValue<boolean>;
  videoContainerClassName?: string;
}> = observer(function LiveCamsSlider({
  slideIndex,
  liveCamsResult,
  isFullScreen,
  videoContainerClassName,
}) {
  const isCurrentFullScreen = isFullScreen.value;
  const prevIsFullScreen = usePrevious(isFullScreen.value);
  useEffect(() => {
    if (!(!prevIsFullScreen) === isFullScreen.value) return;
    if (isCurrentFullScreen) {
      try {
        portalDOM.requestFullscreen();
        // setTimeout(async function() {
        //   try {
        //     await lockScreenOrientation('portrait');
        //   } catch (e) {
        //     console.error(e);
        //   }
        // }, 100);
      } catch (e) {
        console.error('LiveCamsSlider::request');
        // debugger;
      }
      console.log('ENTER FULLSCREEN');
    } else {
      const { webkitExitFullscreen } = window.document as any;
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen()
          .catch((err) => {
            console.error(err);
          });
      } else if (webkitExitFullscreen) {
        try {
          webkitExitFullscreen()
            .catch((err: unknown) => console.log(err));
        } catch (e) {
          // ...
        }
      }
    }
  }, [isCurrentFullScreen]);

  if (isCurrentFullScreen) {
    return (
      <LiveCamsSliderFullsCreen
        slideIndex={slideIndex}
        liveCamsResult={liveCamsResult}
        isFullScreen={isFullScreen}
      />
    );
  }
  if (liveCamsResult.length === 0) {
    return <p> 카메라가 없습니다 </p>;
  }
  return (
    <VirtualizedSwipeableViews
      // onClick={() => isFullScreen.setValue(!isFullScreen.value)}
      id="SliderView"
      enableMouseEvents
      className={css`
        margin-top: 20px;
        height: calc((100vw * 9) / 16);
        padding-left: 5px;
        padding-right: 20px;
        width: 100%;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
      `}
      slideClassName={css`
        padding-left: 5px;
        padding-right: 5px;
        height: 'calc((100vw - 20px) * 9 / 16)';
      `}
      index={slideIndex.value}
      slideCount={liveCamsResult.length}
      onChangeIndex={(index) => {
        slideIndex.setValue(index);
      }}
      overscanSlideAfter={1}
      overscanSlideBefore={1}
      min={0}
      max={liveCamsResult.length}
      slideRenderer={({
        index,
      }) => {
        const cam = liveCamsResult[index];
        if (!cam) {
          return null;
        }
        if (cam.status === 'loading') {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-loading`}
              className={videoContainerClassName}
            > Loading... 222
            </div>
          );
        }
        if (cam.status === 'failure') {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-error`}
              className={videoContainerClassName}
            > Error
            </div>
          );
        }
        return (
          <LiveCamView
            key={cam.value.camId || index}
            className={videoContainerClassName}
            viewModel={cam.value}
            onClickPlayingVideo={() => {
              console.log('CLICK PLAYING VIDEO');
              isFullScreen.setValue(true);
            }}
          />
        );
      }}
    />
  );
});
