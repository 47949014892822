import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { ReactComponent as BellIcon } from '^/userWeb/Assets/Icons/bell.svg';
import { ReactComponent as LogoutIcon } from '^/userWeb/Assets/Icons/logout.svg';
import { SettingItem } from '^/userWeb/Components/SettingItems/SettingItem';
import { SettingItemSectionHeader } from '^/userWeb/Components/SettingItems/SettingItemSectionHeader';
import { routes } from '^/userWeb/routes';
import { borders } from '^/userWeb/styles/commonClasses';
import {
  AccentColors, FontSizes, FontWeights, TextColors,
} from '^/userWeb/styles/styleConstants';

import { MyPageViewModel } from './MyPage.viewmodel';

export const MyPage: React.FC<{
  viewmodel: MyPageViewModel,
}> = observer(function MyPage({
  viewmodel,
}) {
  const name = viewmodel.myName.status === 'success'
    ? `${viewmodel.myName.value}님`
    : null;
  return (
    <div
      id="MyPageRoot"
      className={[
        css`
          display: flex;
          flex-direction: column;
          position: fixed;
          border: 0px;
          max-height: fit-content;
          width: 100%;
          overflow: scroll;
        `,
        borders.DEBUG_blueBorderThickDashed,
      ].join(' ')}
    >
      <p
        className={css`
          color: ${TextColors.black};
          font-size: ${FontSizes.xxxl};
          font-weight: ${FontWeights.bold};
          margin-top: 24px;
          margin-left: 20px;
          margin-right: auto;
          margin-bottom: 0px;
        `}
      >
        안녕하세요
        <br />
        {name}
      </p>
      <div
        className={css`
          height: 100%;
          overflow: scroll;
        `}
      >
        <SettingItemSectionHeader
          title="일반"
          rootCSS={
            css`
              font-size: ${FontSizes.lg};
              margin-top: 40px;
            `
          }
        />
        <SettingItem
          title="알림설정"
          onClick={() => {
            routes.notificationSetting().push();
          }}
          isEnterable
          Icon={BellIcon}
          iconFillColor={AccentColors.yellow}
          rootCSS={
            css`
              width: 100%;
            `
          }
        />
        <SettingItem
          title="로그아웃"
          onClick={() => viewmodel.logout()}
          isEnterable={false}
          Icon={LogoutIcon}
          iconFillColor={AccentColors.blue}
          rootCSS={
            css`
              width: 100%;
            `
          }
        />
      </div>
    </div>
  );
});

export default MyPage;
