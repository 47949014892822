import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

import { ExtractPromiseSuccess } from '^/types/__ResultType';
import { FullScreenSwiper } from '^/userWeb/Components/FullScreenSwiper/FullScreenSwiper';
import { LiveCamView } from '^/userWeb/Components/LiveCam/LiveCamView';
import { ViewByState } from '^/userWeb/Components/ViewByState';
import { useInstance } from '^/userWeb/hooks/useInstance';
import { portalDOM } from '^/userWeb/Portal';
import { borders } from '^/userWeb/styles/commonClasses';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

import type { LiveCamsSliderAreaViewModel } from './LiveCamsSliderArea.viewmodel';

export const LiveCamsSliderFullsCreen: React.FC<{
  slideIndex: MobxValue<number>;
  liveCamsResult: ExtractPromiseSuccess<LiveCamsSliderAreaViewModel['liveCams']>;
  isFullScreen: MobxValue<boolean>;
}> = observer(function LiveCamsSliderFullsCreen({
  slideIndex,
  liveCamsResult,
  isFullScreen,
}) {
  const isFullScreenPinchableDraggable = useInstance(() => new MobxInputValue(false));

  return createPortal(
    <FullScreenSwiper
      items={liveCamsResult}
      isSwiperDisabled={isFullScreenPinchableDraggable.current}
      slideIndex={slideIndex.value}
      setSlideIndex={slideIndex.setValue}
      closeFullScreen={() => {
        isFullScreen.setValue(false);
      }}
      preloadPrevCount={1}
      preloadNextCount={1}
      renderFunc={(
        item,
        nth,
      ) => (
        <ViewByState
          data={item}
          errorView={<div> error </div>}
          loadingView={<div> loading </div>}
        >
          {(value) => {
            return (
              <div
                className={[
                  css`
                    width: 100%;
                    height: 100%;
                  `,
                  // borders.DEBUG_blueBorderThickDashed,
                ].join(' ')}
              >
                <LiveCamView
                  key={value.camId || nth}
                  viewModel={value}
                />
              </div>
            );
          }}
        </ViewByState>
      )}
    />,
    portalDOM,
  );
});
