import type { DetectionType } from '@prisma/client';
import { makeObservable } from 'mobx';

import {
  asSuccessPromise,
  getCurrentFreshness,
} from '^/types/__ResultType';
import { BaseViewModel } from '^/userWeb/BaseViewModel';
import { router, routes } from '^/userWeb/routes';
import { AppContext } from '^/userWeb/types/AppContext';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

export class CowMountHistoryPageViewModel extends BaseViewModel {
  constructor(
    public appContext: AppContext,
    public startDate = new MobxValue<Date | null>(null),
    public endDate = new MobxValue<Date | null>(new Date()),
    public detectionType: DetectionType | null = 'ride',
    public fullScreenNth: number | null = null,
    public detectionType$M = new MobxValue(detectionType),
    public fullScreenNth$M = new MobxInputValue<number | null>(
      fullScreenNth,
      (
        newFullScreenNth,
        prevFullScreenNth,
      ) => {
        // todo: update route...
        if (prevFullScreenNth === null) {
          // push route
          routes.cowMountingHistory({
            detectionType: this.detectionType || undefined,
            freshness: this.freshness,
            fullscreenNth: newFullScreenNth || undefined,
          }).push({
            skipRender: true,
          });
        } else {
          // replace route
          routes.cowMountingHistory({
            detectionType: this.detectionType || undefined,
            freshness: this.freshness,
            fullscreenNth: newFullScreenNth ?? undefined,
          }).replace({
            // skipRender: true,
          });
        }
      },
    ),
    public freshness = getCurrentFreshness(),
  ) {
    super(appContext, freshness);
    makeObservable(this, {
      list: true, filteredList: true,
      //   list: true,
      //   filteredList: true,
      //   detectionType: true,
    });
  }

  get list() {
    if (this.myInfo.status !== 'success') {
      return this.myInfo;
    }
    const result = this.appContext.cowMountListCore.getList({
      // TODO: remove...?
      __CowMountHistoryListFilterType: null,
      userId: this.myInfo.value[0].id,
      userToken: this.myInfo.value[1],
    }, this.freshness);

    console.log('const result = this.appContext.cowMountListCore.getList({', result);
    if (result.status !== 'success') {
      return result;
    }
    console.log("if (result.status !== 'success') {");

    return asSuccessPromise(result.value);
  }

  get filteredList() {
    console.log('this.list', this.list);
    if (this.list.status !== 'success') {
      return this.list;
    }

    return asSuccessPromise(this.list.value
      .filter((r) => {
        console.log('filteredList r.status', r.status);
        if (r.status !== 'success') {
          return true;
        }

        const createdAtTimestamp = (new Date(r.value.createdAt)).getTime(); // 8월 9일
        const startDateTimestamp = this.startDate.value?.getTime(); // 10월 10일
        if (startDateTimestamp && startDateTimestamp > createdAtTimestamp) {
          return false;
        }
        const endDateTimestamp = this.endDate.value?.getTime();
        if (endDateTimestamp && endDateTimestamp < createdAtTimestamp) {
          return false;
        }

        if (this.detectionType$M.value && r.value.detectionType !== this.detectionType$M.value) {
          return false;
        }

        return true;
      }),
      this.freshness,
    );
  }
}
