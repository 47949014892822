import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import type { CameraType } from '^/types/cameraTypes';
import { borders } from '^/userWeb/styles/commonClasses';

export const CameraItem = observer(function CameraItem(p: {
  camera: CameraType,
}) {
  return (
    <div
      className={[
        css`
        
        `,
        borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
    >
      <p>{p.camera.phone}</p>
      <p>{p.camera.name}</p>
      <p>{p.camera.cameraIp}</p>
    </div>
  );
});
