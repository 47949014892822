import React, { useRef } from 'react';

import { observer } from 'mobx-react-lite';

import { LoadedMySettingsPage } from './LoadedMySettingsPage';
import { MySettingsPageViewModel } from './MySettingsPage.viewmodel';

export const MySettingsPage: React.FC<{
  viewmodel: MySettingsPageViewModel,
}> = observer(function MySettingsPage({
  viewmodel,
}) {
  if (viewmodel.loadedViewModel.status === 'loading') {
    return (
      <p>loading</p>
    );
  }
  if (viewmodel.loadedViewModel.status === 'failure') {
    return (
      <div>
        error
        <pre>
          {JSON.stringify(
            viewmodel.loadedViewModel.failure,
            null,
            2,
          )}
        </pre>
      </div>
    );
  }
  return (
    <LoadedMySettingsPage
      viewmodel={viewmodel.loadedViewModel.value}
    />
  );
});

export default MySettingsPage;
