/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import sanitizeFileName from 'sanitize-filename';

import { CameraIDType } from '^/types/cameraTypes';
import LoadingCircle from '^/userWeb/Assets/LottieAnimations/loading_circle2.svg';
import { BackgroundColors } from '^/userWeb/styles/styleConstants';

import { HLSPlayer } from './HLSPlayer';
import { LiveCamViewViewModel } from './LiveCamView.viewmodel';

export const LiveCamView: React.FC<{
  viewModel: LiveCamViewViewModel,
  className?: string,
  onClickPlayingVideo?: () => void,
}> = observer(function LiveCamView({
  viewModel,
  className,
  onClickPlayingVideo,
}) {
  const isJupiter = viewModel.processedBy?.toLowerCase().includes('jupiter');
  const thatPerson = viewModel.phone === '01041400320';
  const camIdTemp = !isJupiter && thatPerson ? viewModel.camId : sanitizeFileName(viewModel.cameraIp);

  console.log(`isJupiter ${isJupiter}`);
  console.log(`thatPerson ${thatPerson}`);
  console.log(`camIdTemp ${camIdTemp}`);
  const { camId } = viewModel;

  return (
    <div
      className={cx(
        css`
          position: relative;
          height: 100%;
        `, className,
      )}
      id="livecam_container"
    >

      <HLSPlayer
        className={css`
            height: 100%;
            width:100%;
            object-fit: fill;
            background: ${BackgroundColors.gray_light} url('${LoadingCircle}') 50% 50% / 50px no-repeat;
          `}
        camId={camId as CameraIDType}
        key={viewModel.camId}
        onDoubleClick={(e) => {
          if (
            !e.currentTarget.paused
            && onClickPlayingVideo
          ) {
            onClickPlayingVideo();
          }
        }}
        onClick={(e) => {}}
      // onClick={(e) => {
      //   if (
      //     !e.currentTarget.paused
      //     && onClickPlayingVideo
      //   ) {
      //     onClickPlayingVideo();
      //   }
      // }}
      />
    </div>
  );
});
