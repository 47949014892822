import { makeObservable } from 'mobx';

import { FreshnessType, mergeSuccessResult } from '^/types/__ResultType';

import { AppContext } from './types/AppContext';

export class BaseViewModel {
  constructor(
    public appContext: AppContext,
    public freshness: FreshnessType,
  ) {
    makeObservable(this, {
      appContext: false,
      userToken: true,
      myInfo: true,
    });
  }

  get userToken() {
    return this.appContext.tokenRepo.userToken;
  }

  get myInfo() {
    if (this.userToken.status !== 'success') {
      return this.userToken;
    }
    const myInfoResult = this.appContext.myInfoCore
      .myInfo(this.userToken.value, this.freshness);
    console.log('myInfoResult', myInfoResult);
    return mergeSuccessResult(
      myInfoResult, this.userToken.value,
    );
  }
}
