import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { IotDeviceIdT } from '^/__generated__/pothosZod/generated_scalars';
import { getCurrentFreshness } from '^/types/__ResultType';
import { AddIotDeviceForm } from '^/userWeb/Components/Forms/IotDeviceForm/AddIotDeviceForm';
import { EditIotDeviceForm } from '^/userWeb/Components/Forms/IotDeviceForm/EditIotDeviceForm';
import { UserSingleSelectList } from '^/userWeb/Components/Lists/UsersList/UserSingleSelectList';
import { HeaderTextField } from '^/userWeb/Components/TextFields/HeaderTextField';
import { borders } from '^/userWeb/styles/commonClasses';

import type { AdminIotDevicePageViewModel } from './AdminIotDevicePage.viewmodel';

export const AdminIotDevicePageEditPopup = observer(function AdminIotDevicePageAddPopup(p: {
  viewmodel: AdminIotDevicePageViewModel,
  editDeviceId: IotDeviceIdT,
}) {
  return (
    <div
      className={[
        css`
          pointer-events: all;
          width: 100%; height: 100%;
          background-color: rgba(100, 100, 100, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
        `,
      ].join(' ')}
    >
      <div
        className={[
          // borders.DEBUG_pinkDashed,
          css`
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            border-radius: 12px;
            background-color: white;
            filter: drop-shadow(8px, 8px, 4px, black);
            display: flex;
            position: relative;
          `,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      >
        <button
          type="button"
          className={css`
            position: absolute;
            right: 8px;
            top: 8px;
          `}
          onClick={() => {
            p.viewmodel.visibleForm.set(null);
          }}
        >
          닫기
        </button>
        <div
          className={[
            css`
              display: flex;
              flex-wrap: wrap;
              height: 100%;
              padding: 16px;
              gap: 20px;
            `,
            borders.DEBUG_greenBorderDashed,
          ].join(' ')}
        >
          <AddIotDeviceForm
            viewmodel={p.viewmodel.addDeviceFormVM}
          />
          {/* <EditIotDeviceForm
            viewmodel={p.viewmodel.editDeviceFormViewModel}
          /> */}
          <div
            id="userListWrapper"
            className={[
              css`
                display: flex;
                height: 100%;
              `,
              borders.DEBUG_pinkDashed,
            ].join(' ')}
          >
            <div
              className={[
                css`
                  display: flex;
                  flex-direction: column;
                `,
                // borders.DEBUG_blueBorderThickDashed,
              ].join(' ')}
            >
              <HeaderTextField
                text={p
                  .viewmodel
                  .userPickerVM
                  .listVM
                  .filter}
                header="검색"
                placeholder="(전화번호나 이름 일부)"
                rootCSS=""
              />
              <UserSingleSelectList
                viewmodel={p
                  .viewmodel
                  .userPickerVM}
                stylesoverride={{
                  list: {
                    root: [
                      css`
                        flex-grow: 1;
                        overflow: scroll;
                        /* height: calc(100% - 50px); */
                      `,
                      borders.DEBUG_blueBorderDashed,
                    ].join(' '),
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
