import { makeAutoObservable } from 'mobx';

import {
  LoadingPromise,
  NotInitiatedPromiseStale,
  asSuccessPromise,
} from '^/types/__ResultType';
import { extractTokenData } from '^/utils/token/forClient';

import type { LoginAPIResult } from '../apiTypes/login';
import { MockLocalStorage } from '../utils/MockLocalStorage';

if (global && !global.localStorage) {
  global.localStorage = new MockLocalStorage();
}

const tokenKey = 'UserToken';

export class TokenRepo {
  constructor(
    public userToken: LoginAPIResult | LoadingPromise = NotInitiatedPromiseStale,
  ) {
    const maybeTokenStringV1 = localStorage.getItem(tokenKey);
    if (maybeTokenStringV1) {
      this.userToken = JSON.parse(maybeTokenStringV1);
    }
    makeAutoObservable(this);
  }

  setUserToken(token: typeof this.userToken) {
    localStorage.setItem(tokenKey, JSON.stringify(token));
    this.userToken = token;
  }

  get userTokenWithData() {
    if (this.userToken.status !== 'success') {
      return this.userToken;
    }
    return asSuccessPromise({
      token: this.userToken.value,
      tokenData: extractTokenData(this.userToken.value),
    }, this.userToken.freshness);
  }
}
