import { css } from '@emotion/css';

import {
  ReactComponent as ChevronDown,
} from '^/userWeb/Assets/Icons/chevron-down.svg';
import {
  ReactComponent as ChevronLeft,
} from '^/userWeb/Assets/Icons/chevron-left.svg';
import {
  ReactComponent as ChevronRight,
} from '^/userWeb/Assets/Icons/chevron-right.svg';
import {
  ReactComponent as ChevronUp,
} from '^/userWeb/Assets/Icons/chevron-up.svg';

const leftAreaCss = css`
  position: absolute;
  height: 100%;
  width: 50px;
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%, rgba(0,0,0,0) 100%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const chevronStyleBase: React.CSSProperties = {
  width: 30,
  height: 30,
  color: 'white',
  fill: 'white',
  stroke: 'yellow',
};
export const SwiperArrow: React.FC<{
  isLandscape: boolean,
  prevFn: () => void,
  nextFn: () => void,
  numSlides: number,
  nthSlide: number,
  dim: boolean,
}> = function SwiperArrow(
  props,
) {
    const isPrevDisabled = props.nthSlide === 0;
    const isNextDisabled = props.nthSlide === props.numSlides - 1;

    if (props.isLandscape) {
      return (
        <>
          {!isPrevDisabled && (
            <div
              className={[
                leftAreaCss,
                css`
                  left: 0;
                `,
              ].join(' ')}
              style={{
                opacity: props.dim ? 0.6 : 1,
              }}
              role="button"
              aria-label="prev"
              tabIndex={-1}
              onClick={props.prevFn}
            >
              <ChevronLeft
                style={{
                  ...chevronStyleBase,
                  strokeWidth: props.dim ? 20 : 50,
                }}
              />

            </div>
          )}
          {!isNextDisabled && (
            <div
              className={[
                leftAreaCss,
                css`
                  right: 0;
                  transform: rotate(180deg);
                `,
              ].join(' ')}
              style={{
                opacity: props.dim ? 0.6 : 1,
              }}
              role="button"
              aria-label="next"
              tabIndex={-1}
              onClick={props.nextFn}
            >
              <ChevronLeft
                style={{
                  ...chevronStyleBase,
                  strokeWidth: props.dim ? 20 : 50,
                }}
              />

            </div>
          )}
        </>
      );
    }
  };
