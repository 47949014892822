import { makeObservable } from 'mobx';
import {
  shareReplay,
  startWith,
  Subject,
  tap,
  throttleTime,
} from 'rxjs';

import {
  FreshnessType, NotInitiatedPromiseStale, PromiseResultType, getCurrentFreshness,
} from '^/types/__ResultType';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';

import { IWithDispose, registerForDispose } from '../utils/withDisposeClass';

class AutoSyncedCaller3<
  T extends PromiseResultType<any, any, any>,
  TArgs extends {},
  TFresh,
> implements IWithDispose {
  constructor(
    public apiFn: (args: TArgs) => Promise<T>,
    public args: TArgs,
    public refreshTrigger: Subject<TFresh>,
    public initialFresh?: TFresh,

    public value = new MobxInputValue<
      T | typeof NotInitiatedPromiseStale
    >(NotInitiatedPromiseStale),
    private _subscription = refreshTrigger
      .pipe(
        initialFresh ? startWith(initialFresh) : tap(),
        throttleTime(200),
        shareReplay({
          bufferSize: 2,
          refCount: true,
        }),
      )
      .subscribe(async (freshness) => {
        // TODO: use rxjs properly!
        const response = await apiFn(args);
        this.value
          .set(
            response,
          );
      }),
  ) {
    // eslint-disable-next-line no-underscore-dangle
    makeObservable(this, {
      value: true,
      dispose: true,
    });
  }

  dispose(): void {
    // eslint-disable-next-line no-underscore-dangle
    this._subscription.unsubscribe();
  }
}

export function createAutoSyncedCaller3<
  T extends PromiseResultType<any, any, any>,
  TArgs extends {},
  TFresh,
>(
  apiFn: (args: TArgs) => Promise<T>,
  args: TArgs,
  refreshTrigger$ = new Subject<TFresh>(),
  initialFreshness?: TFresh,
) {
  return registerForDispose(
    new AutoSyncedCaller3(
      apiFn, args, refreshTrigger$, initialFreshness,
    ),
    'AutoSyncedCaller3',
  );
}
