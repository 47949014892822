import { makeAutoObservable } from 'mobx';

import { FreshnessType } from '^/types/__ResultType';

export class FreshnessRepo {
  constructor(
    public freshness: FreshnessType = 0 as FreshnessType,
  ) {
    makeAutoObservable(this);
  }
}
