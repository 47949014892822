/** @jsx jsx */
import React from 'react';

import {
  css, cx,
} from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';

import { BaseTextFieldStyle } from './__BaseTextFieldStyle';
import { TextFieldHeaderStyle } from './TextFieldHeaderStyle';

export const HeaderIntTextField: React.FC<{
  text: MobxInputValue<number>,
  header: string;
  placeholder: string;
  rootCSS: string;
}> = observer(function HeaderTextField(props) {
  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
        `,
        props.rootCSS,
      )}
    >
      <p className={cx(
        TextFieldHeaderStyle,
      )}
      >{props.header}
      </p>
      <h1>{props.text.value}</h1>
      <input
        type="text"
        inputMode="numeric" pattern="[0-9]*"
        className={cx(
          BaseTextFieldStyle,
          css`
            margin-top: 8px;
          `,
        )}
        placeholder={props.placeholder}
        value={String(props.text.value) || ''}
        onChange={(e) => {
          const newValue = Number(e.currentTarget.value);
          if (Number.isNaN(newValue)) {
            return;
          }
          props
            .text
            .set(newValue);
        }}
      />
    </div>
  );
});
