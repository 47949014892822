import { css } from '@emotion/css';
import { observable } from '@trpc/server/observable';
import { m } from 'framer-motion';
import { observer } from 'mobx-react-lite';

import { DeviceRelayNames, DeviceRelayNamesKeys, DeviceRelayStatusTypes } from '^/iotInterface/RelayConstantsV1';
import { ViewByState } from '^/userWeb/Components/ViewByState';
import { borders } from '^/userWeb/styles/commonClasses';

import { RelayControlSwitch } from './RelayControlSwitch';
import { SensorsDetailPopupViewModel } from './SensorsDetail.popup.viewmodel';

export const RelayControl = observer(function RelayControl(p: {
  viewmodel: SensorsDetailPopupViewModel,
}) {
  // const isActive = p
  //   .viewmodel
  //   .activeView
  //   .value
  //   .type === 'RelayControl';
  const isActive = true;
  return (
    <m.div
      layout="position"
      // layoutId="aa11"
      className={[
        css`
          width: 100%;
          height: 100%;
          overflow-y: scroll;
        `,
        // borders.DEBUG_greenBorderDashed,
      ].join(' ')}
    >
      <m.div
        layout="position"
        id="RelayControl_DEBUG_INFO"
        className={[
          css`
            /* position: absolute;
            top: 20px;
            left: 20px; */
            display: none;
          `,
        ].join(' ')}
      >
        <m.h1
          layout="position"
        > RelayControl!
        </m.h1>
        <h3> isActive: {JSON.stringify(isActive)} </h3>
      </m.div>
      <ViewByState
        data={p.viewmodel.relayData}
        loadingView={<p> loading </p>}
        errorView={(e) => {
          return (
            <div>
              <p> error </p>
              <p>{e.reason}</p>
            </div>
          );
        }}
      >
        {(v) => {
          const stats = DeviceRelayNamesKeys
            .map((nameKey) => {
              const k = DeviceRelayNames.enum[nameKey];
              const deviceLog = v.groupedByDevice.get(k);
              const lastStatus = deviceLog && deviceLog[0];

              return (
                <RelayControlSwitch
                  name={nameKey}
                  status={lastStatus?.deviceState || DeviceRelayStatusTypes.enum.stop}
                  isViewOnly={!isActive}
                  onChange={(newStatus) => {
                    p.viewmodel.sendRelayState(
                      k,
                      newStatus,
                    );
                  }}
                />
              );
            });
          return (
            <div>
              {stats}
            </div>
          );
        }}
      </ViewByState>

    </m.div>
  );
});
