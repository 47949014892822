import { makeObservable } from 'mobx';

import { getCurrentFreshness } from '^/types/__ResultType';
import { NoticeIdType } from '^/types/notice';
import { BaseViewModel } from '^/userWeb/BaseViewModel';
import { AppContext } from '^/userWeb/types/AppContext';

export class NoticeTabViewModel extends BaseViewModel {
  constructor(
    public appContext: AppContext,
    public freshness = getCurrentFreshness(),
    public expanded = new Set<NoticeIdType>(),
  ) {
    super(appContext, freshness);
    makeObservable(this, { notices: true });
  }

  get notices() {
    if (this.myInfo.status !== 'success') {
      return this.myInfo;
    }

    const noticeList = this.appContext.noticesListCore.getList({
      __NoticesListFilterType: null,
      userToken: this.myInfo.value[1],
      userId: this.myInfo.value[0].id,
    }, this.freshness);
    return noticeList;
  }
}
