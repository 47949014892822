import { toJS } from 'mobx';

import type { FreshnessType, PromiseResultType } from '^/types/__ResultType';

import { MobxInputValue } from './MobxInputValue';

export function syncMutationProcess<
  TResult extends PromiseResultType<any, any, any>,
>(
  runFn: () => Promise<TResult>,
  syncResultTo = new MobxInputValue<TResult>({
    status: 'loading',
    freshness: -1 as FreshnessType,
  } as TResult),
) {
  async function syncMutationProcessRun() {
    const result = await runFn();
    syncResultTo.set(result);
  }
  const resultingPromise = syncMutationProcessRun();
  return {
    syncResultTo,
    resultingPromise,
  };
}

export const MutationNotInitiatedAtAllValue = {
  status: 'MUTATION_NOT_INITIATED',
} as const;
export const MutationNotInitiatedAtAll = {
  status: 'not_initialized',
  m: MutationNotInitiatedAtAllValue,
  value: MutationNotInitiatedAtAllValue,
  freshness: -100 as FreshnessType,
} as const;

export function isMutationNotInitiatedAtAll<
  TResult extends PromiseResultType<any, any, any> | typeof MutationNotInitiatedAtAll,
>(
  r: TResult,
) {
  if (r.status === 'loading'
    && r.value && toJS(r.value) === MutationNotInitiatedAtAllValue
  ) {
    return true;
  }
  return false;
}
export function syncedMutationFunc<
  TResult extends PromiseResultType<any, any, any>,
  TArgs = undefined,
>(
  runFn: (args: TArgs) => Promise<TResult>,
  syncResultTo = new MobxInputValue<TResult | typeof MutationNotInitiatedAtAll>(
    MutationNotInitiatedAtAll,
  ),
) {
  async function runMutationFunc(args: TArgs) {
    const result = await runFn(args);
    syncResultTo.set(result);
    return result;
  }
  return {
    runMutationFunc,
    syncResultTo,
    reset() {
      syncResultTo.set(MutationNotInitiatedAtAll);
    },
  };
}
