import React, { PropsWithChildren } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { RouteNamesType } from '^/userWeb/routes';
import { borders } from '^/userWeb/styles/commonClasses';

import { AdminTabBar } from './AdminTabBar';
import { TabBar } from './TabBar';

export const WithTabBar: React.FC<PropsWithChildren<{
  routeName: RouteNamesType | false;
  withAdminTabs?: boolean;
}>> = observer(function WithTabBar({
  routeName,
  children,
  withAdminTabs,
}) {
  return (
    <div
      id="with-tabBar-root"
      className={css`
          width: 100%;
          height: 100%;
          /* position: absolute; */
          display: flex;
          flex-direction: column;
        `}
    >
      <div
        className={[
          css`
              /* position: absolute;
              top: 0;left: 0;right: 0;
              bottom: 56px; */
              flex-grow: 1;
              overflow: scroll;
            `,
          // borders.DEBUG_blueBorderThickDashed,
        ].join(' ')}
      >
        {children}
      </div>
      {(withAdminTabs || null) && (
        <AdminTabBar
          routeName={routeName}
        />
      )}
      <TabBar
        routeName={routeName}
        rootCSS={css`
            height: 56px;
            flex-basis: 56px;
            flex-shrink: 0;
          `}
      />
    </div>
  );
});
