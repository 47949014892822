/** @jsx jsx */
import React from 'react';

import { css, cx } from '@emotion/css';

import { FontSizes, FontWeights, TextColors } from '^/userWeb/styles/styleConstants';

export const SettingItemSectionHeader: React.FC<{
  title: string;
  rootCSS?: string;
}> = (props) => {
  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: row;
          height: 40px;
          padding-left: 20px;
          padding-right: 20px;
          color: ${TextColors.black_light};
          font-size: ${FontSizes.base};
          font-weight: ${FontWeights.regular};
          align-items: center;
        `,
        props.rootCSS,
      )}
    >
      <p
        className={css`
          margin: 0px;
          width: 100%;
        `}
      >
        {props.title}
      </p>
    </div>
  );
};
