import { makeAutoObservable } from 'mobx';

import { asSuccessPromise, FreshnessType } from '^/types/__ResultType';
import { UserIDType, UserTokenType } from '^/types/userTypes';
import { LiveCamViewViewModel } from '^/userWeb/Components/LiveCam/LiveCamView.viewmodel';
import { AppContext } from '^/userWeb/types/AppContext';
import { ensureValidCamUrl } from '^/utils/ensureValidCamURL';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

import { LiveCamStatusViewModel } from './LiveCamStatus.viewmodel';

export class LiveCamsSliderAreaViewModel {
  constructor(
    public appContext: AppContext,
    public userToken: UserTokenType,
    public userId: UserIDType,
    // public processedBy: string,
    // public phone: string,
    // public cameraIp: string,
    public freshness: FreshnessType,
    public slideIndex = new MobxValue(0),
    public isFullScreen = new MobxValue(false),
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }

  get data() {
    if (this.liveCamsStatuses.status !== 'success') {
      return this.liveCamsStatuses;
    }
    if (this.liveCams.status !== 'success') {
      return this.liveCams;
    }
    return asSuccessPromise({
      statuses: this.liveCamsStatuses.value,
      cams: this.liveCams.value, // LiveCamStatusViewModel
    }, this.liveCamsStatuses.freshness);
  }

  get liveCamsStatuses() {
    const liveCams = this.appContext.liveCamsListCore
      .getLiveCams({
        userId: this.userId,
        userToken: this.userToken,
      }, this.freshness);
    if (liveCams.status !== 'success') {
      return liveCams;
    }
    return asSuccessPromise(liveCams.value
      .map((v) => {
        if (v.status !== 'success') return v;
        return asSuccessPromise(
          new LiveCamStatusViewModel(
            this.appContext.apiClient,
            v.value.id,
            this.userToken,
            v.value.name,
          ),
        );
      }),
    );
  }

  get liveCams() {
    const liveCams = this.appContext.liveCamsListCore
      .getLiveCams({
        userId: this.userId,
        userToken: this.userToken,
      }, this.freshness);
    if (liveCams.status !== 'success') {
      return liveCams;
    }
    return asSuccessPromise(liveCams.value
      .map((l, nth) => {
        if (l.status !== 'success') {
          return l;
        }
        // eslint-disable-next-line prefer-destructuring
        const processedBy = l.value.processedBy;
        return asSuccessPromise(new LiveCamViewViewModel(
          this.appContext,
          l.value.id,
          processedBy,
          l.value.phone,
          l.value.cameraIp,
          `채널 ${nth}`, // FIXME: ...??????
          this.userToken,
        ));
      }));
  }
}
