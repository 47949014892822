import { css } from '@emotion/css';

import { FontSizes, FontWeights, TextColors } from '^/userWeb/styles/styleConstants';

export const TextFieldHeaderStyle = css`
  color: ${TextColors.black};
  padding-top: 0px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0px;
  font-size: ${FontSizes.base};
  font-weight: ${FontWeights.regular};
  margin: 0px;
`;
