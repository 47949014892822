import { makeAutoObservable, makeObservable, runInAction } from 'mobx';

import {
  asSuccessPromise,
  ExtractFailures,
  FreshnessType,
  NotInitiatedPromiseStale,
  PromiseResultType,
} from '^/types/__ResultType';
import { DetectedVisitorIdType } from '^/types/DetectedVisitorType';
import { UserIDType, UserTokenType } from '^/types/userTypes';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';

import { APIClient } from '../apiClient';
import type { FailedPromiseDueToTRPCClientError } from '../apiClient/types';
import { GetCowMountHistoryListFailResult } from '../apiTypes/getCowMountHistoryInfo';
import { DetectedVisitorsRepo } from '../repositories/DetectedVistorsRepo';

type SerializedFilter = string & {
  CarPersonDetectedSerializedFilter: null;
};
export type CarPersonDetectedListFilterType = {
  __CarPersonDetectedListFilter: null;
  userId: UserIDType;
  userToken: UserTokenType;
};

function serializeFilter(
  filter: CarPersonDetectedListFilterType,
) {
  return JSON.stringify(
    filter,
  ) as SerializedFilter;
}

export class DetectedVisitorsListCore {
  constructor(
    public apiClient: APIClient,
    public detectedVisitorsRepo: DetectedVisitorsRepo,
    public carPersonIDsByFilter: Record<
      /* eslint-disable @typescript-eslint/indent */
      SerializedFilter,
      PromiseResultType<
        DetectedVisitorIdType[],
        ExtractFailures<
          GetCowMountHistoryListFailResult
          | FailedPromiseDueToTRPCClientError<any>
        >
      >
    > = {},
  ) {
    // makeObservable(this, {
    //   getList: true,
    //   fetchList: true,
    // });
    makeAutoObservable(this, {
      apiClient: false,
    });
  }

  getList = computedFn2((
    filter: CarPersonDetectedListFilterType,
    freshness: FreshnessType,
  ) => {
    const cacheKey = serializeFilter(filter);
    this.fetchList(filter, freshness);
    const idsByFilter = this
      .carPersonIDsByFilter[cacheKey];
    if (!idsByFilter) {
      return NotInitiatedPromiseStale;
    }
    if (idsByFilter.status !== 'success') {
      return idsByFilter;
    }
    return asSuccessPromise(
      idsByFilter.value.map(
        (id) => this
          .detectedVisitorsRepo
          .get(id),
      ),
    );
  });

  fetchList = computedFn2(async (
    filter: CarPersonDetectedListFilterType,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    freshness: FreshnessType,
  ) => {
    const cacheKey = serializeFilter(filter);
    const fetchedData = await this.apiClient
      .detected
      .getCarPersonDetectionList
      .query({
        userId: filter.userId,
        userToken: filter.userToken,
      });
    if (fetchedData.status !== 'success') {
      this.carPersonIDsByFilter[cacheKey] = fetchedData;
      return;
    }
    const ids = fetchedData
      .value
      .map((detected) => detected.id);
    runInAction(() => {
      this.carPersonIDsByFilter[cacheKey] = asSuccessPromise(ids);
      fetchedData.value
        .forEach((detected) => {
          this.detectedVisitorsRepo
            .set(
              detected.id,
              asSuccessPromise(detected),
            );
        });
    });
  });
}
