import Fuse from 'fuse.js';
import { disassembleToString } from 'hangul-js';
import { makeObservable } from 'mobx';
import {
  BehaviorSubject,
  map,
} from 'rxjs';

import { type FreshnessType, getCurrentFreshness, asSuccessPromise } from '^/types/__ResultType';
import { extractTokenData } from '^/utils/token/forClient';

import type { APIClient } from '../apiClient';
import { refreshingAPICall$ } from '../autoSyncedCore/autoSyncedPromiseAPICaller';
import type { TokenRepo } from '../repositories/TokenRepo';
import { flattenMobxRxResult } from '../utils/FlattenMobxRxResult';

export class UserListCore {
  constructor(
    public apiClient: APIClient,
    public tokenRepo: TokenRepo,
    public refreshTrigger = new BehaviorSubject<FreshnessType>(
      getCurrentFreshness(),
    ),
  ) {
    makeObservable(this, {
      usersListFuse: true,
      usersListRes: true,
      usersList: true,
      usersList$Rx: true,
    });
  }

  get usersList$Rx() {
    const userToken$Mobx = this
      .tokenRepo
      .userToken;
    if (userToken$Mobx.status !== 'success') {
      return userToken$Mobx;
    }
    const decodedToken = extractTokenData(
      userToken$Mobx.value,
    );
    const apiCall$Sync = refreshingAPICall$(
      this
        .apiClient
        .user
        .getUsersList
        .query,
    );
    const apiCall$ = apiCall$Sync(
      this.refreshTrigger
        .pipe(
          map((v) => {
            debugger;
            return {
              userToken: userToken$Mobx.value,
              userId: decodedToken.userId,
            };
          }),
        ),
    );
    return asSuccessPromise(
      apiCall$,
      this.refreshTrigger.value,
    );
  }

  get usersListRes() {
    return flattenMobxRxResult(this.usersList$Rx);
  }

  get usersList() {
    return this.usersListRes.current().currentValue;
  }

  get usersListFuse() {
    if (this.usersList.status !== 'success') {
      return this.usersList;
    }
    const fuse = new Fuse(
      this.usersList.value,
      {
        ignoreFieldNorm: true,
        keys: [
          {
            name: 'name',
            getFn(obj) {
              return disassembleToString(obj.name);
            },
          },
          {
            name: 'phone',
            getFn(obj) {
              return obj.phone;
            },
          },
        ],
      },
    );
    return asSuccessPromise(fuse);
  }
}
