import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { ReactComponent as ArrowLeftIcon } from '^/userWeb/Assets/Icons/arrow-left.svg';
import { ReactComponent as SettingIcon } from '^/userWeb/Assets/Icons/setting.svg';
import { VerticalDivider } from '^/userWeb/Components/Dividers/VerticalDivider';
import { NavigationBar } from '^/userWeb/Components/NavigationBar/NavigationBar';
import { ViewByState } from '^/userWeb/Components/ViewByState';
import { routes } from '^/userWeb/routes';
import { fillParentElem, hideScrollBar } from '^/userWeb/styles/commonClasses';
import { BackgroundColors } from '^/userWeb/styles/styleConstants';

import { CowMountHistoryPageTopFilter } from './CowMountHistoryPage.topFilter';
import { CowMountHistoryPageViewModel } from './CowMountHistoryPage.viewmodel';
import { CowMountHistoryPageItemsRenderer } from './CowMountHistoryPageItemsRenderer';

export const CowMountHistoryPage: React.FC<{
  title: string;
  viewmodel: CowMountHistoryPageViewModel,
}> = observer(function CowMountHistoryPage(
  props,
) {
  return (
    <div
      className={cx(
        fillParentElem,
        css`
                  display: flex;
                  flex-direction: column;
                `,
      )}
      id="CowMountHistoryPageRoot"
      style={{
        // backgroundColor: BackgroundColors.white,
        backgroundColor: 'rgba(100, 150, 50, 0.2)',
        position: 'relative',
      }}
    >
      <NavigationBar
        LeftIcon={ArrowLeftIcon}
        title={props.title}
        RightIcon={SettingIcon}
        RightIconProps={{
          onClick: () => {
            routes.notificationSetting({})
              .push();
          },
        }}
        rootCSS={css`
                  /* height: 60px; */
                  flex-basis: 60px;
                `}
      />
      <div
        className={css`
                  display: flex;
                  flex-direction: column;
                  background-color: ${BackgroundColors.white};
                  width: 100%;
                  flex-basis: 65px;
                  /* height: 65px; */
                `}
      >
        <div
          className={cx(
            css`
                          display: flex;
                          flex-direction: row;
                          gap: 8px;
                          height: 100%;
                          overflow-x: scroll;
                          padding-left: 20px;
                          padding-right: 20px;
                          align-items: center;
                        `,
            hideScrollBar,
          )}
        >
          <CowMountHistoryPageTopFilter
            viewmodel={props.viewmodel}
          />
        </div>
        <div className={cx(
          VerticalDivider,
          css`
                      position: relative;
                      left: 0px;
                      right: 0px;
                      bottom: 0px;
                    `,
        )}
        />
      </div>
      <div
        id="CowMountHistoryPageContentArea"
        className={cx(
          css`
                      background-color: ${BackgroundColors.white};
                      width: 100%;
                      flex: 1;
                      overflow-y: scroll;
                    `,
          hideScrollBar,
        )}
      >
        <ViewByState
          data={props.viewmodel.filteredList}
          loadingView={(
            <div>
              loading...
            </div>
          )}
          errorView={(
            <div>
              에러
            </div>
          )}
        >

          {(mountHistories) => {
            console.log('mountHistories', mountHistories);
            return (
              <CowMountHistoryPageItemsRenderer
                items={mountHistories}
                fullScreenSliderIndex={props.viewmodel.fullScreenNth$M}
              />
            );
          }}
        </ViewByState>
      </div>
    </div>
  );
});

export default CowMountHistoryPage;
