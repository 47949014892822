import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { DeviceRelayStatusTypes, DeviceRelayStatusTypesT } from '^/iotInterface/RelayConstantsV1';
import { SegmentedControl } from '^/userWeb/Components/SegmentedControl/SegmentedControl';
import { borders } from '^/userWeb/styles/commonClasses';

// name, status, isActive, onChange
function statusToString(
  s: DeviceRelayStatusTypesT,
) {
  if (s === DeviceRelayStatusTypes.enum.open) {
    return '열림';
  }
  if (s === DeviceRelayStatusTypes.enum.closed) {
    return '닫힘';
  }
  if (s === DeviceRelayStatusTypes.enum.stop) {
    return '멈춤';
  }
}
export const RelayControlSwitch = observer(function RelayControlSwitch(p: {
  name: string;
  status: DeviceRelayStatusTypesT;
  isViewOnly: boolean;
  onChange(newStatus: DeviceRelayStatusTypesT): void;
}) {
  if (p.isViewOnly) {
    // return smaller
    return (
      <div
        className={[
          css`
            /* width: 15ch; */
            float: left;
            color: #333;
            margin-bottom: 4px;
            border-bottom: 1px dotted #111;
            margin-right: 8px;
            width: fit-content;
            /* text-align: right; */
          `,
        ].join(' ')}
      >
        {p.name}: {statusToString(p.status)}
      </div>
    );
  }
  return (
    <div>
      <span
        className={[
          css`
            font-size: 12px;
          `,
        ].join(' ')}
      >
        {p.name}
      </span>
      <SegmentedControl
        items={['열림', '멈춤', '닫힘']}
        selectedIndex={Number(p.status) - 1}
        setSelectIndex={(value) => {
          if (value === 0) {
            p.onChange('1');
          }
          if (value === 1) {
            p.onChange('2');
          }
          if (value === 2) {
            p.onChange('3');
          }
        }}
        rootCSS={css`
            flex-grow: 0;
            width: 100%;
            height: 30px;
          `}
      />
    </div>
  );
});
