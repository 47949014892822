/** @jsx jsx */
import React from 'react';

import { css, cx } from '@emotion/css';

import { SVGReactComponent } from '^/userWeb/types/SVGReactComponent';

import { ReactComponent as ChevronRightIcon } from '../../Assets/Icons/chevron-right.svg';
import {
  BackgroundColors, FontSizes, IconColors, TextColors,
} from '../../styles/styleConstants';

export const SettingItem: React.FC<{
  // eslint-disable-next-line react/no-unused-prop-types
  Icon: SVGReactComponent;
  iconFillColor: string;
  title: string;
  isEnterable: boolean;
  onClick?: () => void;
  rootCSS?: string;
}> = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className={cx(
        css`
          display: flex;
          flex-direction: row;
          gap: 12px;
          background-color: ${BackgroundColors.white};
          height: 52px;
          padding-left: 20px;
          padding-right: 20px;
          align-items: center;
          border: none;
          &:active {
            background-color: ${BackgroundColors.gray_light};
          }
        `,
        props.rootCSS,
      )}
    >
      <props.Icon
        className={css`
          flex-grow: 0;
          width: 20px;
          height: 20px;
          fill: ${props.iconFillColor};
        `}
      />
      <p
        className={css`
          flex-grow: 1;
          color: ${TextColors.black};
          font-size: ${FontSizes.lg};
          text-align: left;
        `}
      >
        {props.title}
      </p>
      <div
        className={css`
          flex-grow: 0;
        `}
      >
        {props.isEnterable ? (
          <ChevronRightIcon
            width="20px"
            height="20px"
            fill={IconColors.black_light}
          />
        ) : null}
      </div>
    </button>
  );
};
