import { useCallback, useEffect, useRef } from 'react';
/**
 * This hook provides a function that returns whether the component is still mounted.
 * This is useful as a check before calling set state operations which will generates
 * a warning when it is called when the component is unmounted.
 * @returns a function
 */
export function useMounted(
  name: string,
): () => boolean {
  const mountedRef = useRef(false);
  console.log(
    'useMounted::MOUNTING: ',
    name,
  );
  useEffect(function useMountedEffect() {
    console.log(
      'useMounted::MOUNTED: ',
      name,
    );
    mountedRef.current = true;
    return function useMountedEffectCleanup() {
      console.log(
        'useMounted::UNMOUNTED: ',
        name,
      );
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return useCallback(function isMounted() {
    return mountedRef.current;
  }, [mountedRef]);
}
