/* eslint-disable no-underscore-dangle */
import { action, makeObservable } from 'mobx';

import { FCMTokenType } from '^/types/pushTypes';

export class FCMTokenRepo {
  constructor(
    public _fcmToken: FCMTokenType | null = (window as any).fcmToken || null,
  ) {
    makeObservable(this, {
      fcmToken: true,
      setToken: action.bound,
    });
  }

  setToken(token: FCMTokenType) {
    console.log(`FCMTokenRepo:setToken(${token})`);
    this._fcmToken = token;
  }

  get fcmToken(): FCMTokenType | undefined {
    return this._fcmToken || (window as any).fcmToken;
  }
}
