import itiriri from 'itiriri';
import { makeAutoObservable } from 'mobx';
import { expr } from 'mobx-utils';
import {
  BehaviorSubject,
  map,
} from 'rxjs';

import type { IotDeviceIdT } from '^/__generated__/pothosZod/generated_scalars';
import { asSuccessPromise } from '^/types/__ResultType';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';
import { extractTokenData } from '^/utils/token/forClient';

import type { APIClient } from '../apiClient';
import { createAutoSyncedCaller3 } from '../autoSyncedCore/autoSyncedCaller3';
import { refreshingAPICall$ } from '../autoSyncedCore/autoSyncedPromiseAPICaller';
import type { TokenRepo } from '../repositories/TokenRepo';

const minInMillisec = 60 * 1000;
const dayInMillisec = 86_400_000;
const before60Days = new Date(
  (new Date()).getTime() - dayInMillisec * 60,
);
export class IotDevicesSensorDataListCore {
  constructor(
    public apiClient: APIClient,
    public tokenRepo: TokenRepo,
    public refreshTrigger = new BehaviorSubject<{
      laterThanDatetime: Date,
      beforeThanDatetime?: Date,
    }>({
      laterThanDatetime: before60Days,
    }),
  ) {
    makeAutoObservable(this, {
      apiClient: false,
      tokenRepo: false,
      refreshTrigger: false,
      getIotDeviceSensirData$Rx: false,
    });
  }

  getIotDeviceSensirData$Rx = computedFn2((
    args: {
      deviceId: IotDeviceIdT,
    }) => {
    const userToken$Mobx = this
      .tokenRepo
      .userToken;
    if (userToken$Mobx.status !== 'success') {
      return userToken$Mobx;
    }
    const decodedToken = extractTokenData(
      userToken$Mobx.value,
    );

    const apiCall$Sync = refreshingAPICall$(
      this
        .apiClient
        .iot
        .getPhysicalEnvStats
        .query,
    );

    const apiCall$ = apiCall$Sync(
      this.refreshTrigger
        .pipe(
          map((v) => {
            return {
              iotDeviceId: args.deviceId,
              userToken: userToken$Mobx.value,
              userId: decodedToken.userId,
              laterThanThisDate: v.laterThanDatetime,
            };
          }),
        ),
    ).pipe(
      map((data) => {
        if (data.status !== 'success') {
          return data;
        }
        return asSuccessPromise(
          itiriri(data.value),
        );
      }),
    );
    // TODO:
    return asSuccessPromise(apiCall$);
  });

  iotDeviceSensordataInner = computedFn2((
    args: {
      deviceId: IotDeviceIdT,
    },
  ) => {
    const userToken$Mobx = this
      .tokenRepo
      .userToken;
    if (userToken$Mobx.status !== 'success') {
      return userToken$Mobx;
    }
    const decodedToken = extractTokenData(
      userToken$Mobx.value,
    );
    const aa = expr(() => createAutoSyncedCaller3(
      this
        .apiClient
        .iot
        .getPhysicalEnvStats
        .query,
      {
        userId: decodedToken.userId,
        userToken: userToken$Mobx.value,
        laterThanThisDate: this.refreshTrigger.value.laterThanDatetime,
        iotDeviceId: args.deviceId,
      },
      this.refreshTrigger,
    ));
    return asSuccessPromise(aa);
  });

  iotDeviceSensordata = computedFn2((
    args: {
      deviceId: IotDeviceIdT,
    },
  ) => {
    const innerResult = this
      .iotDeviceSensordataInner(args);
    if (innerResult.status !== 'success') {
      return innerResult;
    }
    const result = innerResult
      .value
      .current()
      .value
      .value;
    if (result.status !== 'success') {
      return result;
    }
    return asSuccessPromise(
      itiriri(result.value),
    );
  });

  // TODO: ...
  triggerListRefresh(

  ) {
    const prevValue = this.refreshTrigger.value;
    const laterThanDatetime = new Date(
      prevValue.laterThanDatetime.getTime() + minInMillisec * 10,
    );
    this.refreshTrigger.next({
      laterThanDatetime,
    });
  }
}
