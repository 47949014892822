/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { css } from '@emotion/css';

import { ReactComponent as BellIcon } from '^/userWeb/Assets/Icons/bell.svg';
import { routes } from '^/userWeb/routes';
import { noMargin } from '^/userWeb/styles/commonClasses';
import {
  FontSizes, FontWeights, IconColors, TextColors,
} from '^/userWeb/styles/styleConstants';

export const TopWelcomeView: React.FC<{
  userName: string,
  numNotifications: number,
}> = function TopWelcomeView({
  userName,
  numNotifications,
}) {
  return (
    <div
      id="top-nav"
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 24px;
        padding-left: 20px;
        padding-right: 20px;
      `}
    >
      <div
        id="welcome-message"
        className={css`
          display: flex;
          flex-direction: column;
          color: ${TextColors.black};
          font-size: ${FontSizes.xxxl};
          font-weight: ${FontWeights.bold};
          user-select: none;
        `}
      >
        <p
          className={noMargin}
        >안녕하세요
        </p>
        <p
          className={noMargin}
        >{userName}님
        </p>
      </div>
      <div
        id="notification-icon"
        className={css`
          width: 44px;
          height: 44px;
          padding: 12px;
          margin-top: -8px;
          margin-right: -12px;
          position: relative;
        `}
        onClick={() => routes
          .notification({})
          .push()}
      >
        <BellIcon
          className={css`
            width: 20px;
            height: 20px;
            fill: ${IconColors.black};
            &:active{
              opacity: 50%;
            }
          `}
        />
      </div>
    </div>
  );
};
