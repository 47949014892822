import { useEffect, useRef, useState } from 'react';

import { css } from '@emotion/css';
import { useGesture } from '@use-gesture/react';
import {
  animate, m, useMotionValue,
} from 'framer-motion';
import { observer } from 'mobx-react-lite';

import { useInstance } from '^/userWeb/hooks/useInstance';
import { usePrevious } from '^/userWeb/hooks/usePrevious';
import { borders } from '^/userWeb/styles/commonClasses';
import { mobxValueFromMotionValue } from '^/userWeb/utils/mobxMotionValue';
import type { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

const scaleFactor = 2;

function dampen(val: number, [min, max]: [number, number]) {
  if (val > max) {
    const extra = val - max;
    const dampenedExtra = extra > 0 ? Math.sqrt(extra) : -Math.sqrt(-extra);
    return max + dampenedExtra * 0.1;
  } if (val < min) {
    const extra = val - min;
    const dampenedExtra = extra > 0 ? Math.sqrt(extra) : -Math.sqrt(-extra);
    return min + dampenedExtra * 0.1;
  }
  return val;
}

export const FullScreenSwiperPinchableItem: React.FC<{
  item: React.ReactNode;
  fixedItem?: React.ReactNode;
  onSwipeUp(): void;
  isSwiperDisabled: MobxInputValue<boolean>,
  disabled?: boolean,
  // orientationAngle: number,
  isLandscape: boolean,
  width: number, height: number,
  __DEBUG_MESSAGE?: string,
}> = observer(function FullScreenSwiperPinchableItem({
  item,
  fixedItem,
  onSwipeUp,
  isSwiperDisabled,
  disabled,
  // orientationAngle,
  isLandscape,
  width, height,
  __DEBUG_MESSAGE,
}) {
  const containerRef = useRef<HTMLDivElement>(
    null,
  );
  const targetRef = useRef<HTMLDivElement>(
    null,
  );
  const [numTouches, setNumTouches] = useState(0);

  const pinchStarts = useInstance(() => new MobxValue(0));
  const pinchMoves = useInstance(() => new MobxValue(0));
  const pinchEnds = useInstance(() => new MobxValue(0));

  const message = useInstance(() => new MobxValue(''));
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const xMobx = mobxValueFromMotionValue(x);
  const yMobx = mobxValueFromMotionValue(y);
  const prevWidth = usePrevious(width);
  const prevHeight = usePrevious(height);
  // const isLandscape = (70 < orientationAngle && orientationAngle < 120)
  //   || (250 < orientationAngle && orientationAngle < 290);
  const prevIsLandscape = usePrevious(isLandscape);
  const scale = useMotionValue(1);
  const scaleMobx = mobxValueFromMotionValue(scale);
  useEffect(() => {
    if (prevIsLandscape && !isLandscape) {
      // onSwipeUp(); // <-- maybe?
    }
    if (!prevWidth || !prevHeight) {
      maybeAdjustImage();
      return;
    }
    const oldX = x.get();
    const oldY = y.get();

    setTimeout(() => {
      x.set((oldY / prevHeight) * width);
      y.set((oldX / prevWidth) * height);

      maybeAdjustImage();
    }, 100);

    maybeAdjustImage();
  }, [isLandscape]);
  useGesture({
    // onBlur(state) {
    //   state.event.
    // },
    onPinchStart: ({
      event,
    }) => {
      // event.preventDefault();
      isSwiperDisabled.set(true);
    },
    onPinch: ({
      pinching,
      event,
      memo,
      origin: [pinchOriginX, pinchOriginY],
      offset: [offsetD],
      touches,
    }) => {
      event.preventDefault();
      setNumTouches(touches);

      // event.stopPropagation();
      x.stop();
      y.stop();
      if (!targetRef.current) return null;
      // eslint-disable-next-line no-param-reassign
      memo ??= {
        bounds: targetRef.current.getBoundingClientRect(),
        crop: { x: x.get(), y: y.get(), scale: scale.get() },
      };
      console.log('offsetD: ', offsetD);

      const transformOriginX = memo.bounds.x + memo.bounds.width / 2;
      const transformOriginY = memo.bounds.y + memo.bounds.height / 2;

      const displacementX = (transformOriginX - pinchOriginX) / memo.crop.scale;
      const displacementY = (transformOriginY - pinchOriginY) / memo.crop.scale;

      const initialOffsetDistance = (memo.crop.scale - 1) * scaleFactor;
      const movementDistance = offsetD - initialOffsetDistance;

      scale.set(offsetD);
      // scale.set((1 + offsetD / scaleFactor));
      x.set(memo.crop.x + (displacementX * movementDistance) / scaleFactor);
      y.set(memo.crop.y + (displacementY * movementDistance) / scaleFactor);
      // isSwiperDisabled.set(true);
      return memo;
    },
    onDrag: (state) => {
      if (isLandscape
        && state.swipe[1] === -1
      ) {
        // alert('isLandscape / state.swipe[1] === 1!');
        onSwipeUp();
      }
      if (state.swipe[0] === 1) {
        // onSwipeUp();
      } else if (state.swipe[0] === -1) {
        // onSwipeUp();
      }
      // if (state.touches < 2 && !state.ctrlKey) {
      //   return;
      // }
      if (!targetRef.current
        || !containerRef.current
        || disabled
      ) {
        return;
      }
      x.stop();
      y.stop();
      if (state.tap) {
        if (scale.get() < 1.02) {
          isSwiperDisabled.set(false);
          return;
        }
        isSwiperDisabled.set(!isSwiperDisabled.value);
        return;
      }
      if (isSwiperDisabled.value === false) {
        return;
      }
      state.event.stopPropagation();
      const [dx, dy] = state.offset;
      // console.log(`(dx,dy) = ${dx},${dy}`);
      const imageBounds = targetRef.current.getBoundingClientRect();
      const containerBounds = containerRef.current.getBoundingClientRect();
      const originalWidth = targetRef.current.clientWidth;
      const widthOverhang = (imageBounds.width - originalWidth) / 2;
      const originalHeight = targetRef.current.clientHeight;
      const heightOverhang = (imageBounds.height - originalHeight) / 2;
      const maxX = widthOverhang;
      const minX = -(imageBounds.width - containerBounds.width) - widthOverhang;
      const maxY = heightOverhang;
      const minY = -(imageBounds.height - containerBounds.height) - heightOverhang;
      // x.current.mValue.set(state.movement[1]);
      // y.current.mValue.set(-state.movement[0]);

      // x.current.mValue.set(dampen(dy, [minY, maxY]));
      // y.current.mValue.set(-dampen(dx, [minX, maxX]));
      // x.set(dx);
      // y.set(dy);
      console.error('minY: ', minY, '/ maxY: ', maxY);
      x.set(dampen(dx, [minX, maxX]));
      y.set(dampen(dy, [minY, maxY]));
    },
    onDragEnd: maybeAdjustImage,
    onPinchEnd: maybeAdjustImage,
  }, {
    // enabled: false,
    eventOptions: {

      passive: false, // prevent pinch-actions leaking to browser-viewport

    },

    pinch: {
      scaleBounds: {
        min: 1, max: 5,
      },
      // rubberband: true,
      angleBounds: {
        min: 0, max: 0,
      },
      pointer: {
        touch: true,
      },
    },
    drag: {
      from: () => [x.get(), y.get()],
      filterTaps: true,
      pointer: {
        touch: true,
      },
    },
    target: containerRef,

  });

  function maybeAdjustImage() {
    // isSwiperDisabled.set(false);
    const newCrop = { x: x.get(), y: y.get(), scale: scale.get() };
    if (!targetRef.current || !containerRef.current) return;
    const imageBounds = targetRef.current.getBoundingClientRect();
    const containerBounds = containerRef.current.getBoundingClientRect();
    const originalWidth = targetRef.current.clientWidth;
    const widthOverhang = (imageBounds.width - originalWidth) / 2;
    const originalHeight = targetRef.current.clientHeight;
    const heightOverhang = (imageBounds.height - originalHeight) / 2;

    if (imageBounds.left > containerBounds.left) {
      isSwiperDisabled.set(false);
      newCrop.x = widthOverhang;
    } else if (imageBounds.right < containerBounds.right) {
      isSwiperDisabled.set(false);
      newCrop.x = -(imageBounds.width - containerBounds.width) + widthOverhang;
    }

    if (imageBounds.top > containerBounds.top) {
      isSwiperDisabled.set(false);
      newCrop.y = heightOverhang;
    } else if (imageBounds.bottom < containerBounds.bottom) {
      isSwiperDisabled.set(false);
      newCrop.y = -(imageBounds.height - containerBounds.height) + heightOverhang;
    }

    animate(x, newCrop.x, {
      type: 'tween',
      duration: 0.4,
      ease: [0.25, 1, 0.5, 1],
    });
    animate(y, newCrop.y, {
      type: 'tween',
      duration: 0.4,
      ease: [0.25, 1, 0.5, 1],
    });
  }
  return (
    <div
      className={[
        // borders.DEBUG_purpleBorderDashed,
        // borders.DEBUG_blueBorderThickDashed,
        css`
          position: relative;
          pointer-events: all;
          overflow: hidden;
        `,
      ].join(' ')}
      style={{
        width,
        height,
      }}
      onDoubleClick={() => onSwipeUp()}
    >
      {fixedItem}
      <div
        ref={containerRef}
        className={[

          css`
            /* pointer-events: all; */
            width: 100%; height: 100%;
          `,
          // borders.DEBUG_blueBorderDashed,
          // borders.DEBUG_blueBorderThickDashed,
        ].join(' ')}
        style={{
          touchAction: 'none', // pinch
        }}
      >
        <m.div
          ref={targetRef}
          className={[
            // borders.DEBUG_orangeBorderDashed,
            css`
              width: 100%; height: 100%;
              /* background-color: yellow; */
              overflow: hidden;
            `,
          ].join(' ')}
          id="targetDOM"
          style={{
            x,
            y,
            scale,
          }}
        >
          <div
            id="targetInnerDOM"
            className={[
              // borders.DEBUG_blueBorderThickDashed,
              css`
                /* background-color: yellow; */

              `,
            ].join(' ')}
            style={{
              transform: !isLandscape
                ? `translateY(calc(${height * 0.5}px - 50%)) translateX(calc(${width * 0.5}px - 50%)) rotate(90deg)`
                : undefined,
              width: !isLandscape ? height : width,
              height: !isLandscape ? width : height,
            }}
          >
            {item}
          </div>

        </m.div>

      </div>
      {/* <p
        className={css`
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: white;
            opacity: 0.3;
            font-weight: bolder;
          `}
      >
        x: {xMobx.current()?.value.value} <br />
        y: {yMobx.current()?.value.value} <br />
        scale: {scaleMobx.current()?.value.value} <br />
        isSwiperDisabled: {JSON.stringify(isSwiperDisabled.value)} <br />
        disabled: {JSON.stringify(disabled)} <br />
        isLandscape: {JSON.stringify(isLandscape)} <br />
        numTouches: {numTouches} <br />
        width: {width} / height: {height} <br />
        __DEBUG_MESSAGE: {__DEBUG_MESSAGE} <br />
      </p> */}
    </div>
  );
});
