import { css } from '@emotion/css';

import {
  BackgroundColors, FontSizes, LineColors, TextColors,
} from '../../styles/styleConstants';

export const BaseTextFieldStyle = css`
  background-color: ${BackgroundColors.white};
  color: ${TextColors.black};
  font-size: ${FontSizes.base};
  border-radius: 10px;
  border: 1px ${LineColors.gray_light} solid;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 14px;
  gap: 12px;
  align-items: center;

  ::placeholder {
    color: ${TextColors.black_lightest};
  }

  :focus {
    border: 1px ${LineColors.black};
    box-shadow: none;
  }
`;
