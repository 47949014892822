import { forwardRef } from 'react';

import { css, cx } from '@emotion/css';

import { HTMLButtonProps } from '^/userWeb/types/HTMLButtonProps';
import { SVGReactComponent } from '^/userWeb/types/SVGReactComponent';

import { BaseButtonTextStyle } from '../Buttons/BaseButtonTextStyle';

import { BaseChipStyle } from './BaseChipStyle';

type PropsType = HTMLButtonProps & {
  title: string;
  // eslint-disable-next-line react/no-unused-prop-types
  Icon: SVGReactComponent;
  iconColor: string;
  rootCSS?: string;
  textCSS?: string;
};
export const IconChip = forwardRef<HTMLButtonElement, PropsType>(({
  title,
  Icon,
  iconColor,
  rootCSS,
  textCSS,
  ...buttonProps
}, ref,
) => {
  return (
    <button
      ref={ref}
      type="button"
      title={title}
      className={cx(
        BaseChipStyle,
        css`
          gap: 8px;
          user-select: none;
        `,
        rootCSS,
      )}
      {...buttonProps}
    >
      <Icon
        className={css`
          width: 20px;
          height: 20px;
        `}
        style={{
          fill: iconColor,
        }}
      />
      <p
        className={cx(
          BaseButtonTextStyle,
          textCSS,
        )}
      >
        {title}
      </p>
    </button>
  );
});
