import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { AccentColors, BackgroundColors } from '^/userWeb/styles/styleConstants';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

export const Switch: React.FC<{
  isOn: MobxValue<boolean>;
  rootCSS?: string;
}> = observer(function Switch(props) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cx(
        css`
          display: flex;
          flex-basis: 51px;
          padding: 2px;
          background-color: ${props.isOn.value ? AccentColors.green : BackgroundColors.gray_light};
          width: 51px;
          height: 31px;
          border-radius: 25.5px;
          `,
        props.rootCSS,
      )}
      onClick={() => props.isOn.setValue(!props.isOn.value)}
    >
      <div
        className={css`
          aspect-ratio: 1 / 1;
          width: 27px;
          background-color: ${BackgroundColors.white};
          border-radius: 50%;
          margin-right: ${props.isOn.value ? '0px' : 'auto'};
          margin-left: ${props.isOn.value ? 'auto' : '0px'};
        `}
      />
    </div>
  );
});
