import { css, cx } from '@emotion/css';

import { Divider } from './Divider';

export const VerticalDivider = cx(
  Divider,
  css`
    height: 1px;
    width: 100%;
  `,
);
