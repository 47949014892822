/* eslint-disable no-nested-ternary */

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

import { ViewByMobxResultOfRxjsResult } from '^/userWeb/Components/ViewByResultObservable';
import { portalDOM } from '^/userWeb/Portal';

import { AdminIotDevicePageAddPopup } from './AdminIotDevicePage.addPopup';
import { AdminIotDevicePageEditPopup } from './AdminIotDevicePage.editPopup';
import { AdminIotDeviceListItem } from './AdminIotDevicePage.listItem';
import type { AdminIotDevicePageViewModel } from './AdminIotDevicePage.viewmodel';
import { ViewByState } from '^/userWeb/Components/ViewByState';

export const AdminIotDevicePage = observer(function AdminIotDevicePage(
  p: {
    viewmodel: AdminIotDevicePageViewModel,
  },
) {
  return (
    <div
      className={[
        css`
          height: 100%;
        `,
        // borders.DEBUG_blueBorderThickDashed,
      ].join(' ')}
    >
      <h2> IOT 기기 어드민 페이지 </h2>

      <div
        className={[
          css`
            height: calc(100% - 100px);
          `,
          // borders.DEBUG_greenBorderDashed,
          // borders.DEBUG_blueBorderThickDashed,
        ].join(' ')}
      >

        <ViewByState
          data={p.viewmodel.iotDevicesList}
          loadingView={<p> loading </p>}
          errorView={(err) => {
            console.error(err);
            return (
              <p> error </p>
            );
          }}
        >
          {(iotDeviceList) => {
            if (iotDeviceList.length === 0) {
              return (
                <div
                  className={[
                    css`
                      display: flex;
                      height: 100%;
                      flex-direction: column;
                      justify-content: center;
                      gap: 52px;
                      align-items: center;
                    `,
                    // borders.DEBUG_greenBorderDashed,
                  ].join(' ')}
                >
                  <h2>연결된 iot 기기가 없습니다.</h2>
                  <button
                    type="button"
                    onClick={() => {
                      p.viewmodel
                        .visibleForm
                        .set({
                          type: 'add',
                        });
                    }}
                  >추가하기
                  </button>
                </div>
              );
            }
            return (
              <>
                {iotDeviceList.map((device) => {
                  return (
                    <AdminIotDeviceListItem
                      device={device}
                      onEdit={(d) => {
                        p.viewmodel.visibleForm.set({
                          type: 'edit',
                          deviceId: d.id,
                        });
                      }}
                      onDelete={(d) => {
                        const aaa = `${d.accessHost}:${d.accessPort}`;
                        // eslint-disable-next-line no-restricted-globals, no-alert
                        const confirmDel = confirm(`${aaa} iot 기기를 지우시겠습니까?`);
                        if (!confirmDel) return;
                        p.viewmodel.appContext
                          .iotDevicesListCore
                          .deleteDevice(d.id);
                      }}
                    />
                  );
                })}
              </>
            );
          }}
        </ViewByState>
      </div>
      <button
        type="button"
        onClick={() => p
          .viewmodel
          .visibleForm
          .set({
            type: 'add',
          })}
      >
        추가하기
      </button>
      <hr />

      {p.viewmodel.visibleForm.value?.type === 'add' ? (
        createPortal(
          <AdminIotDevicePageAddPopup
            viewmodel={p.viewmodel}
          />,
          portalDOM,
        )

      ) : p.viewmodel.visibleForm.value?.type === 'edit' ? (
        createPortal(
          <AdminIotDevicePageEditPopup
            viewmodel={p.viewmodel}
            editDeviceId={p.viewmodel.visibleForm.value.deviceId}
          />,
          portalDOM,
        )
      ) : null}
    </div>
  );
});
