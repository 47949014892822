const portalDOM = document.getElementById('PORTAL') as HTMLDivElement;

if (!(portalDOM instanceof HTMLDivElement)) {
  throw new Error(
    `portalDOM is not div, but: ${portalDOM}`,
  );
}

export {
  portalDOM,
};
