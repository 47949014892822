/** @jsx jsx */
import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { ReactComponent as BackIcon } from '^/userWeb/Assets/Icons/arrow-left.svg';
import { DescriptiveSwitch } from '^/userWeb/Components/DescriptiveSwitch';
import { NavigationBar } from '^/userWeb/Components/NavigationBar/NavigationBar';
import { FontSizes, FontWeights, TextColors } from '^/userWeb/styles/styleConstants';

import { LoadedMySettingsPageViewModel } from './LoadedMySettingsPage.viewmodel';

export const LoadedMySettingsPage: React.FC<{
  viewmodel: LoadedMySettingsPageViewModel;
}> = observer(function MySettingsPage({
  viewmodel,
}) {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <NavigationBar
        LeftIcon={BackIcon}
        rootCSS={
          css`
            position: absolute;
            top: 0px;
            width: 100%;
          `
        }
      />

      <p
        className={css`
          color: ${TextColors.black};
          font-size: ${FontSizes.xxxl};
          font-weight: ${FontWeights.bold};
          margin-top: 80px;
          margin-left: 20px;
          margin-right: auto;
          margin-bottom: 0px;
        `}
      >알림설정
      </p>
      <p
        className={css`
          color: ${TextColors.black_light};
          font-size: ${FontSizes.lg};
          line-height: 150%;
          margin-top: 20px;
          margin-left: 20px;
          margin-right: auto;
          margin-bottom: 0px;
          word-break: keep-all;
        `}
      >
        알림을 설정하셔서 항상 축사에 대한
        <br />
        알림을 확인하세요.
      </p>
      <DescriptiveSwitch
        title="승가 알림설정"
        description="승가가 관찰 되었을 때 알림을 보내드립니다."
        isOn={viewmodel.isCowAlarmOn}
        rootCSS={css`
          margin-top: 40px;
          margin-left: 20px;
          margin-right: 20px;
        `}
      />
      <DescriptiveSwitch
        title="방문자 알림설정"
        description="방문자가 관찰 되었을 때 알림을 보내드립니다."
        isOn={viewmodel.isVisitorAlarmOn}
        rootCSS={
          css`
            margin-top: 20px;
            margin-left: 20px;
            margin-right: 20px;
          `
        }
      />
    </div>
  );
});
