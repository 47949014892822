import { makeAutoObservable } from 'mobx';

import { asFailedPromise } from '^/types/__ResultType';
import type { UserType } from '^/types/userTypes';
import type { AppContext } from '^/userWeb/types/AppContext';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';
import { MutationNotInitiatedAtAll, syncedMutationFunc } from '^/utils/mobx-utils/syncedMutationProcess';

import { BaseIotDeviceFormViewModel } from './BaseIotDeviceForm.viewmodel';

export class AddIotDeviceFormViewModel {
  constructor(
    public appContext: AppContext,
    public user$ = new MobxInputValue<UserType | null>(
      null,
      () => this.submit.reset(),
    ),
    public baseFormVM = new BaseIotDeviceFormViewModel(
      () => this
        .submit
        .reset(),
    ),
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }

  submit = syncedMutationFunc(
    async (_) => {
      const token$M = this.appContext
        .tokenRepo
        .userTokenWithData;
      if (token$M.status !== 'success') {
        return token$M;
      }
      const user = this.baseFormVM.user$.value;
      if (!user) {
        return asFailedPromise({
          reason: 'USER_NOT_SELECTED_YET',
        } as const);
      }
      const m = await this.appContext
        .apiClient
        .iot
        .addIotDevice
        .mutate({
          userToken: token$M.value.token,
          device: {
            name: this.baseFormVM.name$.value,
            accessHost: this.baseFormVM.accessHost$.value,
            accessPort: this.baseFormVM.accessPort$.value,
            phone: user.phone,
            deviceVersion: 'V1_2024_03_30',
            krGovfarmRegNo: this.baseFormVM.govFarmId$.value,
          },
        });
      return m;
    },
  );
}
