import React from 'react';

import { observer } from 'mobx-react-lite';

import {
  ExtractFailures, ExtractLoading, ExtractPromiseSuccess, PromiseResultType,
} from '^/types/__ResultType';

export type PropsType<T extends PromiseResultType<any, any, any>> = {
  data: T;
  errorView: React.ReactElement
  | ((err: ExtractFailures<T>) => React.ReactElement);
  loadingView: React.ReactElement
  | ((loading: ExtractLoading<T> | undefined) => React.ReactElement),
  children(value: ExtractPromiseSuccess<T>): React.ReactElement;
};

export const ViewByState = observer(
  function ViewByState<T extends PromiseResultType<any, any, any>>({
    data,
    loadingView,
    errorView,
    children,
  }: PropsType<T>,
  ): React.ReactElement {
    console.log("data.status must be 'loading' or 'failure' or 'success', but?",data.status);
    if (data.status === 'loading') {
      if (typeof (loadingView) === 'function') {
        return loadingView(data.value);
      }
      return loadingView;
    }
    if (data.status === 'failure') {
      if (typeof (errorView) === 'function') {
        return errorView(data.failure);
      }
      return errorView;
    }
    return children(data.value);
  });
