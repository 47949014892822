import { action, makeAutoObservable } from 'mobx';

export class MobxInputValue<T> {
  constructor(
    public value: T,
    public onChange?: (newValue: T, oldValue: T) => void,
    public hasBlurred = false,
    public hasEdited = false,
  ) {
    makeAutoObservable(this, {
      set: action.bound,
    });
  }

  set(value: T) {
    this.hasEdited = true;
    if (this.onChange) {
      this.onChange(value, this.value);
    }
    this.value = value;
  }

  setHasBlurred(hasBlurred: boolean) {
    this.hasBlurred = hasBlurred;
  }
}
