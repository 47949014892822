import { useRef, useState, type MutableRefObject } from 'react';

export function useInstance<T>(factoryFn: () => T) {
  const [isCreated, setIsCreated] = useState(false);
  const value = useRef<T | null>(null);
  if (isCreated !== true) {
    value.current = factoryFn();
    setIsCreated(true);
  }
  return value as MutableRefObject<T>;
}
