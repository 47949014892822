import { makeAutoObservable } from 'mobx';

import { LoadingPromise, NotInitiatedPromiseStale, SuccessPromise } from '^/types/__ResultType';
import {
  CowMountHistory,
  CowMountHistoryIdType,
} from '^/types/CowMountHistoryType';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';

import { GetCowMountHistoryListFailResult } from '../apiTypes/getCowMountHistoryInfo';

export type CowMountHistoryResultType =
  | GetCowMountHistoryListFailResult
  | LoadingPromise
  | SuccessPromise<CowMountHistory>;
export class CowMountHistoryRepo {
  constructor(
    public cowMountHistory: Record<CowMountHistoryIdType, CowMountHistoryResultType> = {},
  ) {
    makeAutoObservable(this);
  }

  getCowMountHistoryById = computedFn2((
    id: CowMountHistoryIdType,
  ) => {
    return this.cowMountHistory[id] || NotInitiatedPromiseStale;
  });

  setCowMountHistoryById(
    id: CowMountHistoryIdType,
    cowMountHistory: CowMountHistoryResultType,
  ) {
    this.cowMountHistory[id] = cowMountHistory;
  }
}
