import { makeAutoObservable } from 'mobx';

import { UserType } from '^/types/userTypes';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';

export class BaseIotDeviceFormViewModel {
  constructor(
    public onChange: () => void,
    public _accessHost = '',
    public _accessPort = 12345,
    public _name = '',
    public _govFarmId = '',
    public name$ = new MobxInputValue<string>(_name, onChange),
    public user$ = new MobxInputValue<UserType | null>(
      null,
      onChange,
    ),
    public govFarmId$ = new MobxInputValue(_govFarmId, onChange),
    public accessHost$ = new MobxInputValue(
      _accessHost,
      onChange,
    ),
    public accessPort$ = new MobxInputValue(
      _accessPort,
      onChange,
    ),

  ) {
    makeAutoObservable(this);
  }
}
