import { useRef } from 'react';

import { css } from '@emotion/css';
import { useWindowSize } from '@uidotdev/usehooks';
import { useGesture, useScroll } from '@use-gesture/react';
import { formatISO9075 } from 'date-fns';
import { LayoutGroup, m, useMotionValue } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import toast from 'react-hot-toast';

import { ViewByMobxResultOfRxjsResult } from '^/userWeb/Components/ViewByResultObservable';
import { ViewByState } from '^/userWeb/Components/ViewByState';
import { borders } from '^/userWeb/styles/commonClasses';
import { mobxValueFromMotionValue } from '^/userWeb/utils/mobxMotionValue';

import { RelayControl } from './RelayControl';
import { SensorsDetailPopupViewModel } from './SensorsDetail.popup.viewmodel';
import { TempHumidityChart } from './TempHumidityChart';

export const defaultStyles = {
  valueText: css`
    margin-right: 8px; 
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  `,
};
export const SensorsDetailPopup = observer(function SensorsDetailPopup(p: {
  viewmodel: SensorsDetailPopupViewModel,
}) {
  // 0: initial view
  // 1: 100% of: show more data-view
  const moreDataViewRatio = useMotionValue(0);
  // const a = useScroll((s) => {
  //   s.movement
  // }, {
  //   target: 
  // })

  const winSize = useWindowSize();
  const winWidth = winSize.width || 0;
  const winHeight = winSize.height || 0;
  const moreDataViewRatioDragRef = useRef<HTMLDivElement>(null);
  const isWideView = winWidth > winHeight + 100;

  // const activeView = p.viewmodel.activeView.value;

  // eslint-disable-next-line no-nested-ternary
  return (
    <LayoutGroup>
      <div
        ref={moreDataViewRatioDragRef}
        className={[
          css`
            /* background-color: green; */
            display: flex;
            
            padding-left: 16px;
            padding-right: 16px;
            gap: 8px;
            width: 100%;
            height: 100%;

          `,
          // borders.DEBUG_blueBorderThickDashed,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
        style={isWideView ? {
          // flex-direction: row;
          flexDirection: 'row',
          // height:
          // width: '100%',
          // overflowX: 'scroll',
        } : {
          flexDirection: 'column',
        }}
      >
        <div
          className={[
            css`
              position: absolute;
              right: 10px;
              top: -50px;
              pointer-events: none;
              display: none;
            `,
            borders.DEBUG_greenBorderDashed,
          ].join(' ')}
        >
          <h1>w: {winWidth} / h: {winHeight}</h1>
          {/* <h2>active: {activeView.type}</h2> */}
          {/* <h1>{moreDataViewRatioMobx.current()?.value.value}</h1> */}
        </div>
        <div>

          <p
            className={[
              css`
                width: fit-content;
                font-weight: bold;
                position: absolute;
                top: -36px;
                left: 12px;
                line-height: 0.65;
              `,
              // borders.DEBUG_blueBorderDashed,
            ].join(' ')}
          >
            환경제어기: {p.viewmodel.device.name} <br />
            <span
              className={[
                css`
                  font-size: 10px;
                  white-space: pre-line;
                  color: blue;
                `,
              ].join(' ')}
            >
              {p.viewmodel.device.accessHost}:{p.viewmodel.device.accessPort}&#10;
              {/* version: {p.viewmodel.device.deviceVersion}&#10;
              phone: {p.viewmodel.device.phone} */}
            </span>
          </p>

        </div>
        <m.div
          layout
          id="RelayControl_Wrapper"
          className={[
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
          onMouseEnter={() => {
            p.viewmodel.activeView.set({
              type: 'RelayControl',
            });
          }}
          style={
            {
              width: isWideView ? 240 : '100%',
              // width: isWideView ? winWidth * 0.2 : '100%',
              height: isWideView ? '100%' : winHeight * 0.2,
            }
          }
        >
          <RelayControl
            viewmodel={p.viewmodel}
          />
        </m.div>
        <ViewByState
          data={p.viewmodel.sensorData}
          errorView={<p> error </p>}
          loadingView={<p> loading </p>}
        >
          {(value) => {
            // eslint-disable-next-line no-nested-ternary

            return (
              <>
                <m.div
                  layout
                  layoutId="11a33"
                  onMouseDown={() => {
                    p.viewmodel.activeView.set({
                      type: 'SensorDataGraph',
                    });
                  }}
                  className={[
                    css`
                      /* flex-basis: 300px; */
                    `,
                    borders.DEBUG_greenBorderDashed,
                  ].join(' ')}
                  // style={isWideView ? {
                  //   height: winHeight - 80,
                  // } : undefined}
                  // style={{
                  //   height: isWideView
                  //     ? '100%'
                  //     : winWidth - 40,
                  //   // height: winHeight - 80,
                  //   width: isWideView
                  //     ? winHeight
                  //     : winWidth,
                  // }}
                  // style={{
                  //   // eslint-disable-next-line no-nested-ternary
                  //   width: activeView.type === 'SensorDataGraph'
                  //     ? isWideView ? undefined : '100%'
                  //     : isWideView ? winWidth * 0.2 : '100%',
                  //   height: graphHeight,
                  //   flexGrow: (isWideView
                  //     && activeView.type === 'SensorDataGraph')
                  //     ? 1 : undefined,
                  // }}
                  // eslint-disable-next-line no-nested-ternary
                  style={isWideView
                      ? {
                        width: winWidth * 0.7,
                        height: '100%',

                      } : {
                        width: '100%',
                        height: winHeight * 0.2,
                      }}
                >

                  <TempHumidityChart
                    viewmodel={p.viewmodel}
                    data={value}
                  // dimension={{
                  //   // width: graphWidth,
                  //   // height: graphHeight,
                  // }}
                  />
                </m.div>
                <m.div
                  onMouseDown={() => {
                    p.viewmodel.activeView.set({
                      type: 'SensorDataTable',
                    });
                  }}
                  layout
                  layoutId="SensorDataTable"
                  className={[
                    css`
                      /* flex: 1; */

                      /* overflow-x: hidden; */
                      /* display: flex;
                      flex-direction: column; */
                      display: grid;
                      grid-template-columns: 1fr;
                      grid-template-rows: 1fr;
                      /* overflow-y: scroll; */
                      /* height: calc(100% - 220px); */
                      border-radius: 16px;
                      /* position: absolute;
                      bottom: 10px;
                      left: 10px;
                      right: 10px; */
                    `,
                    borders.DEBUG_blueBorderDashed,
                  ].join(' ')}
                  role="list"
                  style={{
                    // eslint-disable-next-line no-nested-ternary
                    height: isWideView
                      ? '100%' : 'calc(100% - 50px)',
                    // eslint-disable-next-line no-nested-ternary
                    width: isWideView ? 400 : '100%',
                    // height: p.viewmodel.expandDataArea.value === true
                    //   ? 'clamp(200px, 100% - 200px, 80vh)'
                    //   : 200,

                  }}
                >

                  <m.div
                    layout="position"
                    className={[
                      css`
                        /* height: 300px; */
                        /* height: calc(100% - 900px); */
                        height: 100%;
                        /* background-color: green; */
                        overflow-y: scroll;
                        flex: 1;
                        display: grid;
                        /* width: 100px; */
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr;
                        overflow-y: scroll;
                      `,
                      // borders.DEBUG_greenBorderDashed,
                      // borders.DEBUG_blueBorderThickDashed,
                    ].join(' ')}
                  >
                    {value.map((v) => {
                      return (
                        <div
                          key={v.id}
                          className={css`
                            border-bottom: 1px dotted black;
                            margin-left: 8px;
                            margin-right: 8px;
                            margin-top: auto;
                            padding-top: 8px;
                            padding-bottom: 8px;
                          `}
                        >
                          <p
                            className={defaultStyles.valueText}
                          >
                            {formatISO9075(v.assumedTime)}
                          </p>
                          <p
                            className={[
                              css`
                                display: inline-block;
                                margin: 0;
                              `,
                            ].join(' ')}
                          >
                            온도:
                            <span
                              className={defaultStyles.valueText}
                            >
                              {v.temperature.toFixed(1)}°C
                            </span>
                            습도:
                            <span
                              className={defaultStyles.valueText}
                            >
                              {v.humidity.toFixed(1)}%
                            </span>
                          </p>
                        </div>
                      );
                    })}
                  </m.div>
                </m.div>
              </>
            );
          }}
        </ViewByState>

      </div>
    </LayoutGroup>
  );
});
