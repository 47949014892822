import { makeObservable } from 'mobx';

import {
  asSuccessPromise,
  getCurrentFreshness,
} from '^/types/__ResultType';
import { BaseViewModel } from '^/userWeb/BaseViewModel';
import { routes } from '^/userWeb/routes';
import { AppContext } from '^/userWeb/types/AppContext';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

export class DetectedVisitorsPageViewModel extends BaseViewModel {
  constructor(
    public appContext: AppContext,
    public startDate = new MobxValue<Date | null>(null),
    public endDate = new MobxValue<Date | null>(new Date()),
    public fullScreenNth: number | null = null,
    public fullScreenNth$M = new MobxInputValue(
      fullScreenNth,
      (
        newValue,
        prevValue,
      ) => {
        if (prevValue === null) {
          // push route
          routes.visitorHistory({
            fullscreenNth: newValue ?? undefined,
            freshness: this.freshness,
          }).push({
            skipRender: true,
          });
        } else {
          routes.visitorHistory({
            fullscreenNth: newValue ?? undefined,
            freshness: this.freshness,
          }).replace({
            skipRender: true,
          });
        }
      },
    ),
    public freshness = getCurrentFreshness(),
  ) {
    super(appContext, freshness);
    makeObservable(this, { list: true, filteredList: true });
  }

  get list() {
    if (this.myInfo.status !== 'success') {
      return this.myInfo;
    }
    const result = this.appContext
      .detectedVisitorsListCore
      .getList({
        __CarPersonDetectedListFilter: null,
        userId: this.myInfo.value[0].id,
        userToken: this.myInfo.value[1],
      }, this.freshness);
    if (result.status !== 'success') {
      return result;
    }
    return result; // TODO: ...
  }

  get filteredList() {
    if (this.list.status !== 'success') {
      return this.list;
    }
    return asSuccessPromise(this.list.value
      .filter((r) => {
        if (r.status !== 'success') {
          return true;
        }
        const createdAtTimestamp = (new Date(r.value.createdAt)).getTime(); // 8월 9일
        const startDateTimestamp = this.startDate.value?.getTime(); // 10월 10일
        if (startDateTimestamp && startDateTimestamp > createdAtTimestamp) {
          return false;
        }
        const endDateTimestamp = this.endDate.value?.getTime();
        if (endDateTimestamp && endDateTimestamp < createdAtTimestamp) {
          return false;
        }
        return true;
      }),
      this.freshness);
  }
}
