import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import type { IotDeviceT } from '^/__generated__/pothosZod/generated_models';
import type { IotDeviceIdT } from '^/__generated__/pothosZod/generated_scalars';
import { borders } from '^/userWeb/styles/commonClasses';

export const AdminIotDeviceListItem = observer(function AdminIotDeviceListItem(p: {
  device: IotDeviceT,
  onEdit(device: IotDeviceT): void;
  onDelete(device: IotDeviceT): void;
}) {
  return (
    <div
      className={[
        css`
          padding-top: 4px;
          padding-bottom: 4px;
          margin-left: 12px;
          margin-right: 12px;
          display: flex;
          gap: 12px;
          flex-wrap: wrap;
          border-bottom: 1px solid grey;
        `,
        // borders.DEBUG_blueBorderDashed,
      ].join(' ')}
    >
      <span
        className={[
          css`
            width: 180px;
          `,
        ].join(' ')}
      >호스트 {p.device.accessHost} &#9;
      </span>
      <span
        className={[
          css`
            width: 100px;
          `,
        ].join(' ')}
      >포트 {p.device.accessPort}
      </span>
      <span>{p.device.deviceVersion}</span>
      <span>{p.device.phone}</span>

      <button
        type="button"
        onClick={() => {
          p.onDelete(p.device);
        }}
      >
        지우기
      </button>
      <button
        type="button"
        onClick={() => {
          p.onEdit(p.device);
        }}
      >
        수정하기
      </button>
    </div>
  );
});
