import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { ReactComponent as ArrowLeftIcon } from '^/userWeb/Assets/Icons/arrow-left.svg';
import { ReactComponent as SettingIcon } from '^/userWeb/Assets/Icons/setting.svg';
import { VerticalDivider } from '^/userWeb/Components/Dividers/VerticalDivider';
import { NavigationBar } from '^/userWeb/Components/NavigationBar/NavigationBar';
import { ViewByState } from '^/userWeb/Components/ViewByState';
import { routes } from '^/userWeb/routes';
import {
  borders,
  fillParentElem, hideScrollBar, whiteBackground,
} from '^/userWeb/styles/commonClasses';
import { BackgroundColors } from '^/userWeb/styles/styleConstants';

import { DetectedVisitorsPageLoaded } from './DetectedVisitorsPage.Loaded';
import { DetectedVisitorsPageTopFilter } from './DetectedVisitorsPage.topFilter';
import { DetectedVisitorsPageViewModel } from './DetectedVisitorsPage.viewmodel';

export const DetectedVisitorsPage: React.FC<{
  title: string;
  viewmodel: DetectedVisitorsPageViewModel,
}> = observer(function DetectedVisitorsPage({
  title,
  viewmodel,
}) {
  return (
    <div
      className={cx(
        fillParentElem, whiteBackground,
      )}
    >
      <NavigationBar
        LeftIcon={ArrowLeftIcon}
        title={title}
        RightIcon={SettingIcon}
        RightIconProps={{
          onClick() {
            routes.notificationSetting({}).push();
          },
        }}
        rootCSS={css`
          height: 60px;
        `}
      />
      <div
        className={css`
          display: flex;
          flex-direction: column;
          background-color: ${BackgroundColors.white};
          width: 100%;
          height: 65px;
        `}
      >
        <div
          className={cx(
            css`
              display: flex;
              flex-direction: row;
              gap: 8px;
              height: 100%;
              overflow-x: scroll;
              padding-left: 20px;
              padding-right: 20px;
              align-items: center;
            `,
            hideScrollBar,
          )}
        >
          <DetectedVisitorsPageTopFilter
            viewmodel={viewmodel}
          />
        </div>
        <div
          className={cx(
            VerticalDivider,
            css`
              position: relative;
              left: 0px;
              right: 0px;
              bottom: 0px;
            `,
          )}
        />
        <div
          className={[
            css`
              /* --grid-column-gap: 20px;
              --grip-row-gap: 8px; */
              --grid-row-gap: 20px;
              --grid-column-gap: 8px;
              --grid-column-count: 5;
              --grid-item--min-width: 144px;

              --gap-count: calc(var(--grid-column-count) - 1);
              --total-gap-width: calc(var(--gap-count) * var(--grid-row-gap));
              --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

              position: absolute;
              display: grid;
              padding: 12px;
              row-gap: var(--grid-row-gap);
              column-gap: var(--grid-column-gap);
              grid-template-columns: repeat(
                auto-fill,
                minmax(
                  max(
                    var(--grid-item--min-width),
                    var(--grid-item--max-width)
                  ),
                1fr)
              );
              background-color: ${BackgroundColors.white};
              width: 100%;
              max-height: 100%;
              overflow: scroll;
              padding-bottom: 200px;
            `,
            // borders.DEBUG_blueBorderThickDashed,
          ].join(' ')}
        >
          <ViewByState
            data={viewmodel.filteredList}
            loadingView={(
              <div>
                loading...
              </div>
            )}
            errorView={(
              <div>
                에러
              </div>
            )}
          >
            {(detectedVisitors) => {
              return (
                <DetectedVisitorsPageLoaded
                  detectedVisitors={detectedVisitors}
                  fullScreenNth={viewmodel.fullScreenNth$M}
                />
              );
            }}
          </ViewByState>
        </div>
      </div>
    </div>
  );
});

export default DetectedVisitorsPage;
