import { motionValue, type MotionValue } from 'framer-motion';
import { makeAutoObservable } from 'mobx';
import { fromResource } from 'mobx-utils';

import { MobxValue } from '^/utils/mobx-utils/MobxValue';

export class MobxMotionValue {
  constructor(
    public value: MobxValue<number>,
    public mValue = motionValue(
      value.value,
    ),
  ) {
    makeAutoObservable(this);
    mValue.on('change', (newValue) => {
      this.value.setValue(newValue);
    });
  }
}

export function mobxValueFromMotionValue(
  mValue: MotionValue<number>,
) {
  const mobxMotionValue = new MobxMotionValue(
    new MobxValue(
      mValue.get(),
    ),
    mValue,
  );
  return fromResource<MobxMotionValue>((sink) => {
    return sink(mobxMotionValue);
  }, () => {
    // ???
  });
}
