/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/** @jsx jsx */
import React from 'react';

import {
  css, cx,
} from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';

import LoadingCircle from '^/userWeb/Assets/LottieAnimations/loading_circle2.svg';

import {
  BackgroundColors, FontSizes, FontWeights, TextColors,
} from '../styles/styleConstants';

import { MediaPreview } from './MediaPreview';

export const SmallVideoThumbnailCell: React.FC<{
  text?: string;
  date: string;
  rootCSS?: string;
  videoSrc?: string;
  rootStyle?: React.CSSProperties;
  onDoubleClick?(): void;
}> = observer(function SmallVideoThumbnailCell(props) {
  const {
    ref: inViewRef,
    inView,
  } = useInView({
    rootMargin: '50px',
  });

  return (
    <div
      ref={inViewRef}
      className={cx(
        css`
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        aspect-ratio: 16 / 10;
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: clamp(200px, 80%, 500px);
        /* flex-basis: 200px; */
        padding-left: 4px;
        padding-right: 4px;
      `,
        props.rootCSS,
        // borders.DEBUG_blueBorderDashed,
      )}
      style={props.rootStyle}
      onDoubleClick={(e) => {
        e.preventDefault();
        if (props.onDoubleClick) {
          props.onDoubleClick();
        }
      }}
    >
      {inView ? (
        <MediaPreview
          mediaSrc={props.videoSrc}
          mediaCSS={css`
            overflow: hidden;
            border-radius: 6px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            // background-color: yellow;
            background: ${BackgroundColors.gray_light} 50% 50% / 50px no-repeat;
            // background: ${BackgroundColors.gray_light} url('${LoadingCircle}') 50% 50% / 50px no-repeat;
          `}
        />
      ) : (
        <div
          className={css`
                      /* background-color: green; */
                      background-color: ${BackgroundColors.gray_light};
                      overflow: hidden;
                      border-radius: 6px;
                      width: 100%;
                      height: 100%;
                    `}
        />
      )}

      <p
        className={css`
          color: ${TextColors.black_light};
          font-size: ${FontSizes.base};
          font-weight: ${FontWeights.regular};
          width: 100%;
          padding-left: 8px;
          padding-right: 8px;
          margin-top: 0px;
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <span>{props.date}</span>
        <span>{props.text}</span>
      </p>
    </div>
  );
});
