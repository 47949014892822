import { getCurrentFreshness } from '^/types/__ResultType';
import { BaseViewModel } from '^/userWeb/BaseViewModel';
import { AppContext } from '^/userWeb/types/AppContext';

export class CowAlarmsTabViewModel extends BaseViewModel {
  constructor(
    public appContext: AppContext,
    public freshness = getCurrentFreshness(),
  ) {
    super(appContext, freshness);
  }

  get list() {
    if (this.myInfo.status !== 'success') {
      return this.myInfo;
    }
    return this.appContext
      .cowMountListCore
      .getList({
        __CowMountHistoryListFilterType: null,
        userId: this.myInfo.value[0].id,
        userToken: this.myInfo.value[1],
      }, this.freshness);
  }
}
