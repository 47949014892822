import { observer } from 'mobx-react-lite';

import { UserList } from '^/userWeb/Components/Lists/UsersList/UsersList';

import type { AdminUsersPageViewModel } from './AdminUsersPage.viewmodel';

export const AdminUsersPage = observer(function AdminUsersPage(p: {
  viewmodel: AdminUsersPageViewModel,
}) {
  return (
    <div>
      <h1> AdminusersPage</h1>
      <UserList
        viewmodel={p.viewmodel.userPickerVM}
      />
    </div>
  );
});
