/* eslint-disable @typescript-eslint/no-use-before-define */
import { makeAutoObservable } from 'mobx';

import { CameraIDType } from '^/types/cameraTypes';
import { UserTokenType } from '^/types/userTypes';
import { AppContext } from '^/userWeb/types/AppContext';

export class LiveCamViewViewModel {
  constructor(
    public appContext: AppContext,
    public camId: CameraIDType,
    public processedBy: string,
    public phone: string,
    public cameraIp: string,
    public camName: string,
    public userToken: UserTokenType,
    // public screenOrientation = createScreenOrientationWatch(),
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }
}
