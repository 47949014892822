import React, { MouseEventHandler } from 'react';

import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';

import emptyLightGreyImg from '^/userWeb/Assets/lightgrey.jpg';

import { useMounted } from '../hooks/useMounted';

import { MediaPreview } from './MediaPreview';
import { NotificationVideoCellStyles } from './NotificationVideoCell.styles';

export const NotificationVideoCell: React.FC<{
  date: string;
  title: string;
  src: string | null;
  onClick?: MouseEventHandler<HTMLElement>;
  onDoubleClick?: MouseEventHandler<HTMLElement>;
  stylesOverride?: typeof NotificationVideoCellStyles;
}> = observer(function NotificationVideoCell(props) {
  const styles = props.stylesOverride || NotificationVideoCellStyles;
  const {
    ref: inViewRef,
    inView,
  } = useInView({
    rootMargin: '20px',
  });
  useMounted(
    `NotificationVideoCell.tsx ${props.date}`,
  );
  return (
    <div
      ref={inViewRef}
      className={styles.root}
    >
      <div
        className={styles.container}
      >
        <div
          className={styles.textArea}
        >
          <p
            className={styles.textArea$Date}
          >{props.date}
          </p>
          <p
            className={styles.textArea$Title}
          >{props.title}
          </p>
        </div>
        {inView ? (
          <MediaPreview
            // onDoubleClick={props.}
            onClick={props.onClick}
            onDoubleClick={props.onDoubleClick}
            mediaCSS={styles.mediaPreview}
            mediaSrc={props.src || emptyLightGreyImg}
          />
        ) : (
          <MediaPreview
            onClick={props.onClick}
            onDoubleClick={props.onDoubleClick}
            mediaCSS={styles.mediaPreviewNORENDER}
          // mediaSrc={emptyLightGreyImg}
          />
        )}
      </div>
      <div
        className={styles.bottomDivider}
      />
    </div>
  );
});
