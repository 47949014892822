import { makeAutoObservable } from 'mobx';

import { UserListViewModel } from '^/userWeb/Components/Lists/UsersList/UsersList.viewmodel';
import type { AppContext } from '^/userWeb/types/AppContext';

export class AdminUsersPageViewModel {
  constructor(
    public appContext: AppContext,
    public userPickerVM = new UserListViewModel(
      appContext.userListCore,
    ),
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }
}
