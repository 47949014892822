/* eslint-disable jsx-a11y/label-has-associated-control */
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { HeaderIntTextField } from '../../TextFields/HeaderIntTextField';
import { HeaderTextField } from '../../TextFields/HeaderTextField';

import type { BaseIotDeviceFormViewModel } from './BaseIotDeviceForm.viewmodel';
import { borders } from '^/userWeb/styles/commonClasses';

export const BaseIotDeviceFormDefaultStyle = {
  root: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* background-color: green; */
    /* width: 100%; */

    input {
      /* background-color: grey; */
    }
  `,
  host: {
    rootLabel: '',
  },
  port: {
    rootLabel: '',
  },
} as const;
export const BaseIotDeviceForm = observer(function BaseIotDeviceForm(p: {
  viewmodel: BaseIotDeviceFormViewModel,
  stylesOverride?: Partial<typeof BaseIotDeviceFormDefaultStyle>;
}) {
  const style = {
    ...BaseIotDeviceFormDefaultStyle,
    ...p.stylesOverride,
  };
  return (
    <div
      className={[
        style.root,
        borders.DEBUG_blueBorderThickDashed,
      ].join(' ')}
    >
      <HeaderTextField
        text={p.viewmodel.accessHost$}
        header="host 호스트 주소 (ip, 도메인, 등)"
        placeholder="예시). 123.123.123.123"
        rootCSS=""
      />
      <HeaderIntTextField
        text={p.viewmodel.accessPort$}
        header="port 포트"
        placeholder="예시). 12345"
        rootCSS=""
      />

      <HeaderTextField
        text={p.viewmodel.name$}
        header="기기 이름"
        placeholder="기기 이름"
        rootCSS=""
      />

      <HeaderTextField
        text={p.viewmodel.name$}
        header="축산등록번호"
        placeholder="축산등록번호"
        rootCSS=""
      />
    </div>
  );
});
