import { makeAutoObservable } from 'mobx';

import {
  LoadingPromise, SuccessPromise,
  NotInitiatedPromiseStale,
} from '^/types/__ResultType';
import type { CameraIDType, CameraType } from '^/types/cameraTypes';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';

import type { GetCameraListAPIFailResult } from '../apiTypes/getCamerasList';

export type LiveCamResultType =
  | GetCameraListAPIFailResult
  | LoadingPromise
  | SuccessPromise<CameraType>;
export class LiveCamerasRepo {
  constructor(

    public liveCamsById: Record<CameraIDType, LiveCamResultType> = {},
  ) {
    makeAutoObservable(this);
  }

  getCamById = computedFn2((camID: CameraIDType) => {
    return this.liveCamsById[camID] || NotInitiatedPromiseStale;
  });

  setCamById(id: CameraIDType, cam: LiveCamResultType) {
    this.liveCamsById[id] = cam;
  }
}
