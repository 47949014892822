import { action, makeAutoObservable } from 'mobx';

export class MobxValue<T> {
  constructor(
    public value: T,
    public onChange?: (newValue: T) => void,
  ) {
    makeAutoObservable(this, {
      setValue: action.bound,
    });
  }

  setValue(value: T) {
    this.value = value;
    if (this.onChange) {
      this.onChange(value);
    }
  }
}
