import React from 'react';

import { observer } from 'mobx-react-lite';

import { AnnouncementCell } from '^/userWeb/Components/AnnouncementCell';
import { WarningChip } from '^/userWeb/Components/Chips/WarningChip';
import { FilledLoadingCircle } from '^/userWeb/Components/StatusViews/LoadingCircle';

import { NoticeTabViewModel } from './NoticeTab.viewmodel';

export const NoticeTab: React.FC<{
  viewmodel: NoticeTabViewModel,
}> = observer(function NoticeTab({
  viewmodel,
}) {
  if (viewmodel.notices.status === 'loading') {
    return (
      <FilledLoadingCircle />
    );
  }
  if (viewmodel.notices.status === 'failure') {
    return (
      <WarningChip title="에러" />
    );
  }
  return (
    <div>
      {viewmodel.notices.value.map((notice, nth) => {
        if (notice.status === 'failure') {
          return (
            <AnnouncementCell
              // eslint-disable-next-line react/no-array-index-key
              key={`error_${nth}`}
              date="-"
              content=""
              title="공지 가져오기에 실패하였습니다."
              isFolded
            />
          );
        }
        if (notice.status === 'loading') {
          return (
            <AnnouncementCell
              // eslint-disable-next-line react/no-array-index-key
              key={`loading_${nth}`}
              date="-"
              content=""
              title="로딩 중"
              isFolded
            />
          );
        }
        return (
          <AnnouncementCell
            key={notice.value.id}
            date={notice.value.created.toLocaleDateString()}
            content={notice.value.content}
            title={notice.value.title}
            isFolded={viewmodel.expanded.has(notice.value.id)}
          />
        );
      })}
    </div>
  );
});
