/** @jsx jsx */
import React from 'react';

import { css, cx } from '@emotion/css';

import {
  AccentColors, FontSizes, FontWeights, TextColors,
} from '^/userWeb/styles/styleConstants';

export const SegmentedControlItem: React.FC<{
  title: string;
  isSelected: boolean;
  rootCss?: string;
  onClick?: () => void;
}> = (props) => {
  return (
    <button
      onClick={
        props.onClick
      }
      type="button"
      className={cx(
        css`
          display: flex;
          flex-grow: 1;
          background-color: ${props.isSelected ? AccentColors.blue : 'transparent'};
          color: ${props.isSelected ? TextColors.white : TextColors.black_light};
          font-size: ${FontSizes.base};
          font-weight: ${FontWeights.regular};
          border: none;
          border-radius: 6px;
          align-items: center;
        `,
        props.rootCss,
      )}
    >
      <p
        className={css`
          width: 100%;
        `}
      >
        {props.title}
      </p>
    </button>
  );
};
