import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { BasePicker } from '^/userWeb/Components/Pickers/BasePicker';
import { DatePicker } from '^/userWeb/Components/Pickers/DatePicker';

import { DetectedVisitorsPageViewModel } from './DetectedVisitorsPage.viewmodel';

export const DetectedVisitorsPageTopFilter: React.FC<{
  viewmodel: DetectedVisitorsPageViewModel,
}> = observer(function CowMountHistoryPageTopFilter({
  viewmodel,
}) {
  return (
    <>
      <BasePicker
        name="카메라"
        selectedItemTitle="전체"
        rootCSS={css`
          height: 40px;
        `}
      />
      <DatePicker
        name="시작일"
        date={viewmodel.startDate}
        rootCSS={css`
          height: 40px;
        `}
      />
      <DatePicker
        name="종료일"
        date={viewmodel.endDate}
        rootCSS={css`
          height: 40px;
        `}
      />
    </>
  );
});
