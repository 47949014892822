/** @jsx jsx */
import React from 'react';

import {
  css, cx,
} from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';

import { BaseTextFieldStyle } from './__BaseTextFieldStyle';
import { TextFieldHeaderStyle } from './TextFieldHeaderStyle';

export const HeaderTextField: React.FC<{
  text: MobxInputValue<string>,
  header: string;
  placeholder: string;
  rootCSS: string;
}> = observer(function HeaderTextField(props) {
  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
        `,
        props.rootCSS,
      )}
    >
      <p className={cx(
        TextFieldHeaderStyle,
      )}
      >{props.header}
      </p>
      <input
        type="text"
        className={cx(
          BaseTextFieldStyle,
          css`
            margin-top: 8px;
          `,
        )}
        placeholder={props.placeholder}
        value={props.text.value || ''}
        onChange={(e) => props
          .text.set(e.currentTarget.value)}
      />
    </div>
  );
});
