import { formatISO9075 } from 'date-fns';
import { makeAutoObservable } from 'mobx';

import { IotDeviceT } from '^/__generated__/pothosZod/generated_models';
import { DeviceRelayNamesT, DeviceRelayStatusTypesT } from '^/iotInterface/RelayConstantsV1';
import { asSuccessPromise, mapPromiseResult } from '^/types/__ResultType';
import { AppContext } from '^/userWeb/types/AppContext';
import { createAutoDisposedInterval } from '^/userWeb/utils/AutoDisposedInterval';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';
import { waitMs } from '^/utils/waitMs';

export type ActiveSensorDetail = {
  type: 'RelayControl',
} | {
  type: 'SensorDataGraph',
} | {
  type: 'SensorDataTable';
};

export class SensorsDetailPopupViewModel {
  constructor(
    public appContext: AppContext,
    public device: IotDeviceT,
    public activeView = new MobxInputValue<ActiveSensorDetail>({
      type: 'SensorDataGraph',
    }),
    public autoRefresher = createAutoDisposedInterval(
      () => {
        // toast('Refresher running!');
        appContext
          .iotDevicesSensorDataListCore
          .triggerListRefresh();
      },
      1000 * 60 * 7,
      'SensorsPage.viewmodel',
    ).current(),
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }

  get sensorData() {
    const deviceId = this.device.id;
    const sensorDataResult = this
      .appContext
      .iotDevicesSensorDataListCore
      .iotDeviceSensordata({
        deviceId,
      });
    if (sensorDataResult.status !== 'success') {
      return sensorDataResult;
    }
    return asSuccessPromise(
      sensorDataResult.value
        .map((d) => ({
          ...d,
          time: formatISO9075(d.assumedTime),
        })),
    );
  }

  get relayData() {
    const deviceId = this.device.id;
    const relayDataResult = this
      .appContext
      .iotDevicesRelayDataListCore
      .iotDeviceRelayData({
        deviceId,
      });
    return relayDataResult;
    // return mapPromiseResult(relayDataResult,
    //   (v) => {
    //     v.
    //   }
    // )
  }

  async sendRelayState(
    deviceName: DeviceRelayNamesT,
    deviceStatus: DeviceRelayStatusTypesT,
  ) {
    const userToken = this
      .appContext
      .tokenRepo
      .userTokenWithData;
    if (userToken.status !== 'success') {
      return;
    }
    const deviceId = this.device.id;
    await this
      .appContext
      .apiClient
      .iot
      .sendIotRelayCmd
      .mutate({
        deviceId,
        deviceName,
        deviceStatus,
        userToken: userToken.value.token,
      });
    await waitMs(400, null);
    this
      .appContext
      .iotDevicesRelayDataListCore
      .triggerListRefresh();
  }
}
