import { makeAutoObservable } from 'mobx';

import type { UserType } from '^/types/userTypes';
import type { AppContext } from '^/userWeb/types/AppContext';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';

import { UserListViewModel } from './UsersList.viewmodel';

export class UserSingleSelectListViewModel {
  constructor(
    public appContext: AppContext,
    public selectedUser = new MobxInputValue<
      UserType | null
    >(null),
    public listVM = new UserListViewModel(
      appContext.userListCore,
    ),
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }
}
