import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import type { UserType } from '^/types/userTypes';
import { borders } from '^/userWeb/styles/commonClasses';

export const defaultUserItemStyle = {
  root: css`
  `,
} as const;
export const UsersListItem = observer(
  function UsersListItem(p: {
    user: UserType,
    stylesOverride?: Partial<typeof defaultUserItemStyle>;
  }) {
    const styles = {
      ...defaultUserItemStyle,
      ...p.stylesOverride,
    };
    return (
      <div
        className={[
          // borders.DEBUG_greenBorderDashed,
          styles.root,
        ].join(' ')}
      >
        <p>{p.user.name}</p>
        <p>{p.user.phone}</p>
      </div>
    );
  });
