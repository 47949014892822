import { MyInfoCore } from '^/userWeb/core/MyInfoCore';
import { FreshnessRepo } from '^/userWeb/repositories/FreshnessRepo';
import { TokenRepo } from '^/userWeb/repositories/TokenRepo';

import { type APIClient } from '../apiClient';
import { CowMountHistoryListCore } from '../core/CowMountHistoryListCore';
import { DetectedVisitorsListCore } from '../core/DetectedVisitorsListCore';
import { IotDevicesListCore } from '../core/iotDevicesListCore';
import { IotDevicesRelayDataListCore } from '../core/iotDevicesRelayDataListCore';
import { IotDevicesSensorDataListCore } from '../core/iotDevicesSensorDataListCore';
import { LiveCamerasListCore } from '../core/LiveCamerasListCore';
import { NoticesListCore } from '../core/NoticesListCore';
import { UserListCore } from '../core/userListCore';
import { CowMountHistoryRepo } from '../repositories/CowMountHistoryRepo';
import { DetectedVisitorsRepo } from '../repositories/DetectedVistorsRepo';
import { ExpoPushTokenRepo } from '../repositories/ExpoPushTokenRepo';
import { FCMTokenRepo } from '../repositories/FCMTokenRepo';
import { LiveCamerasRepo } from '../repositories/LiveCamerasRepo';
import { MyInfoRepo } from '../repositories/MyInfoRepo';
import { NoticesRepo } from '../repositories/NoticesRepo';

export function getDefaultAppContext(
  apiClient: APIClient,
) {
  const fcmTokenRepo = new FCMTokenRepo();
  (window as any).fcmTokenRepo = fcmTokenRepo;
  const expoPushTokenRepo = new ExpoPushTokenRepo();
  (window as any).expoPushTokenRepo = expoPushTokenRepo;

  const freshnessRepo = new FreshnessRepo();
  const tokenRepo = new TokenRepo();
  const myInfoRepo = new MyInfoRepo();

  const myInfoCore = new MyInfoCore(
    tokenRepo,
    myInfoRepo,
    fcmTokenRepo,
    expoPushTokenRepo,
    apiClient,
  );
  // camera list
  const liveCamsRepo = new LiveCamerasRepo();
  const liveCamsListCore = new LiveCamerasListCore(
    liveCamsRepo,
    apiClient,
  );
  const cowMountHistoryRepo = new CowMountHistoryRepo();
  const cowMountListCore = new CowMountHistoryListCore(
    apiClient,
    cowMountHistoryRepo,
  );

  const carPersonDetectedRepo = new DetectedVisitorsRepo();
  const carPersonDetectedListCore = new DetectedVisitorsListCore(
    apiClient,
    carPersonDetectedRepo,
  );

  const noticesRepo = new NoticesRepo();
  const noticesListCore = new NoticesListCore(apiClient, noticesRepo);

  const iotDevicesListCore = new IotDevicesListCore(
    apiClient,
    tokenRepo,
  );
  const iotDevicesSensorDataListCore = new IotDevicesSensorDataListCore(
    apiClient,
    tokenRepo,
  );
  const iotDevicesRelayDataListCore = new IotDevicesRelayDataListCore(
    apiClient,
    tokenRepo,
  );
  const userListCore = new UserListCore(
    apiClient,
    tokenRepo,
  );
  return {
    apiClient,
    // repos:
    freshnessRepo,
    tokenRepo,
    liveCamsRepo,
    cowMountHistoryRepo,
    detectedVisitorsRepo: carPersonDetectedRepo,
    noticesRepo,
    fcmTokenRepo,
    // cores:
    myInfoCore,
    liveCamsListCore,
    cowMountListCore,
    detectedVisitorsListCore: carPersonDetectedListCore,
    noticesListCore,

    iotDevicesListCore,
    iotDevicesSensorDataListCore,
    iotDevicesRelayDataListCore,
    userListCore,
  } as const;
}

export type AppContext = ReturnType<typeof getDefaultAppContext>;
