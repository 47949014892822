import { css } from '@emotion/css';

import { TextColors, FontSizes, FontWeights } from '^/userWeb/styles/styleConstants';

export const BaseButtonTextStyle = css`
  color: ${TextColors.black};
  font-size: ${FontSizes.base};
  font-weight: ${FontWeights.regular};
  white-space: nowrap;
  :active {
    opacity: 0.5;
  }
`;
