export class MockLocalStorage implements Storage {
  length: number = 0;

  data: Record<string, string | null> = {};

  clear(): void {
    this.data = {};
  }

  getItem(key: string): string | null {
    return this.data[key] ?? null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  key(index: number): string | null {
    throw new Error('Method not implemented.');
  }

  removeItem(key: string): void {
    delete this.data[key];
  }

  setItem(key: string, value: string): void {
    this.data[key] = value;
  }
}
