/* eslint-disable jsx-a11y/no-static-element-interactions */
import { cx, css } from '@emotion/css';
import { formatISO9075 } from 'date-fns';
import itiriri from 'itiriri';
import { observer } from 'mobx-react-lite';

import { SmallVideoThumbnailCell } from '^/userWeb/Components/SmallVideoThumbnailCell';
import { routes } from '^/userWeb/routes';
import { hideScrollBar } from '^/userWeb/styles/commonClasses';

import { DetectedVisitorsSlidesViewModel } from './DetectedVisitorsSlides.viewmodel';

export const DetectedVisitorsSlides: React.FC<{
  viewmodel: DetectedVisitorsSlidesViewModel,
}> = observer(function DetectedVisitorsSlides({
  viewmodel,
}) {
  if (viewmodel.list.status === 'loading') {
    return <h1> loading... </h1>;
  }
  if (viewmodel.list.status === 'failure') {
    return (
      <h1> ERROR </h1>
    );
  }
  return (
    <div
      className={cx(
        hideScrollBar,
        css`
          display: flex;
          flex-direction: row;
          gap: 12px;
          padding-top: 8px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 8px;
          overflow-x: scroll;
        `,
      )}
    >
      {itiriri(viewmodel.list.value)
        .take(3)
        .map((mountResult, nth) => {
          if (mountResult.status === 'loading') {
            return (
              <SmallVideoThumbnailCell
                // eslint-disable-next-line react/no-array-index-key
                key={`loading_${nth}`}
                date="로딩 중..."
              />
            );
          }
          if (mountResult.status === 'failure') {
            return (
              <SmallVideoThumbnailCell
                // eslint-disable-next-line react/no-array-index-key
                key={`error_${nth}`}
                date="에러"
                rootCSS={css`
                  border-color: red;
                `}
              />
            );
          }
          return (
            <SmallVideoThumbnailCell
              key={mountResult.value.id}
              videoSrc={mountResult.value.videoUrl}
              date={formatISO9075(
                mountResult.value.createdAt,
              )}
              text={`카메라 ${mountResult.value.cameraName}`}
              rootCSS={css`
                width: 200px;
              `}
              onDoubleClick={() => {
                routes.visitorHistory({
                  fullscreenNth: nth,
                  freshness: viewmodel.freshness,
                }).push();
              }}
            />
          );
        })}
      <div
        onClick={() => routes
          .visitorHistory({})
          .push()}
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
        `}
      >
        ...더 보기
      </div>
    </div>
  );
});
