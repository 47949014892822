import { useEffect, useRef, useState } from 'react';

export function usePreviousOLD<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value; // assign the value of ref to the argument
  }, [value]); // this code will run when the value of 'value' changes
  return ref.current; // in the end, return the current ref value.
}

export function usePrevious<T>(value: T) {
  const [tuple, setTuple] = useState([null, value]);
  if (tuple[1] !== value) {
    setTuple([tuple[1], value]);
  }
  return tuple[0];
}
