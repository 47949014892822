import React from 'react';

import { css } from '@emotion/css';

import {
  AccentColors, BackgroundColors, FontSizes, FontWeights,
} from '^/userWeb/styles/styleConstants';
import { SVGReactComponent } from '^/userWeb/types/SVGReactComponent';

export const TabBarItem: React.FC<{
  // eslint-disable-next-line react/no-unused-prop-types
  Icon: SVGReactComponent;
  title: string;
  isSelected: boolean;
  onClick?: () => void;
}> = (props) => {
  const foregroundColor = props.isSelected
    ? AccentColors.blue : AccentColors.gray;

  return (
    <button
      type="button"
      onClick={
        props.onClick
      }
      className={
        css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        padding-top: 4px;
        padding-bottom: 4px;
        width: 56px;
        height: 56px;
        border: none;
        background-color: ${BackgroundColors.white};
      `
      }
    >
      <props.Icon
        className={css`
          fill: ${foregroundColor};
          width: 24px;
          height: 24px;
        `}
      >
      </props.Icon>
      <p
        className={
          css`
          color: ${foregroundColor};
          font-weight: ${FontWeights.regular};
          font-size: ${FontSizes.xs};
          height: ${FontSizes.xs};
          margin: 0px;
          padding: 0px;
          user-select: none;;
        `
        }
      >
        {props.title}
      </p>
    </button>
  );
};
