/** @jsx jsx */
import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import {
  BackgroundColors, FontSizes, FontWeights, LineColors, TextColors,
} from '^/userWeb/styles/styleConstants';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

import { Switch } from './Switch/Switch';

export const DescriptiveSwitch: React.FC<{
  title: string;
  description?: string;
  rootCSS?: string;
  isOn: MobxValue<boolean>;
}> = observer(function DescriptiveSwitch(props) {
  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: row;
          gap: 12px;
          background-color: ${BackgroundColors.white};
          border: 1px ${LineColors.gray_light} solid;
          border-radius: 10px;
          padding-top: 12px;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 12px;
        `,
        props.rootCSS,
      )}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        `}
      >
        <p
          className={css`
            color: ${TextColors.black};
            font-size: ${FontSizes.base};
            font-weight: ${FontWeights.medium};
            margin: 0px;
          `}
        >{props.title}
        </p>
        <p
          className={css`
            color: ${TextColors.black_light};
            font-size: ${FontSizes.base};
            margin: 0px;
            margin-top: 8px;
          `}
        >{props.description}
        </p>
      </div>
      <Switch
        isOn={props.isOn}
        rootCSS={css`
          flex-grow: 0;
        `}
      />
    </div>
  );
});
