import { forwardRef } from 'react';

import { css } from '@emotion/css';

import { ReactComponent as WarningIcon } from '^/userWeb/Assets/Icons/warning.svg';
import { AccentColors, IconColors, TextColors } from '^/userWeb/styles/styleConstants';
import { HTMLButtonProps } from '^/userWeb/types/HTMLButtonProps';

import { IconChip } from './IconChip';

type PropsType = HTMLButtonProps & {
  title: string;
  isSelected?: boolean;
};
export const WarningChip = forwardRef<HTMLButtonElement, PropsType>(({
  title,
  isSelected,
  ...buttonProps
}, ref) => {
  return (
    <IconChip
      ref={ref}
      title={title}
      Icon={WarningIcon}
      iconColor={isSelected ? IconColors.white : AccentColors.red}
      rootCSS={css`
        background-color: ${isSelected && AccentColors.red};
        border: ${isSelected && 'none'};
      `}
      textCSS={css`
        color: ${isSelected && TextColors.white};
      `}
      {...buttonProps}
    />
  );
});
