import React from 'react';

import { css, cx } from '@emotion/css';

import { AccentColors, TextColors } from '^/userWeb/styles/styleConstants';
import { HTMLButtonProps } from '^/userWeb/types/HTMLButtonProps';

import { BaseButtonTextStyle } from '../Buttons/BaseButtonTextStyle';

import { BaseChipStyle } from './BaseChipStyle';

type PropsType = {
  title: string;
  isSelected?: boolean;
} & HTMLButtonProps;
export const BaseChip = React.forwardRef<HTMLButtonElement, PropsType>(({
  isSelected,
  title,
  ...buttonProps
}, ref) => {
  // const {
  //   isSelected,
  //   title,
  // } = props;
  return (
    <button
      ref={ref}
      type="button"
      className={cx(
        BaseChipStyle,
        css`
          user-select: none;
        `,
      )}
      style={isSelected ? {
        backgroundColor: AccentColors.blue,
        border: 'none',
      } : undefined}
      {...buttonProps}
    >
      <span
        className={BaseButtonTextStyle}
        style={isSelected ? {
          color: TextColors.white,
        } : {}}
      >
        { title }
      </span>
    </button>
  );
});
