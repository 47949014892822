import { css } from '@emotion/css';

import { BackgroundColors, LineColors, TextColors } from '^/userWeb/styles/styleConstants';

export const BaseChipStyle = css`
  display: flex;
  align-items: center;
  background-color: ${BackgroundColors.white};
  color: ${TextColors.black};
  border-radius: 8px;
  border: 1px ${LineColors.gray_light} solid;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
`;
