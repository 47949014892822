import { makeAutoObservable } from 'mobx';

import {
  LoadingPromise,
  NotInitiatedPromiseStale,
} from '^/types/__ResultType';

import { GetUserInfoAPIFailResult, GetUserInfoAPISuccessResult } from '../apiTypes/getUserInfo';

export type GetUserInfoAPIResult =
  | GetUserInfoAPIFailResult
  | GetUserInfoAPISuccessResult
  | LoadingPromise;

export class MyInfoRepo {
  constructor(

    public myInfo: GetUserInfoAPIResult = NotInitiatedPromiseStale,
  ) {
    makeAutoObservable(this);
  }

  setMyInfo(myInfo: typeof this.myInfo) {
    this.myInfo = myInfo;
  }
}
