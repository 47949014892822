import { css } from '@emotion/css';

import {
  BackgroundColors, FontSizes, FontWeights, TextColors,
} from '^/userWeb/styles/styleConstants';

export const BaseButtonStyle = css`
  background-color: ${BackgroundColors.white};
  color: ${TextColors.black};
  font-size: ${FontSizes.base};
  font-weight: ${FontWeights.regular};
  border: none;
  border-radius: 10px;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 14px;
  align-items: center;
  user-select: none;
  transition: all 0.1s;
`;
