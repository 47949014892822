import type { Unsubscribable } from '@trpc/server/observable';
import { makeAutoObservable } from 'mobx';
import { fromResource } from 'mobx-utils';

import { CameraIDType } from '^/types/cameraTypes';
import { UserTokenType } from '^/types/userTypes';
import type { APIClient } from '^/userWeb/apiClient';
import { CameraStatusAPIResult } from '^/userWeb/apiTypes/cameraStatus';

export function createCamSubscription(
  apiClient: APIClient,
  cameraId: CameraIDType,
  userToken: UserTokenType,
) {
  let unsubscribeFn: Unsubscribable | null = null;
  return fromResource<CameraStatusAPIResult>(
    (sink) => {
      unsubscribeFn = apiClient
        .camera_status
        .subscribe({
          cameraId,
          userToken,
          $__type: 'cameraId',
        }, {
          onData(a) {
            sink(a);
          },
        });
    },
    () => {
      if (unsubscribeFn) {
        unsubscribeFn.unsubscribe();
      }
    },
  );
}
export class LiveCamStatusViewModel {
  constructor(
    public apiClient: APIClient,
    public camId: CameraIDType,
    public userToken: UserTokenType,
    public name: string,
    public subscription = createCamSubscription(
      apiClient,
      camId,
      userToken,
    ),
  ) {
    makeAutoObservable(this, {
      apiClient: false,
    });
  }
}
