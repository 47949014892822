import { css } from '@emotion/css';

import { BackgroundColors } from './styleConstants';

export const fillParentElem = css`
  width: 100%;
  height: 100%;
`;

export const whiteBackground = css`
  background-color: ${BackgroundColors.white};
`;

export const hideScrollBar = css`
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const noMargin = css`
  margin: 0;
`;

export const borders = {
  DEBUG_greenBorderDashed: css`
    border: green 2px dashed;
  `,
  DEBUG_blueBorderDashed: css`
    border: blue 2px dashed;
  `,
  DEBUG_blueBorderThickDashed: css`
    border: blue 20px dashed;
  `,
  DEBUG_purpleBorderDashed: css`
    border: purple 2px dashed;
  `,
  DEBUG_redBorderDashed: css`
    border: red 2px dashed;
  `,
  DEBUG_orangeBorderDashed: css`
  border: orange 2px dashed;
`,
  DEBUG_pinkDashed: css`
    border: pink 2px dashed;
  `,
  DEBUG_goldDashed: css`
    border: gold 2px dashed;
  `,
} as const;

export const outlines = {
  DEBUG_greenoutlineDashed: css`
    outline: green 2px dashed;
  `,
  DEBUG_blueoutlineDashed: css`
    outline: blue 2px dashed;
  `,
  DEBUG_blueoutlineThickDashed: css`
    outline: blue 20px dashed;
  `,
  DEBUG_purpleoutlineDashed: css`
    outline: purple 2px dashed;
  `,
  DEBUG_redoutlineDashed: css`
    outline: red 2px dashed;
  `,
  DEBUG_orangeoutlineDashed: css`
  outline: orange 2px dashed;
`,
  DEBUG_pinkDashed: css`
    outline: pink 2px dashed;
  `,
  DEBUG_goldDashed: css`
    outline: gold 2px dashed;
  `,
} as const;
