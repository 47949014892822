import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { LiveCamsRadioChips } from './LiveCamsRadioChips';
import { LiveCamsSlider } from './LiveCamsSlider';
import { LiveCamsSliderAreaViewModel } from './LiveCamsSliderArea.viewmodel';

export const LiveCamsSliderArea: React.FC<{
  viewmodel: LiveCamsSliderAreaViewModel,
  videoContainerClassName?: string,
}> = observer(function LiveCamsSliderArea({
  viewmodel,
  videoContainerClassName,
}) {
  if (viewmodel.data.status === 'failure') {
    return (
      <div className={videoContainerClassName}>
        <h2> ERROR </h2>
        <pre>
          {JSON.stringify(viewmodel.data.failure, null, 2)}
        </pre>
      </div>
    );
  }
  if (viewmodel.data.status === 'loading') {
    return (
      <div className={videoContainerClassName}>
        <h2> loading </h2>
      </div>
    );
  }
  return (
    <>
      <div
        id="LiveCamsSliderArea_OUTER"
        className={css`
          overflow-x: scroll;
          width: 100%;
        `}
      >
        <div
          id="LiveCamsSliderArea_INNER"
          className={css`
            display: flex;
            flex-direction: column;
            gap: 10px;
          `}
        >
          <LiveCamsRadioChips
            slideIndex={viewmodel.slideIndex}
            liveCamsResult={viewmodel.data.value.statuses}
          />
          <LiveCamsSlider
            slideIndex={viewmodel.slideIndex}
            liveCamsResult={viewmodel.data.value.cams}
            isFullScreen={viewmodel.isFullScreen}
            videoContainerClassName={videoContainerClassName}
          />
        </div>
      </div>

    </>
  );
});
