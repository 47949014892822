import { makeAutoObservable } from 'mobx';

import { LoadingPromise, NotInitiatedPromiseStale, SuccessPromise } from '^/types/__ResultType';
import type { NoticeIdType, NoticeType } from '^/types/notice';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';

import type { GetNoticesListFailResult } from '../apiTypes/getNotices';

export type NoticesResultType =
  | GetNoticesListFailResult
  | LoadingPromise
  | SuccessPromise<NoticeType>;

export class NoticesRepo {
  constructor(
    public notices: Record<NoticeIdType, NoticesResultType> = {},
  ) {
    makeAutoObservable(this);
  }

  get = computedFn2((
    id: NoticeIdType,
  ) => {
    return this.notices[id] || NotInitiatedPromiseStale;
  });

  set(
    id: NoticeIdType,
    notice: NoticesResultType,
  ) {
    this.notices[id] = notice;
  }
}
