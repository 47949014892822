/* eslint-disable no-underscore-dangle */
import { action, makeObservable } from 'mobx';

import { ExpoPushTokenT } from '^/types/pushTypes';

export class ExpoPushTokenRepo {
  constructor(
    public _expoPushToken: ExpoPushTokenT | null = (window as any).expoPushToken || null,
  ) {
    makeObservable(this, {
      expoPushToken: true,
      setToken: action.bound,
    });
  }

  setToken(token: ExpoPushTokenT) {
    console.log(`ExpoPushTokenRepo:setToken(${token})`);
    this._expoPushToken = token;
  }

  get expoPushToken(): ExpoPushTokenT | undefined {
    const toReturn = this._expoPushToken || (window as any).expoPushToken;
    // alert(`expoPushToken: ${toReturn}`);
    return toReturn;
  }
}
