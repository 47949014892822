import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import type { IotDeviceT } from '^/__generated__/pothosZod/generated_models';
import { borders } from '^/userWeb/styles/commonClasses';

export const SensorItem = observer(function SensorItem(p: {
  device: IotDeviceT,
  onClick: () => void,
}) {
  return (
    <div
      className={[
        css`
          padding-top: 16px;
          padding-bottom: 16px;
          margin-left: 16px;
          margin-inline-end: 16px;
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          border-bottom: 1px dotted black;
        `,
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
      onClick={() => p.onClick()}
      role="listitem"
    >
      <div>
        기기 이름: {p.device.name || '이름없음'}
      </div>
      <div
        className={[
          // css`
          //   margin-left: 12px;
          // `,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      >
        주소: {p.device.accessHost}:{p.device.accessPort}
      </div>
    </div>
  );
});
