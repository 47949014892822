import { css, cx } from '@emotion/css';

import { AccentColors, TextColors } from '../../styles/styleConstants';

import { BaseButtonStyle } from './BaseButtonStyle';

export const FilledButtonStyle = cx(
  BaseButtonStyle,
  css`
    background-color: ${AccentColors.blue};
    color: ${TextColors.white};

    &:active {
      color: ${TextColors.white50};
    }
  `,
);

export const FilledDoneButtonStyle = cx(
  BaseButtonStyle,
  css`
    background-color: ${AccentColors.green};
    color: ${TextColors.white};

    &:active {
      color: ${TextColors.white50};
    }
  `,
);
