import React from 'react';

import { formatISO9075 } from 'date-fns';
import { observer } from 'mobx-react-lite';

import { NotificationVideoCell } from '^/userWeb/Components/NotificationVideoCell';
import { FilledLoadingCircle } from '^/userWeb/Components/StatusViews/LoadingCircle';
import { routes } from '^/userWeb/routes';

import { CowAlarmsTabViewModel } from './CowAlarmsTab.viewmodel';

export const CowAlarmsTab: React.FC<{
  viewmodel: CowAlarmsTabViewModel,
}> = observer(function CowAlarmsTab({
  viewmodel,
}) {
  if (viewmodel.list.status === 'loading') {
    return <FilledLoadingCircle />;
  }
  if (viewmodel.list.status === 'failure') {
    return <h1> error </h1>;
  }
  return (
    <>
      {viewmodel.list.value.map((
        cowMount,
        nth,
      ) => {
        if (cowMount.status === 'loading') {
          return (
            <NotificationVideoCell
              // eslint-disable-next-line react/no-array-index-key
              key={`loading_${nth}`}
              date=" - "
              title="로딩 중 입니다..."
              src={null}
            />
          );
        }
        if (cowMount.status === 'failure') {
          return (
            <NotificationVideoCell
              // eslint-disable-next-line react/no-array-index-key
              key={`error_${nth}`}
              date=" - "
              title="에러가 발생하였습니다"
              src={null}
            />
          );
        }
        const dateString = formatISO9075(
          cowMount.value.createdAt,
        );

        return (
          <NotificationVideoCell
            // FIXME: cowmount.value.isCheck
            key={cowMount.value.id}
            date={dateString}
            title={`카메라 ${cowMount.value.cameraName} 에서 승가가 발생하였습니다`}
            src={cowMount.value.videoUrl || null}
            onDoubleClick={() => {
              routes.cowMountingHistory({
                detectionType: 'ride',
                fullscreenNth: nth,
                freshness: viewmodel.freshness,
              }).push();
            }}
          />
        );
      })}
    </>
  );
});
