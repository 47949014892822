import type { DetectionType } from '@prisma/client';
import { noMatch, ValueSerializer } from 'type-route';
import type { BRAND } from 'zod';

import type { IotDeviceIdT } from '^/__generated__/pothosZod/generated_scalars';

import { FreshnessType } from './__ResultType';
import { CameraIDType } from './cameraTypes';
import type { CowMountHistoryIdType } from './CowMountHistoryType';

export const IotDeviceIdParam: ValueSerializer<IotDeviceIdT> = {
  parse(raw: string) {
    return raw as IotDeviceIdT;
  },
  stringify(value) {
    return value;
  },
};

export const CameraIdParam: ValueSerializer<CameraIDType> = {
  parse(raw) {
    return raw as CameraIDType;
  },
  stringify(value) {
    return value;
  },
};

export const CowMountHistoryIdParam: ValueSerializer<CowMountHistoryIdType> = {
  parse(raw) {
    return raw as CowMountHistoryIdType;
  },
  stringify(value) {
    return value;
  },
};

export const DetectionTypeParam: ValueSerializer<DetectionType> = {
  parse(raw) {
    if (raw === 'person') {
      return 'person' as const;
    }
    if (raw === 'ride') {
      return 'ride' as const;
    }
    if (raw === 'stanzone') {
      return 'stanzone' as const;
    }
    return noMatch;
  },
  stringify(value) {
    return value;
  },
};

export const FreshnessParam: ValueSerializer<FreshnessType> = {
  parse(raw) {
    return Number(raw) as FreshnessType;
  },
  stringify(value) {
    return String(value);
  },
};
