/* eslint-disable jsx-a11y/label-has-associated-control */
import { useId } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { borders } from '^/userWeb/styles/commonClasses';

import { UsersListItem } from './UserItemBasic';
import { UserSingleSelectListViewModel } from './UserSingleSelectListViewModel';
import { UserList, UserListDefaultStyles } from './UsersList';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const UserSingleSelectListDefaultStyles = {
  list: UserListDefaultStyles,
} as const;
export const UserSingleSelectList = observer(function UserSingleSelectedList(p: {
  viewmodel: UserSingleSelectListViewModel,
  stylesoverride?: Partial<typeof UserSingleSelectListDefaultStyles>;
}) {
  const styles = {
    ...UserListDefaultStyles,
    ...p.stylesoverride,
  };
  const containerId = useId();
  const selectedUser = p.viewmodel.selectedUser.value;
  return (
    <>
      {/* <h1>{selectedUser?.name}</h1> */}
      <UserList
        stylesOverride={styles.list}
        viewmodel={p.viewmodel.listVM}
        customRenderer={({
          user,
        }) => {
          const selected = selectedUser?.id === user.id;
          const newId = `UserSingleSelectList_${containerId}_${user.id}`;
          return (
            <label
              key={user.id}
              className={[
                selected ? css`
                  background-color: yellow;
                ` : '',
                css`
                  display: flex;
                `,
              ].join(' ')}
              htmlFor={newId}
            >
              <input
                id={newId}
                type="radio"
                // radioGroup={radioGroupName}
                onChange={(e) => {
                  console.log(user.name);
                  p.viewmodel.selectedUser.set(user);
                }}
                checked={selected}
              />
              <UsersListItem
                stylesOverride={{
                  root: [
                    css`
                      flex: 1;
                      display: flex;
                      gap: 12px;
                      margin-top: 8px;
                      margin-bottom: 8px;
                    `,
                    // borders.DEBUG_greenBorderDashed,
                  ].join(' '),
                }}
                user={user}
              />
            </label>
          );
        }}
      />
    </>
  );
});
