import { makeObservable } from 'mobx';

import { asSuccessPromise, getCurrentFreshness, NotInitiatedPromiseStale } from '^/types/__ResultType';
import { BaseViewModel } from '^/userWeb/BaseViewModel';
import { routes } from '^/userWeb/routes';
import { AppContext } from '^/userWeb/types/AppContext';

export class MyPageViewModel extends BaseViewModel {
  constructor(
    public appContext: AppContext,
    public freshness = getCurrentFreshness(),
  ) {
    super(appContext, freshness);
    makeObservable(this, {
      appContext: false,
      myName: true,
      logout: true,
    });
  }

  get myName() {
    console.log('this.myInfo', this.myInfo);
    if (this.myInfo.status !== 'success') {
      return this.myInfo;
    }
    return asSuccessPromise(this.myInfo.value[0].name);
  }

  async logout() {
    if (this.appContext.tokenRepo.userToken.status !== 'success') {
      // impossible case...
      return;
    }
    const { fcmToken } = this.appContext.fcmTokenRepo;

    this.appContext
      .apiClient
      .user
      .logout
      .mutate({
        fcmToken: fcmToken || null,
        userToken: this.appContext.tokenRepo.userToken.value,
        userId: null,
      });
    this.appContext.tokenRepo.setUserToken(NotInitiatedPromiseStale);
    routes.main({
      freshness: getCurrentFreshness(),
    }).replace();
    const reg = await navigator.serviceWorker?.getRegistration();
    if (reg) {
      const subscription = await reg.pushManager?.getSubscription();
      subscription?.unsubscribe();
      await reg.unregister();
    }
  }
}
