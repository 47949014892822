export async function registerSW() {
  const existingServiceWorker = await navigator
    .serviceWorker
    ?.getRegistration();
  const serviceWorkerReg = existingServiceWorker
    || await navigator.serviceWorker
      ?.register('/serviceWorker.js', {
        scope: '/',
      });
  const activeServiceWorker = new Promise<ServiceWorker>((resolve, reject) => {
    if (!serviceWorkerReg) return;
    if (serviceWorkerReg.active) {
      resolve(serviceWorkerReg.active);
    }
    serviceWorkerReg.addEventListener('updatefound', (ev) => {
      if (serviceWorkerReg.active) {
        resolve(serviceWorkerReg.active);
      }
    });
  });
  if (!navigator.serviceWorker) {
    return null;
  }
  const reg2 = await navigator.serviceWorker.ready;
  return reg2;
}

export const registeredServiceWorker = registerSW();
