import itiriri from 'itiriri';
import { makeAutoObservable } from 'mobx';
import {
  BehaviorSubject,
} from 'rxjs';

import type { IotDeviceIdT } from '^/__generated__/pothosZod/generated_scalars';
import {
  DeviceRelayNames, DeviceRelayNamesT,
} from '^/iotInterface/RelayConstantsV1';
import { asFailedPromise, asSuccessPromise } from '^/types/__ResultType';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';
import { extractTokenData } from '^/utils/token/forClient';

import type { APIClient } from '../apiClient';
import { createAutoSyncedCaller3 } from '../autoSyncedCore/autoSyncedCaller3';
import type { TokenRepo } from '../repositories/TokenRepo';

const minInMillisec = 60 * 1000;
const dayInMillisec = 86_400_000;
const before60Days = new Date(
  (new Date()).getTime() - dayInMillisec * 60,
);
export class IotDevicesRelayDataListCore {
  constructor(
    public apiClient: APIClient,
    public tokenRepo: TokenRepo,
    public refreshTrigger = new BehaviorSubject<{
      laterThanDatetime: Date,
      beforeThanDatetime?: Date,
    }>({
      laterThanDatetime: before60Days,
    }),
  ) {
    makeAutoObservable(this, {
      apiClient: false,
      tokenRepo: false,
      refreshTrigger: false,
    });
  }

  iotDeviceRelaydataInner = computedFn2((
    args: {
      deviceId: IotDeviceIdT,
    },
  ) => {
    const userToken$Mobx = this
      .tokenRepo
      .userToken;
    if (userToken$Mobx.status !== 'success') {
      return userToken$Mobx;
    }
    const decodedToken = extractTokenData(
      userToken$Mobx.value,
    );
    const aa = createAutoSyncedCaller3(
      this
        .apiClient
        .iot
        .getIotRelayStatus
        .mutate,
      {
        // userId: decodedToken.userId,
        userToken: userToken$Mobx.value,
        // laterThanThisDate: this.refreshTrigger.value.laterThanDatetime,
        deviceId: args.deviceId,
      },
      this.refreshTrigger,
    );
    return asSuccessPromise(aa);
  });

  iotDeviceRelayData = computedFn2((
    args: {
      deviceId: IotDeviceIdT,
    },
  ) => {
    const innerResult = this
      .iotDeviceRelaydataInner(args);
    if (innerResult.status !== 'success') {
      return innerResult;
    }
    const result = innerResult
      .value
      .current()
      .value
      .value;
    if (!result) {
      debugger;
      return asFailedPromise({
        reason: 'getIotDeviceRelay_result_missing',
        result,
      });
    }
    if (result.status !== 'success') {
      return result;
    }
    const a = DeviceRelayNames.enum;

    const groupedByDevice = itiriri(result.value.relayStatus)
      .toGroups(
        (item) => item.deviceName as DeviceRelayNamesT,
      );
    // DeviceRelayNamesKeys.map((key) => {
    //   const deviceAlphabet = DeviceRelayNameConst[key];
    //   const deviceLog = groupedByDevice.get(deviceAlphabet);
    // });
    return asSuccessPromise({
      raw: result.value,
      groupedByDevice,
    });
  });

  // TODO: ...
  triggerListRefresh(

  ) {
    const prevValue = this.refreshTrigger.value;
    const laterThanDatetime = new Date(
      prevValue.laterThanDatetime.getTime() + minInMillisec * 10,
    );
    this.refreshTrigger.next({
      laterThanDatetime,
    });
  }
}
