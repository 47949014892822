import { makeAutoObservable } from 'mobx';

import type { IotDeviceIdT } from '^/__generated__/pothosZod/generated_scalars';
import {
  NotInitiatedPromiseStale, asFailedPromise, asSuccessPromise, getCurrentFreshness, type FreshnessType,
} from '^/types/__ResultType';
import { router, routes } from '^/userWeb/routes';
import type { AppContext } from '^/userWeb/types/AppContext';
import { createAutoDisposedInterval } from '^/userWeb/utils/AutoDisposedInterval';
import { flattenMobxRxResult } from '^/userWeb/utils/FlattenMobxRxResult';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';

import { SensorsDetailPopupViewModel } from './SensorsDetail.popup.viewmodel';

export class SensorsPageViewModel {
  constructor(
    public appContext: AppContext,
    public freshness: FreshnessType,
    public _selectedDeviceId: IotDeviceIdT | null,
    public selectedDeviceId = new MobxInputValue(
      _selectedDeviceId,
      (newValue, prevValue) => {
        const a = routes.sensors;
        debugger;
        routes.sensors({
          freshness,
          selectedDevice: newValue ?? undefined,
        }).push({
          // skipRender: true,
        });
        if (!newValue && prevValue) {
          window.location.reload();
          // this.freshness = getCurrentFreshness();
        }
        // this.freshness = getCurrentFreshness();
      }),
    // public expandDataArea = new MobxInputValue(false),
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }

  get iotDevices() {
    // return flattenMobxRxResult(
    //   this
    //     .appContext
    //     .iotDevicesListCore
    //     .iotDevices$Rx,
    // );
    return this
      .appContext
      .iotDevicesListCore
      .iotDevices;
  }

  get selectedDevice() {
    const deviceId = this.selectedDeviceId.value;
    debugger;
    if (!deviceId) {
      return NotInitiatedPromiseStale;
    }
    const devicesList = this
      .iotDevices;
    if (devicesList.status !== 'success') {
      return devicesList;
    }
    const selectedDevice = devicesList
      .value
      .find((d) => d.id === deviceId);
    if (!selectedDevice) {
      return asFailedPromise({
        reason: 'DeviceWithIdNotFoundInDeviceList',
        devicesList: devicesList.value,
        id: deviceId,
      });
    }
    return asSuccessPromise(selectedDevice);
  }

  get detailPopupVM() {
    if (this.selectedDevice.status !== 'success') {
      return this.selectedDevice;
    }
    return asSuccessPromise(new SensorsDetailPopupViewModel(
      this.appContext,
      this.selectedDevice.value,
    ));
  }
}
