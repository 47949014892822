import { css } from '@emotion/css';
import { IconUserFilled } from '@tabler/icons-react';

import { routes, type RouteNamesType } from '^/userWeb/routes';
import { borders } from '^/userWeb/styles/commonClasses';
import { LineColors } from '^/userWeb/styles/styleConstants';

import { ReactComponent as PersonIcon } from '../../Assets/Icons/person.svg';
import { ReactComponent as SensorsIcon } from '../../Assets/Icons/sensor.svg';
import { ReactComponent as ZoomInIcon } from '../../Assets/Icons/zoom-in.svg';

import { TabBarItem } from './TabBarItem';

export const AdminTabBar = (p: {
  routeName: RouteNamesType | false;
}) => {
  return (
    <div
      className={[
        borders.DEBUG_blueBorderDashed,
        css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;
          
        `,
      ].join(' ')}
    >
      <p>관리자 메뉴:</p>
      <TabBarItem
        isSelected={p.routeName === 'adminIotPage'}
        Icon={SensorsIcon}
        title="iot기기관리"
        onClick={() => {
          // alert('!');
          routes
            .adminIotPage()
            .push();
        }}
      />
      <TabBarItem
        isSelected={p.routeName === 'adminCamerasPage'}
        Icon={ZoomInIcon}
        title="카메라관리"
        onClick={() => {
          routes
            .adminCamerasPage()
            .push();
        }}
      />
      <TabBarItem
        isSelected={p.routeName === 'adminUsersPage'}
        Icon={PersonIcon}
        title="사용자관리"
        onClick={() => {
          routes
            .adminUsersPage()
            .push();
        }}
      />
    </div>
  );
};
