/** @jsx jsx */
import React from 'react';

import { css, cx } from '@emotion/css';

import { BackgroundColors } from '^/userWeb/styles/styleConstants';

import { SegmentedControlItem } from './SegmentedControlItem';

export const SegmentedControl: React.FC<{
  items: string[];
  selectedIndex: number;
  setSelectIndex: (nth: number) => void;
  rootCSS?: string;
}> = (props) => {
  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: row;
          background-color: ${BackgroundColors.gray_light};
          border-radius: 10px;
          padding: 4px;
        `,
        props.rootCSS,
      )}
    >
      {props.items.map((title, index) => {
        return (
          <SegmentedControlItem
            key={title}
            title={title}
            isSelected={props.selectedIndex === index}
            onClick={() => {
              props.setSelectIndex(index);
            }}
            rootCss={css`
              width: 100%;
            `}
          />
        );
      })}
    </div>
  );
};
