import {
  makeAutoObservable,
} from 'mobx';
import {
  BehaviorSubject,
} from 'rxjs';

import type { IotDeviceIdT } from '^/__generated__/pothosZod/generated_scalars';
import { FreshnessType, getCurrentFreshness } from '^/types/__ResultType';
import { AddIotDeviceFormViewModel } from '^/userWeb/Components/Forms/IotDeviceForm/AddIotDeviceForm.viewmodel';
import { UserSingleSelectListViewModel } from '^/userWeb/Components/Lists/UsersList/UserSingleSelectListViewModel';
import type { AppContext } from '^/userWeb/types/AppContext';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';

type VisibleFormKinds = {
  type: 'add';
} | {
  type: 'edit',
  deviceId: IotDeviceIdT,
};
export class AdminIotDevicePageViewModel {
  constructor(
    public appContext: AppContext,
    public refreshTrigger = new BehaviorSubject<FreshnessType>(
      getCurrentFreshness(),
    ),
    public visibleForm = new MobxInputValue<VisibleFormKinds | null>(null),
    public addDeviceFormVM = new AddIotDeviceFormViewModel(
      appContext,
    ),
    public userPickerVM = new UserSingleSelectListViewModel(
      appContext,
      addDeviceFormVM.baseFormVM.user$,
    ),
    public tempSearchTextVM = new MobxInputValue(''),
  ) {
    makeAutoObservable(this, {
      appContext: false,
      refreshTrigger: false,

    });
  }

  get iotDevicesList() {
    return this.appContext
      .iotDevicesListCore
      .iotDevices;
  }

  get editDeviceFormViewModel() {
    if (this.iotDevicesList.status !== 'success') {
      return null;
    }
    this.iotDevicesList.value
      .find(device => {
        
      })

  }
}
