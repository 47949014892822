import { makeAutoObservable } from 'mobx';

import { UserType } from '^/types/userTypes';
import { AppContext } from '^/userWeb/types/AppContext';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

export class LoadedMySettingsPageViewModel {
  constructor(
    public appContext: AppContext,
    public myInfo: UserType,
    public isCowAlarmOn = new MobxValue(
      myInfo.isCowAlarm,
      (changedValue) => {
        debugger;
        appContext.myInfoCore
          .updateMyInfo(myInfo.id, {
            isCowAlarm: changedValue,
          });
      },
    ),
    public isVisitorAlarmOn = new MobxValue(
      myInfo.isPersonAlarm,
      (changedValue) => {
        appContext.myInfoCore
          .updateMyInfo(myInfo.id, {
            isPersonAlarm: changedValue,
          });
      },
    ),
  ) {
    debugger;
    makeAutoObservable(this, {
      appContext: false,
    });
  }

  // TODO: move outside...?
  // handleVisitorChanged(newValue: boolean) {
  //   this.appContext.apiClient
  //     .mutation('user.updateUser', {
  //       isPersonAlarm: newValue,
  //     });
  // }
}
