import { makeAutoObservable } from 'mobx';

import type { FreshnessType } from '^/types/__ResultType';
import type { AppContext } from '^/userWeb/types/AppContext';

export class StatsPageViewModel {
  constructor(
    public appContext: AppContext,
    public freshness: FreshnessType,
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }
}
