import React from 'react';

import { css } from '@emotion/css';

import {
  ReactComponent as LoadingCircle,
} from '^/userWeb/Assets/LottieAnimations/loading_circle2.svg';
// import Lottie, {
//   Options as LottieOptions,
// } from 'react-lottie';

// import * as LoadingCircleAnim from '^/userWeb/Assets/LottieAnimations/loading_circle.json';

// const defaultOptions: LottieOptions = {
//   animationData: LoadingCircleAnim,
//   loop: true,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice',
//   },
// };
// export const LoadingCircle: React.FC<{
//   size?: number;
// }> = ({
//   size,
// }) => {
//   return (
//     <Lottie
//       options={defaultOptions}
//       height={size || 28}
//       width={size || 28}
//     />
//   );
// };

export const FilledLoadingCircle: React.FC<{
  // eslint-disable-next-line no-empty-pattern
}> = ({
}) => {
    return (
      <div
        className={css`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
      >
        <LoadingCircle />
      </div>
    );
  };
