import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import type { UserIDType, UserType } from '^/types/userTypes';
import { borders } from '^/userWeb/styles/commonClasses';

import { FilledLoadingCircle } from '../../StatusViews/LoadingCircle';
import { ViewByState } from '../../ViewByState';

import { UsersListItem } from './UserItemBasic';
import type { UserListViewModel } from './UsersList.viewmodel';

export const UserListDefaultStyles = {
  root: css`
    height: 100%;
    width: 100%;
    overflow: scroll;
  `,
} as const;

export const UserList = observer(function UserPicker(p: {
  viewmodel: UserListViewModel,
  customRenderer?: React.ComponentType<{
    user: UserType;
  }>,
  stylesOverride?: Partial<typeof UserListDefaultStyles>,
}) {
  const styles = {
    ...UserListDefaultStyles,
    ...p.stylesOverride,
  };
  return (
    <div
      id="usersList"
      className={[
        styles.root,
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
    >
      {/* <input
        type="text"
        value={p.viewmodel.filter.value}
        onChange={(e) => {
          p.viewmodel.filter.set(e.currentTarget.value);
        }}
      />
      <h2>{p.viewmodel.filteredList.status}</h2>
      <h2>
        {(p.viewmodel.filteredList.status === 'success'
          && p.viewmodel.filteredList.value.length) || null}
      </h2>
      <pre
        className={css`
          height: 100px;
          overflow: scroll;
        `}
      >
        {JSON.stringify(p.viewmodel.filteredList, null, 2)}
      </pre> */}
      <ViewByState
        data={p.viewmodel.filteredList}
        errorView={(e) => (
          <div>
            <h3> error: </h3>
            <pre>
              {JSON.stringify(e, null, 2)}
            </pre>
          </div>
        )}
        loadingView={<FilledLoadingCircle />}
      >
        {(users) => {
          if (p.customRenderer) {
            const CustomRenderer = p.customRenderer;
            return (
              <>
                {users.map((u) => (
                  <CustomRenderer
                    key={u.id}
                    user={u}
                  />
                ))}
              </>
            );
          }
          return (
            <>
              {users.map((u) => {
                return (
                  <UsersListItem
                    user={u}
                    key={u.id}
                  />
                );
              })}
            </>
          );
        }}
      </ViewByState>
    </div>
  );
});
