import { useEffect, useRef } from 'react';

import axios from 'axios';
import HLS from 'hls.js';

import { cameraIdT } from '^/__generated__/pothosZod/generated_scalars';
import { CameraIDType } from '^/types/cameraTypes';
import getConfig from '^/userWeb/config';
import { HTMLVideoProps } from '^/userWeb/types/HTMLVideoExtraProps';

const config = getConfig();

function getHLSUrls(camId: cameraIdT) {
  return config.hlsServers.map(
    (hlsServer) => `${hlsServer}/live/${camId}/cam.m3u8`,
    // (hlsServer) => `${hlsServer}/rtsp_stream/${camId}/channel/0/hls/live/index.m3u8`,
  );
}
export const HLSPlayer: React.FC<{
  camId: CameraIDType,
} & HTMLVideoProps> = ({
  camId,
  ...vidProps
}) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    useEffect(() => {
      let hls: HLS | null = null;
      const videoCurrent = videoRef.current;
      if (videoCurrent && HLS.isSupported()) {
        hls = new HLS();
        // GET /stream/{STREAM_ID}/channel/{CHANNEL_ID}/hlsll/live/index.m3u8
        // hls.loadSource('https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8');
        // hls.loadSource('https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8');
        // hls.loadSource(`/rtsp_stream/${camId}/channel/0/hlsll/live/index.m3u8`);
        const sources = getHLSUrls(camId);
        // eslint-disable-next-line no-inner-declarations
        async function run() {
          if (!videoCurrent) return;
          try {
            const validHlsSource = await Promise.any(sources.map(async (url) => {
              const result = await axios.get(url);
              console.log('hls: ', result);
              return url;
            }));
            // sources.forEach((hlsSource) => hls?.loadSource(hlsSource));
            hls?.loadSource(validHlsSource);
            hls?.attachMedia(videoCurrent);

            await videoCurrent.play();
          } catch (e) {
            console.error('videoCurrent.play() failed: ', e);
          }
        }
        run();
      }
      return () => {
        // videoCurrent?.pause();
        hls?.stopLoad();
        hls?.destroy();
      };
    }, [camId]);
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        {...vidProps}
        muted
        playsInline
        autoPlay
        ref={videoRef}
      >
        {getHLSUrls(camId).map((hlsUrl) => {
          return (
            <source
              src={hlsUrl}
              type="application/x-mpegURL"
            />
          );
        })}

      </video>
    );
  };
