import type {
  IterableQuery,
} from 'itiriri';

export function isDefined<T>(t: T | undefined | null): t is T {
  return t !== undefined && t !== null;
}

export function filterNotDefineds<T>(
  iterableQuery: IterableQuery<T | null | undefined>,
) {
  return iterableQuery.filter(isDefined) as IterableQuery<T>;
}

export function assertTruthy(
  value: any,
  message?: string,
) {
  if (!value) {
    throw new Error(`
      Assertion is not true: got ${value}
      ${message},
    `);
  }
}

export function normalizeValue(
  min: number,
  max: number,
  value: number,
) {
  return Math.max(
    min,
    Math.min(max, value),
  );
}

export function transformNumberRange(
  oldFrom: number,
  oldTo: number,
  newFrom: number,
  newTo: number,
  oldValue: number,
) {
  const from0Value = oldValue - oldFrom;
  const scaledValue = from0Value * (
    (newTo - newFrom) / (oldTo - oldFrom)
  );
  return scaledValue + newFrom;
}

export function returnAndLog<T>(name: string, data: T) {
  // eslint-disable-next-line no-console
  console.log(name, data);
  return data;
}
