import { observer } from 'mobx-react-lite';

import type { StatsPageViewModel } from './StatsPage.viewmodel';

export const StatsPage = observer(function StatsPage(props: {
  viewmodel: StatsPageViewModel,
}) {
  return (
    <div>
      <h1> 통계 폐이지 </h1>
      <h3> 준비 중 입니다</h3>
    </div>
  );
});
