import { makeAutoObservable } from 'mobx';

import {
  asSuccessPromise, FreshnessType, getCurrentFreshness, mergeSuccessResult,
} from '^/types/__ResultType';
import { AppContext } from '^/userWeb/types/AppContext';

import { DetectedVisitorsSlidesViewModel } from './DetectedVisitorsSlides/DetectedVisitorsSlides.viewmodel';
import { LiveCamsSliderAreaViewModel } from './LiveCamsSliderArea/LiveCamsSliderArea.viewmodel';
import { RecentCowMountSlidesViewModel } from './RecentCowMountSlides/RecentCowMountSlides.viewmodel';

export class MainPageViewModel {
  constructor(
    public appContext: AppContext,
    public freshness: FreshnessType = getCurrentFreshness(),
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }

  get userToken() {
    return this.appContext.tokenRepo.userToken;
  }

  get myInfo() {
    if (this.userToken.status !== 'success') {
      return this.userToken;
    }
    const myInfoResult = this.appContext
      .myInfoCore
      .myInfo(this.userToken.value, this.freshness);

    return mergeSuccessResult(
      myInfoResult, this.userToken.value,
    );
  }

  get liveCamsViewModel() {
    if (this.myInfo.status !== 'success') {
      return this.myInfo;
    }
    return asSuccessPromise(
      new LiveCamsSliderAreaViewModel(
        this.appContext,
        this.myInfo.value[1],
        this.myInfo.value[0].id,
        this.freshness,
      ),
      this.freshness,
    );
  }

  get recentCowMountHistoryViewModel() {
    return new RecentCowMountSlidesViewModel(
      this.appContext,
      this.freshness,
    );
  }

  get detectedVisitorsViewModel() {
    return new DetectedVisitorsSlidesViewModel(
      this.appContext,
      this.freshness,
    );
  }
}
