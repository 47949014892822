import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';

import { IWithDispose, registerForDispose } from './withDisposeClass';

class AutoDisposedInterval implements IWithDispose {
  constructor(
    public runFn: () => void,
    public intervalMs: number,
    public _debugName: string,
    public interval: ReturnType<typeof setInterval> | null = null,
  ) {
    this.interval = setInterval(runFn, this.intervalMs);
    makeAutoObservable(this);
  }

  dispose(): void {
    // eslint-disable-next-line no-underscore-dangle
    // alert('disposing!');
    // toast(`disposing AutoDisposedInterval:${this._debugName}`);
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}

export function createAutoDisposedInterval(
  runFn: () => void,
  intervalMs: number,
  _debugName: string = 'AutoDisposedInterval::NO_NAME',
) {
  return registerForDispose(
    new AutoDisposedInterval(
      runFn,
      intervalMs,
      _debugName,
    ),
  );
}
