import fuzzysort from 'fuzzysort';
import { disassembleToString } from 'hangul-js';
import itiriri from 'itiriri';
import { makeAutoObservable } from 'mobx';

import { asSuccessPromise, type FreshnessType } from '^/types/__ResultType';
import { CameraIDType } from '^/types/cameraTypes';
import type { AppContext } from '^/userWeb/types/AppContext';
import { filterNotDefineds } from '^/utils';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';

export class AdminCamerasPageViewmodel {
  constructor(
    public appContext: AppContext,
    public freshness: FreshnessType,
    public filter = new MobxInputValue(''),
    public selectedCam = new MobxInputValue<CameraIDType | null>(null),
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }

  get filteredAndGroupedCameras2() {
    const userToken$R = this.appContext.tokenRepo.userTokenWithData;
    if (userToken$R.status !== 'success') {
      return userToken$R;
    }
    return this.appContext.liveCamsListCore.getFilteredAndGroupedCameras(
      {
        userId: userToken$R.value.tokenData.userId,
        userToken: userToken$R.value.token,
      },
      this.filter.value,
      this.freshness,
    );
  }
}
