/** @jsx jsx */
import React from 'react';

import { css, cx } from '@emotion/css';

import { FontSizes, FontWeights, TextColors } from '../styles/styleConstants';

export const VideoSectionHeader: React.FC<{
  title: string;
  onClickShowAllButton?: () => void;
  rootCSS?: string;
}> = (props) => {
  return (
    <div
      className={
        cx(
          css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-left: 20px;
            padding-right: 20px;
            height: 40px;
            align-items: center;
          `,
          props.rootCSS,
        )
    }
    >
      <p
        className={css`
          color: ${TextColors.black};
          font-size: ${FontSizes.lg};
          font-weight: ${FontWeights.bold};
          margin: 0px;
        `}
      >{props.title}
      </p>
      <button
        type="button"
        onClick={props.onClickShowAllButton}
        className={css`
          background-color: transparent;
          color: ${TextColors.black_light};
          font-size: ${FontSizes.base};
          font-weight: ${FontWeights.regular};
          margin: 0px;
          height: 100%;
          padding-left: 8px;
          padding-right: 8px;
          border: none;
          &:active{
            opacity: 50%;
          }
        `}
      >전체보기
      </button>
    </div>
  );
};
