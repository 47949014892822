import { useEffect, useLayoutEffect } from 'react';

export const useLifecycles = (
  mount: () => void,
  unmount: () => void,
) => {
  useLayoutEffect(() => {
    if (mount) mount();
    return () => {
      if (unmount) unmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
