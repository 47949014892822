/* eslint-disable @typescript-eslint/naming-convention */
import fuzzysort from 'fuzzysort';
import { disassembleToString } from 'hangul-js';
import itiriri from 'itiriri';
import { makeAutoObservable, runInAction } from 'mobx';

import {
  asSuccessPromise, ExtractFailures, FreshnessType, NotInitiatedPromiseStale, PromiseResultType,
} from '^/types/__ResultType';
import { CameraIDType, CameraType } from '^/types/cameraTypes';
import { UserIDType, UserTokenType } from '^/types/userTypes';
import { filterNotDefineds } from '^/utils';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';

import { APIClient } from '../apiClient';
import { GetCameraListAPIFailResult } from '../apiTypes/getCamerasList';
import { LiveCamerasRepo } from '../repositories/LiveCamerasRepo';

// import sanitizeFileName from 'sanitize-filename';

export type LiveCamsFilterType = {
  userId: UserIDType,
  userToken: UserTokenType,
};

type __SERIALIZED_LiveCamsFilter = string & {
  __SERIALIZED_LiveCamsFilterType: null;
};

function serializeLiveCamsFilter(
  filter: LiveCamsFilterType,
) {
  // TODO: filter/sort options?
  return JSON.stringify(filter) as __SERIALIZED_LiveCamsFilter;
}

export class LiveCamerasListCore {
  constructor(
    public liveCamsRepo: LiveCamerasRepo,
    public apiClient: APIClient,
    private camIDsByFilter: Record<
      __SERIALIZED_LiveCamsFilter,
      PromiseResultType<CameraIDType[], ExtractFailures<GetCameraListAPIFailResult>>
    > = {},
  ) {
    makeAutoObservable(this, {
      apiClient: false,
    });
  }

  getLiveCams = computedFn2((
    filter: LiveCamsFilterType,
    freshness: FreshnessType,
  ) => {
    const filterKey = serializeLiveCamsFilter(filter);
    this.fetchLiveCams(
      filter, freshness,
    );
    const camIDsByFilter = this.camIDsByFilter[filterKey] || null;
    if (!camIDsByFilter) {
      return NotInitiatedPromiseStale;
    }
    if (camIDsByFilter.status !== 'success') {
      return camIDsByFilter;
    }
    return asSuccessPromise(
      camIDsByFilter.value.map(
        (camId) => this
          .liveCamsRepo
          .getCamById(camId),
      ),
    );
  });
  // getLiveCams = computedFn2((filter: ))

  fetchLiveCams = computedFn2(async (
    filter: LiveCamsFilterType,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    freshness: FreshnessType,
    // eslint-disable-next-line consistent-return
  ) => {
    const fetchedData = await this.apiClient
      .camera
      .getCamerasList
      .query({
        userId: filter.userId,
        userToken: filter.userToken,
      },
      );
    const cacheKey = serializeLiveCamsFilter(filter);
    if (fetchedData.status === 'failure') {
      this.camIDsByFilter[cacheKey] = fetchedData;
      return null;
    }
    const ids = fetchedData
      .value
      .map((cam) => cam.id as CameraIDType);
    runInAction(() => {
      this.camIDsByFilter[cacheKey] = asSuccessPromise(ids);
      fetchedData.value
        .forEach((cam) => {
          // TODO fix this
          //  quick test, see if it's working with the cameraIp
          // if (!cam.processedBy?.includes("jupiter") && cam.phone ==="01041400320"){
          //   this.liveCamsRepo.setCamById(
          //     sanitizeFileName(cam.cameraIp) as CameraIDType,
          //     asSuccessPromise(cam as CameraType),
          //   );
          // }else{
          this.liveCamsRepo.setCamById(
            cam.id as CameraIDType,
            asSuccessPromise(cam as CameraType),
          );
          // }
        });
    });
  });

  fuzzsort = computedFn2((
    filter: LiveCamsFilterType,
    freshness: FreshnessType,
  ) => {
    const cams = this.getLiveCams(filter, freshness);
    if (cams.status !== 'success') {
      return cams;
    }
    const prepared = filterNotDefineds(
      itiriri(cams.value)
        .map((c) => {
          if (c.status !== 'success') {
            return null;
          }
          return c.value;
        }),
    )
      .map((cam) => {
        const name = fuzzysort.prepare(
          disassembleToString(cam.name));
        const phone = fuzzysort.prepare(
          cam.phone,
        );
        const cameraIp = fuzzysort.prepare(
          cam.cameraIp,
        );
        return {
          cam,
          name,
          phone,
          cameraIp,
        };
      });
    return asSuccessPromise(prepared);
  });

  getFilteredCameras = computedFn2((
    filter: LiveCamsFilterType,
    text: string,
    freshness: FreshnessType,
  ) => {
    const fuzzsort = this.fuzzsort(filter, freshness);
    if (fuzzsort.status !== 'success') {
      return fuzzsort;
    }
    const searchResults = fuzzysort.go(
      disassembleToString(text),
      fuzzsort.value.toArray(),
      {
        keys: [
          'name',
          'phone',
          'cameraIp',
        ],
        all: true,
      },
    );

    return asSuccessPromise(searchResults);
  });

  getFilteredAndGroupedCameras = computedFn2((
    filter: LiveCamsFilterType,
    text: string,
    freshness: FreshnessType,
  ) => {
    const filteredCameras = this.getFilteredCameras(
      filter, text, freshness,
    );

    if (filteredCameras.status !== 'success') {
      return filteredCameras;
    }
    const grouped = itiriri(filteredCameras.value)
      .groupBy((c) => c.obj.cam.phone)
      .sort((
        a,
        b,
      ) => {
        const maxItemA = a[1]
          .max((q) => q?.score || 0)
          ?.score || 0;
        const maxItemB = b[1]
          .max((q) => q?.score || 0)
          ?.score || 0;
        return maxItemA < maxItemB ? 1 : -1;
      });
    return asSuccessPromise(grouped);
  });
}
