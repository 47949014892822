import React, { FC, PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { formatDate as dateFormat } from 'date-fns/format';

import { MobxValue } from '^/utils/mobx-utils/MobxValue';

import {
  BackgroundColors, FontSizes, LineColors, TextColors,
} from '../../styles/styleConstants';
import { BaseButtonStyle } from '../Buttons/BaseButtonStyle';
import { HorizontalDivider } from '../Dividers/HorizontalDividers';

const TitleTextView = css`
  background-color : transparent;
  color: ${TextColors.black_lighter};
  flex-grow: 1;
  margin: 0px;
  padding: 12px 16px 16px 12px;
  font-size: ${FontSizes.base};
`;

const ItemTextView = cx(
  TitleTextView,
  css`
    color: ${TextColors.black};
    flex-grow: 1;

    &:active {
      color: ${TextColors.black_lighter};
    }
  `,
);

type PropsType = React.HTMLProps<HTMLDivElement> & {
  name: string;
  date: MobxValue<Date | null>;
  rootCSS?: string;
  childCSS?: string;
};

export const DatePicker: FC<PropsWithChildren<PropsType>> = ({
  name,
  date,
  rootCSS,
  childCSS,
  children,
  ...divProps
}) => {
  return (
    <div
      {...divProps}
      className={cx(
        css`
          display: flex;
        `,
        rootCSS,
      )}

    >
      <button
        className={cx(
          BaseButtonStyle,
          css`
            display: flex;
            background-color: ${BackgroundColors.white};
            flex-direction: row;
            padding: 0px;
            height: 100%;
            border-radius: 8px;
            border: 1px ${LineColors.gray_light} solid;
          `,
          childCSS,
        )}
        type="button"
      >
        <p
          className={cx(
            TitleTextView,
            css`
              flex-grow: 1;
              white-space: nowrap;
            `,
          )}
        >{name}
        </p>
        <div
          className={cx(
            HorizontalDivider,
            css`
              height: 28px;
            `,
          )}
        >
        </div>
        <input
          type="date"
          value={(date.value && (
            dateFormat(date.value, 'uuuu-MM-dd')
          )) || undefined}
          onChange={(e) => date
            .setValue(new Date(e.currentTarget.value))}
          className={cx(
            ItemTextView,
            css`
              flex-grow: 1;
              white-space: nowrap;
              outline: none;
              box-shadow: none;
              border: none;
              :focus {
                outline: none;
              }
            `,
          )}
        />
      </button>
      {children}
    </div>
  );
};
