/** @jsx jsx */
import React from 'react';

import {
  css, cx,
} from '@emotion/css';

import { ReactComponent as ChevronDownIcon } from '../Assets/Icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '../Assets/Icons/chevron-up.svg';
import {
  BackgroundColors, FontSizes, FontWeights, IconColors, TextColors,
} from '../styles/styleConstants';

import { VerticalDivider } from './Dividers/VerticalDivider';

export const AnnouncementCell: React.FC<{
  date: string;
  title: string;
  content: string;
  isFolded: boolean;
}> = (props) => {
  function chevronIcon() {
    if (props.isFolded) {
      return <ChevronDownIcon width={16} height={16} fill={IconColors.gray} />;
    }
    return <ChevronUpIcon width={16} height={16} fill={IconColors.gray} />;
  }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 16px;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          gap: 12px;
        `}
      >
        <p
          className={css`
            color: ${TextColors.black_light};
            font-size: ${FontSizes.base};
            font-weight: ${FontWeights.regular};
            margin: 0px;
            flex-grow: 1;
          `}
        >{props.date}
        </p>
        {chevronIcon()}
      </div>
      <p
        className={css`
          color: ${TextColors.black};
          font-size: ${FontSizes.lg};
          font-weight: ${FontWeights.bold};
          margin: 0px;
        `}
      >{props.title}
      </p>
      <div
        className={css`
          background-color: ${BackgroundColors.gray_light};
          border-radius: 6px;
          padding-top: 16px;
          padding-left: 12px;
          padding-right: 12px;
          padding-bottom: 16px;
          margin-top: 12px;
        `}
      >
        {props.content}
      </div>
      <div
        className={cx(
          VerticalDivider,
          css`
            margin-top: 16px;
          `,
        )}
      >
      </div>
    </div>
  );
};
