import { pushPubKey } from '^/pushConfig/forClient';
import { PushEndpointUrl, UserIDType, UserTokenType } from '^/types/userTypes';

import { APIClient } from './apiClient';
import { registeredServiceWorker } from './registeredServiceWorker';

export async function registerServiceWorkerAndPush(
  userId: UserIDType,
  userToken: UserTokenType,
  apiClient: APIClient,
) {
  try {
    const reg2 = await registeredServiceWorker;
    const serviceWorkerReg = reg2;
    const sub = await reg2?.pushManager?.getSubscription();
    if (!sub && serviceWorkerReg) {
      const serviceWorker = serviceWorkerReg.active
        || serviceWorkerReg.waiting
        || serviceWorkerReg.installing;

      if (!serviceWorker) {
        return;
      }
      await Notification.requestPermission();
      await navigator.serviceWorker?.ready;

      const newSub = await serviceWorkerReg.pushManager?.subscribe({
        userVisibleOnly: true,
        applicationServerKey: pushPubKey,
      });
      if (!newSub) {
        return;
      }
      const subscriptionJson = newSub.toJSON();
      await apiClient
        .addPushSubscription
        .mutate({
          userId,
          userToken,
          pushEndpointUrl: newSub.endpoint as PushEndpointUrl,
          pushKeys: subscriptionJson.keys,
        });
      newSub.getKey('auth');
      newSub.getKey('p256dh');
      newSub.toJSON();
      // ...
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      // const permission = await askPermission();
    } else {
      debugger;
    }
  } catch (e) {
    console.error(e);
  }
}
