import { makeAutoObservable } from 'mobx';

import {
  asSuccessPromise,
  getCurrentFreshness,
} from '^/types/__ResultType';
import type { AppContext } from '^/userWeb/types/AppContext';

import { LoadedMySettingsPageViewModel } from './LoadedMySettingsPage.viewmodel';

export class MySettingsPageViewModel {
  constructor(
    public appContext: AppContext,
    public freshness = getCurrentFreshness(),
  ) {
    makeAutoObservable(this, {
      appContext: false,
    });
  }

  get userToken() {
    return this.appContext.tokenRepo.userToken;
  }

  get myInfo() {
    if (this.userToken.status !== 'success') {
      return this.userToken;
    }
    const myInfoResult = this.appContext.myInfoCore
      .myInfo(this.userToken.value, this.freshness);
    return myInfoResult;
  }

  get loadedViewModel() {
    if (this.myInfo.status !== 'success') {
      return this.myInfo;
    }
    debugger;
    return asSuccessPromise(
      new LoadedMySettingsPageViewModel(
        this.appContext,
        this.myInfo.value,
      ),
    );
  }
}
