export const AccentColors = {
  blue: '#215DE8',
  blue50: '#215DE880',
  pink: '#EA4C89',
  gray: '#C9CACE',
  yellow: '#FFBC11',
  red: '#FF3E3E',
  green: '#34C759',
} as const;

export const BackgroundColors = {
  black: '#212529',
  white: '#FFFFFF',
  gray_light: '#F3F3F4',
} as const;

export const TextColors = {
  black: '#212529',
  black_light: '#788088',
  black_lighter: '#B1B7BC',
  black_lightest: '#C7CED3',
  gray_light: '#E0E1E6',
  white: '#FFFFFF',
  white50: '#ffffff80',
} as const;

export const LineColors = {
  black: '#000000',
  gray_light: '#E0E1E6',
} as const;

export const IconColors = {
  black: '#212529',
  black_light: '#68737D',
  gray: '#AFB2B2',
  white: '#FFFFFF',
} as const;

export const FontSizes = {
  xs: '10px',
  sm: '12px',
  base: '14px',
  lg: '16px',
  xl: '18px',
  xxl: '20px',
  xxxl: '24px',
} as const;

export const FontWeights = {
  light: '100',
  demiLight: '300',
  regular: '400',
  medium: '500',
  bold: '700',
  black: '900',
} as const;
