import React from 'react';

import { css, cx } from '@emotion/css';

import { RouteNamesType, routes } from '^/userWeb/routes';
import { BackgroundColors, LineColors } from '^/userWeb/styles/styleConstants';

import { ReactComponent as HomeIcon } from '../../Assets/Icons/home.svg';
import { ReactComponent as PersonIcon } from '../../Assets/Icons/person.svg';
import { ReactComponent as SensorIcon } from '../../Assets/Icons/sensor.svg';
import { ReactComponent as StatisticsIcon } from '../../Assets/Icons/statistics.svg';
import { VerticalDivider } from '../Dividers/VerticalDivider';

import { TabBarItem } from './TabBarItem';

export const TabBar: React.FC<{
  routeName: RouteNamesType | false;
  rootCSS?: string;
}> = ({
  routeName,
  rootCSS,
}) => {
    return (
      <div
        className={cx(
          css`
          display: flex;
          flex-direction: column;
          background-color: ${BackgroundColors.white};
          width: 100%;
        `,
          rootCSS,
        )}
      >
        <div
          className={cx(
            VerticalDivider,
            css`
            width: 100%;
            height: 1px;
            background-color: ${LineColors.gray_light};
          `,
          )}
        />
        <div
          className={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: ${BackgroundColors.white};
        `}
        >
          <TabBarItem
            Icon={HomeIcon}
            title="메인"
            isSelected={routeName === 'main'}
            onClick={() => {
              routes.main({}).push();
            }}
          />
          <TabBarItem
            Icon={SensorIcon}
            title="센서"
            isSelected={routeName === 'sensors'}
            onClick={() => routes.sensors({}).push()}
          />
          <TabBarItem
            Icon={StatisticsIcon}
            title="통계"
            isSelected={routeName === 'stats'}
            onClick={() => routes.stats({}).push()}
          />
          <TabBarItem
            Icon={PersonIcon}
            title="내 프로필"
            isSelected={routeName === 'myPage'}
            onClick={() => routes.myPage({}).push()}
          />
        </div>
      </div>
    );
  };
