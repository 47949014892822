import {
  createRouter, defineRoute,
  param,
} from 'type-route';

import {
  DetectionTypeParam,
  FreshnessParam,
  IotDeviceIdParam,
} from '^/types/RouteParamTypes';

export const router = createRouter({
  // session: {
  //   type: 'browser',
  // },
  // baseUrl: '/web',
}, {
  main2: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => '/oldmain'),

  login: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
    redirectUrl: param.query.optional.string,
  }, () => '/login'),

  sensors: defineRoute({
    selectedDevice: param.query.optional.ofType(IotDeviceIdParam),
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => '/sensors'),

  stats: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => '/stats'),

  myPage: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => '/my_page'),

  notification: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => '/notification'),

  notificationSetting: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => '/notification_setting'),

  visitorHistory: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
    fullscreenNth: param.query.optional.number,
  }, () => '/visitor_history'),

  cowMountingHistory: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
    detectionType: param.query.optional.ofType(DetectionTypeParam),
    fullscreenNth: param.query.optional.number,
  }, () => '/cow_mounting_history'),

  cameraPage: defineRoute({
    // ///
  }, () => '/camera'),

  main: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => ['/main', '/']),

  // adminPages
  adminMain: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => '/adminaa'),

  adminIotPage: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => '/admin/iotdevices'),

  adminCamerasPage: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => '/admin/cameras'),

  adminUsersPage: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),
  }, () => '/admin/users'),
});

export const { routes, useRoute } = router;
export type RouteNamesType = keyof (typeof routes);
