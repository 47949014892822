import { css, cx } from '@emotion/css';

import { Divider } from './Divider';

export const HorizontalDivider = cx(
  Divider,
  css`
    width: 1px;
    height: 100%;
  `,
);
