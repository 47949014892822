import {
  defer, from, Observable, Subject, Subscription,
  debounceTime,
  shareReplay,
  ReplaySubject,
} from 'rxjs';
import {
  switchMap, tap, catchError, share, startWith,
} from 'rxjs/operators';

import type { ExtractObservable } from '^/types/__utils';

const noLastArg = Symbol('noLastArg');

export type RefreshingAPITriggerItemType<TArgs extends {}, T> = TArgs & {
  $callback?: (result: T) => void,
};
export function refreshingAPICall$<TArgs extends {}, T>(
  apiFunction: (args: TArgs) => Promise<T>,
) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return function _refreshingAPICall$(
    refreshTrigger: Observable<RefreshingAPITriggerItemType<TArgs, T>>,
  ): Observable<T> {
    let lastArgs: RefreshingAPITriggerItemType<TArgs, T> | typeof noLastArg = noLastArg;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
    const _ret2 = refreshTrigger
      .pipe(
        debounceTime(100),
        tap((args) => {
          console.log('updating lastArgs: ', args);
          lastArgs = args;
        }),
      );
    let subscription: Subscription | undefined;
    return defer(() => {
      const subject = new ReplaySubject<T>(1);
      console.log('!!!!! creating new defer!');

      const a = lastArgs === noLastArg
        ? _ret2
        : _ret2.pipe(startWith(lastArgs));

      subscription = a.pipe(
        // after re-subscription, add initial
        switchMap((args) => {
          return from(apiFunction(args))
            .pipe(
              shareReplay(2),
              tap((apiResult) => {
                subject.next(apiResult);
                if (args.$callback) {
                  args.$callback(apiResult);
                }
              }),
            );
        }),
      ).subscribe();

      return subject.asObservable()
        .pipe(
          catchError((error) => {
            // Handle errors appropriately, potentially retrying or logging
            console.error('API call error:', error);
            throw error; // Rethrow to allow further error handling downstream
          }),
        );
    }).pipe(
      share({
        // resetOnRefCountZero() {
        //   console.log('unsubscribe!');
        //   subscription?.unsubscribe();
        //   return [];
        // },
        // resetOnComplete() {

        // }
      }),
    );
  };
}

export type ExtractRefreshTriggerItem<
  TFunc,
> = TFunc extends ReturnType<typeof refreshingAPICall$<any, any>> ?
  ExtractObservable<Parameters<TFunc>[0]>
  : never;

export async function sampleAPI(args: {
  a: number,
  message?: string,
}) {
  return {
    aaa: args.a,
    message: args.message,
    randValue: Math.random(),
  } as const;
}
