import { makeAutoObservable } from 'mobx';

import { LoadingPromise, NotInitiatedPromiseStale, SuccessPromise } from '^/types/__ResultType';
import type { DetectedVisitorType, DetectedVisitorIdType } from '^/types/DetectedVisitorType';
import { computedFn2 } from '^/utils/mobx-utils/mobxComputedFn2';

import type { GetCarPersonDetectionsListFailResult } from '../apiTypes/getDetectedVisitorsList';

export type DetectedVisitorsResultType =
  | GetCarPersonDetectionsListFailResult
  | LoadingPromise
  | SuccessPromise<DetectedVisitorType>;

export class DetectedVisitorsRepo {
  constructor(
    public visitorsDetected: Record<
    DetectedVisitorIdType, DetectedVisitorsResultType
    > = {},
  ) {
    makeAutoObservable(this);
  }

  get = computedFn2((
    id: DetectedVisitorIdType,
  ) => {
    return this.visitorsDetected[id] || NotInitiatedPromiseStale;
  });

  set(
    id: DetectedVisitorIdType,
    detected: DetectedVisitorsResultType,
  ) {
    this.visitorsDetected[id] = detected;
  }
}
