import { css } from '@emotion/css';
import { formatISO9075 } from 'date-fns/formatISO9075';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

import { FullScreenSwiper } from '^/userWeb/Components/FullScreenSwiper/FullScreenSwiper';
import { SmallVideoThumbnailCell } from '^/userWeb/Components/SmallVideoThumbnailCell';
import { ViewByState } from '^/userWeb/Components/ViewByState';
import { useInstance } from '^/userWeb/hooks/useInstance';
import { portalDOM } from '^/userWeb/Portal';
import type { DetectedVisitorsResultType } from '^/userWeb/repositories/DetectedVistorsRepo';
import { router } from '^/userWeb/routes';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';
import { borders } from '^/userWeb/styles/commonClasses';

export const DetectedVisitorsPageLoaded = observer(function DetectedVisitorsPageLoaded(
  props: {
    detectedVisitors: DetectedVisitorsResultType[],
    fullScreenNth: MobxInputValue<number | null>,
  },
) {
  const isFullScreenInteractable = useInstance(
    () => new MobxInputValue(false),
  );
  if (props.fullScreenNth.value !== null) {
    // TODO: fullscreen
    return createPortal(
      <FullScreenSwiper
        isSwiperDisabled={isFullScreenInteractable.current}
        items={props.detectedVisitors}
        slideIndex={props.fullScreenNth.value}
        setSlideIndex={(newIdx) => {
          console.log('DEBUG__setSlideIndex!', newIdx);
          props.fullScreenNth.set(newIdx);
        }}
        closeFullScreen={() => {
          // fullScreenSliderIndex.set(null);
          router.session.back();
        }}
        preloadPrevCount={2}
        preloadNextCount={2}
        fixedLayerRenderFunc={(
          item, nth,
        ) => (
          <ViewByState
            data={item}
            errorView={<div />}
            loadingView={<div />}
          >
            {(value) => {
              return (
                <>
                  <div
                    className={[
                      css`
                        position: absolute;
                        top: 0;
                        right: 20px;
                        /* background-color: grey; */
                        opacity: 1;
                        color: white;
                        z-index: 200;
                        font-size: 20px;
                        height: 20px;
                        text-align: right;
                        text-shadow: 0px 0px 10px black;
                      `,
                    ].join(' ')}
                  >
                    {formatISO9075(value.createdAt)} <br />
                    카메라 {value.cameraName} <br />
                    방문자기록 {nth + 1} / {props.detectedVisitors.length}
                  </div>
                </>
              );
            }}
          </ViewByState>
        )}
        renderFunc={(
          detectedVisitor,
          nth,
        ) => (
          <ViewByState
            data={detectedVisitor}
            errorView={<div> error </div>}
            loadingView={<div> loading </div>}
          >
            {(value) => {
              if (value.videoUrl?.endsWith('.svg')) {
                return (
                  <div
                    className={[
                      // borders.DEBUG_redBorderDashed,
                      css`
                        width: 100%;
                        height: 100%;
                        position: relative;
                      `,
                    ].join(' ')}
                  >
                    <object
                      aria-label={`camera: ${value.cameraName}`}
                      id="SVGViewer1"
                      data={value.videoUrl}
                      type="image/svg+xml"
                      className={[
                        css`
                          width: 100%;
                          height: 100%;
                          pointer-events: none;
                        `,
                        borders.DEBUG_purpleBorderDashed,
                      ].join(' ')}
                    >
                    </object>
                  </div>
                );
              }
              return (
                <div
                  className={[
                    // borders.DEBUG_redBorderDashed,
                    css`
                      width: 100%;
                      height: 100%;
                      position: relative;
                    `,
                  ].join(' ')}
                >
                  <img
                    alt="testimage"
                    className={[
                      css`
                        /* position: absolute; */
                        /* top: 0; left:0; right:0; bottom: 0; */
                        width: 100%; height: 100%;
                        /* transform: translateY(calc({dimension.height * 0.5}px - 50%)) translateX(calc({dimension.width * 0.5}px - 50%)) rotate(90deg); */
                        background-color: aqua;
                      `,
                      // borders.DEBUG_greenBorderDashed,
                    ].join(' ')}
                    // style={{
                    //   width: dimension.height,
                    //   height: dimension.width,
                    // }}
                    src={value.videoUrl}
                  />
                </div>
              );
            }}
          </ViewByState>

        )}
      />,
      portalDOM,
    );
  }
  return (
    <>
      {props.detectedVisitors.map((item, nth) => {
        if (item.status === 'failure') {
          return (
            <SmallVideoThumbnailCell
              videoSrc=""
              date="에러"
            />
          );
        }
        if (item.status === 'loading') {
          return (
            <SmallVideoThumbnailCell
              videoSrc=""
              date="로딩중..."
            />
          );
        }
        return (
          <SmallVideoThumbnailCell
            videoSrc={item.value.videoUrl}
            date={formatISO9075(new Date(item.value.createdAt))}
            onDoubleClick={() => {
              props.fullScreenNth.set(nth);
            }}
          />
        );
      })}
    </>
  );
});
