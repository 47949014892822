import { makeAutoObservable } from 'mobx';

import { registerServiceWorkerAndPush } from '^/userWeb/push';
import { router, routes } from '^/userWeb/routes';
import { AppContext } from '^/userWeb/types/AppContext';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';
import { extractTokenData } from '^/utils/token/forClient';

export class LoginPageViewModel {
  constructor(
    public appContext: AppContext,
    public redirectURL?: string,
    public username = new MobxInputValue(''),
    public password = new MobxInputValue(''),
    public showPassword = new MobxValue(false),
  ) {
    if (appContext.tokenRepo.userToken.status === 'success') {
      setTimeout(() => router
        .routes.main()
        .push(),
      );
    }
    makeAutoObservable(this, {
      appContext: false,
    });
  }

  // eslint-disable-next-line consistent-return
  async login() {
    const result = await this.appContext
      .myInfoCore
      .login({
        phone: this.username.value,
        password: this.password.value,
      });
    // const result = await this.appContext.apiClient.query('user.login', {
    //   phone: this.username.value,
    //   password: this.password.value,
    // });
    if (result.status === 'success') {
      const tokenData = extractTokenData(result.value);
      if (!tokenData) {
        alert('no token data');
        return null;
      }
      registerServiceWorkerAndPush(
        tokenData.userId,
        result.value,
        this.appContext.apiClient,
      );

      if (tokenData.userKind === 'admin') {
        debugger;
        return routes.adminMain().push();
      }
      if (this.redirectURL) {
        return router.session.replace(this.redirectURL);
      }

      return routes.main().push();
    }
    alert(`login error:${result.failure.reason}`); // FIXME: show errors...
  }
}
