import { disassembleToString } from 'hangul-js';

import { asSuccessPromise } from '^/types/__ResultType';
import { UserType } from '^/types/userTypes';
import type { UserListCore } from '^/userWeb/core/userListCore';
import { MobxInputValue } from '^/utils/mobx-utils/MobxInputValue';

export class UserListViewModel {
  constructor(
    public userListCore: UserListCore,
    public filter = new MobxInputValue(''),
  ) {

  }

  get filteredList() {
    if (this.filter.value === '') {
      return this.userListCore.usersList;
    }
    if (this.userListCore.usersListFuse.status !== 'success') {
      return this.userListCore.usersListFuse;
    }
    const users = this.userListCore.usersListFuse
      .value
      .search(disassembleToString(this.filter.value))
      .map((r) => {
        return r.item;
      });
    return asSuccessPromise(users);
  }
}
