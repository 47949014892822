/* eslint-disable jsx-a11y/label-has-associated-control */
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { borders } from '^/userWeb/styles/commonClasses';

import { FilledButtonStyle } from '../../Buttons/FilledButtonStyle';

import type { AddIotDeviceFormViewModel } from './AddIotDeviceForm.viewmodel';
import { BaseIotDeviceForm } from './BaseIotDeviceForm';

export const AddIotDeviceFormAddBtn = observer(function AddIotDeviceFormAddBtn(p: {
  viewmodel: AddIotDeviceFormViewModel,
}) {
  const syncResultValue = p.viewmodel.submit.syncResultTo.value;
  if (syncResultValue.status === 'not_initialized') {
    return (
      <button
        className={
          FilledButtonStyle
        }
        type="submit"
      >추가하기
      </button>
    );
  }
  if (syncResultValue.status === 'success') {
    return (
      <button
        className={
          FilledButtonStyle
        }
        type="submit"
      >추가완료!
      </button>
    );
  }
  if (syncResultValue.status === 'failure') {
    return (
      <button type="submit">에러: {syncResultValue.failure.reason}</button>
    );
  }
  return (
    <button type="button" disabled>로딩중</button>
  );
});

export const AddIotDeviceForm = observer(function EditIotDeviceForm(p: {
  viewmodel: AddIotDeviceFormViewModel,
}) {
  return (
    <form
      className={[
        css`
          display: flex;
          flex-direction: column; 
          gap: 20px;
        `,
        borders.DEBUG_blueBorderDashed,
      ].join(' ')}
      onSubmit={(e) => {
        e.preventDefault();
        p.viewmodel.submit.runMutationFunc(undefined);
      }}
    >
      <h3> 디바이스 추가 </h3>
      <BaseIotDeviceForm
        viewmodel={p.viewmodel.baseFormVM}
      />
      {/* <pre>
        {JSON.stringify(p.viewmodel.submit.syncResultTo.value, null, 2)}
      </pre> */}
      <AddIotDeviceFormAddBtn
        viewmodel={p.viewmodel}
      />
    </form>
  );
});
